import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import LockResetIcon from '@mui/icons-material/LockReset';
import UserProfileImg from "assets/images/profile-user.png";
import { Pages } from "utils/data";
import { style } from "./style";
import { logout } from "redux/slices/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { HeadLogo } from "UI/molecules";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import Notification from "UI/molecules/Notification";
import { ROUTES, USER_ROLES } from "utils/constants";
import Swal from "sweetalert2";
import { FRUser } from "@forgerock/javascript-sdk";
import { useMsal } from '@azure/msal-react';


const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, userProfile } = useSelector((state) => state.auth);
  const isAdmin = user.role === USER_ROLES.ADMIN;
  const [profileMenu, setProfileMenu] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const isProfileMenuOpen = Boolean(profileMenu);
  const { instance } = useMsal();

  const handleProfileMenuOpen = (event) => {
    setProfileMenu(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenu(null);
  };
  const dispatch = useDispatch();
  const handleLogout = async () => {
    if (isAdmin) {
      setIsLogoutLoading(true);
      instance.logoutRedirect({
        postLogoutRedirectUri: '/'
      })
      dispatch(logout());
      setIsLogoutLoading(false);

    } else {
      setIsLogoutLoading(true);
      await FRUser.logout();
      dispatch(logout());
      setIsLogoutLoading(false);
    }
  };
  const navigateToProfile = () => {
    if (userProfile?.vender?.isProfileCompleted || isAdmin) {
      navigate(ROUTES.PROFILE);
      handleProfileMenuClose();
      return;
    }
    Swal.fire({
      title: "Your Profile is not Completed!",
      text: "Click proceed to complete your profile",
      icon: "warning",
      confirmButtonText: "Yes, Proceed!",
      showCancelButton: true,
      confirmButtonColor: "#CBDA00",
      cancelButtonColor: "#E45400",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(ROUTES.PROFILE_COMPLETE);
      }
    });
  };
  const navigateToChangePassword = () => {
    navigate(ROUTES.CHANGE_PASSWORD);
  }
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <Box sx={style.bottomBorder}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            py={1.4}
            display={"flex"}
            alignItems={"center"}
            xs={2}
            sm={1}
          >
            <HeadLogo />
          </Grid>
          <Grid item xs={6} sm={5} alignSelf={"center"}>
            <Hidden mdUp>
              {isAdmin && (
                <Stack direction={"row"}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none", minWidth: 100 },
                    }}
                  >
                    {Pages.map((page, ind) => {
                      let isActive = false;
                      if (page.path.substring(1)) {
                        isActive = location.pathname.includes(
                          page.path.substring(1)
                        );
                      } else {
                        isActive = location.pathname === page.path;
                      }
                      return (
                        <Box
                          key={ind}
                          py={1.5}
                          px={3}
                          sx={
                            isActive
                              ? style.smallActivePage
                              : style.cursorPointer
                          }
                          onClick={() => {
                            navigate(page.path);
                            handleCloseNavMenu();
                          }}
                        >
                          <img
                            width={18}
                            src={page.icon}
                            style={{ filter: "invert(1)" }}
                            alt={"Icon"}
                          />
                          <Typography
                            sx={
                              isActive ? style.activeTitle : style.inActiveTitle
                            }
                            ml={1}
                            component="span"
                            variant="h5"
                          >
                            {page.title}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Menu>
                </Stack>
              )}
            </Hidden>
            <Hidden mdDown>
              {isAdmin && (
                <Stack direction={"row"} justifyContent={"space-evenly"}>
                  {Pages.map((page, ind) => {
                    let isActive = false;
                    if (page.path.substring(1)) {
                      isActive = location.pathname.includes(
                        page.path.substring(1)
                      );
                    } else {
                      isActive = location.pathname === page.path;
                    }
                    return (
                      <Box
                        key={ind}
                        py={4}
                        sx={isActive ? style.activePage : style.cursorPointer}
                        onClick={() => {
                          navigate(page.path);
                        }}
                      >
                        <img width={18} src={page.icon}
                          alt={"Icon"}
                        />

                        <Typography
                          sx={
                            isActive ? style.activeTitle : style.inActiveTitle
                          }
                          ml={1}
                          component="span"
                          variant="h5"
                        >
                          {page.title}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>
              )}
            </Hidden>
          </Grid>
          <Grid item xs={4} sm={6} alignSelf={"center"}>
            <Stack sx={style.menuIconContainer}>
              <Notification />
              {/* Profile */}
              <Stack
                id="profile-menu-button"
                aria-controls={isProfileMenuOpen ? "profile-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isProfileMenuOpen ? "true" : undefined}
                onClick={handleProfileMenuOpen}
                sx={style.cursorPointer}
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                <Box sx={style.avatarBackground}>
                  <Avatar alt="User Avatar" src={UserProfileImg} />
                </Box>
                <Hidden smDown>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ ...style.semiBold, textTransform: "capitalize" }}
                    >
                      {userProfile?.vender?.name}
                    </Typography>
                    <Typography variant="h6" sx={{ color: "#818181" }}>
                      {isAdmin ? "Admin" : "Vendor"}
                    </Typography>
                  </Box>
                </Hidden>
              </Stack>
              <Menu
                id="profile-menu"
                anchorEl={profileMenu}
                open={isProfileMenuOpen}
                onClose={handleProfileMenuClose}
                MenuListProps={{
                  "aria-labelledby": "profile-menu-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ mt: 1 }}
              >
                <MenuItem
                  sx={{ minWidth: "200px" }}
                  onClick={navigateToProfile}
                >
                  <SettingsIcon sx={{ color: "#818181", mr: 2 }} />
                  Account Setting
                </MenuItem>
                {
                  !isAdmin && (
                    <MenuItem
                      sx={{ minWidth: "200px" }}
                      onClick={navigateToChangePassword}
                    >
                      <LockResetIcon sx={{ color: "#818181", mr: 2 }} />
                      Change Password
                    </MenuItem>
                  )
                }
                <MenuItem sx={{ minWidth: "200px" }} onClick={handleLogout}>
                  {isLogoutLoading ? (
                    <CircularProgress
                      color="pending"
                      size={25}
                      sx={{ mr: 2 }}
                    />
                  ) : (
                    <LogoutIcon sx={{ color: "#818181", mr: 2 }} />
                  )}
                  Log Out
                </MenuItem>
              </Menu>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;
