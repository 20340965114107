import { Container, Grid, Stack, Typography, Hidden, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { style } from "./style";
import { VendorCards } from "utils/data";
import { StatCard, TableLoader } from "UI/atoms";
import { AddVendorDialog, MDTtable, PageHeader } from "UI/molecules";
import ThemeCustomization from "themes";
import MDTtableTheme from "themes/overrides/MDTtable";
import { vendorOptions, vendorsColumn } from "utils/data/table/vendors";
import { VendorsContext } from "utils/contexts";
import { THUNK_STATUS } from "utils/constants";

export const VendorsTemplate = () => {
  const {
    VendorsData,
    vendorPayload,
    setVendorPayload,
    handleVendorDetailsClick,
    handleVendorStatusChange,
    vendorStates,
    allVendorApiStatus,
  } = useContext(VendorsContext);
  const theme = useTheme();
  return (
    <>
      {/* Dashboard Description */}
      <Stack sx={style.dashboardBackground} pt={8} mb={10}>
        <Grid container>
          <Container maxWidth="lg">
            <Grid item container justifyContent={"space-between"} rowSpacing={2}>
              <PageHeader title={"Vendor Management"} />
              <AddVendorDialog />
            </Grid>
          </Container>
          <Grid
            item
            pt={5}
            xs={12}
            sx={{ background: "linear-gradient(#060e0e 75%, #f3f1f0 30%)" }}
          >
            <Container maxWidth="lg">
              <Hidden smDown>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500,
                    ...style.whiteText,
                  }}
                >
                  Overview
                </Typography>
              </Hidden>
              <Stack sx={style.cardContainer}>
                <Grid container gap={2} direction={"row"} display={"flex"}>
                  {VendorCards(vendorStates).map((card, ind) => {
                    return <StatCard key={ind} card={card} />;
                  })}
                </Grid>
              </Stack>
            </Container>
          </Grid>
        </Grid>
      </Stack>
      {/* Overview */}
      <Container maxWidth="lg">
        <Grid container spacing={3} pb={10}>
          <Grid item xs={12}>
            <ThemeCustomization overRides={() => MDTtableTheme(theme)}>
              <TableLoader
                loading={allVendorApiStatus === THUNK_STATUS.PENDING}
              >
                <MDTtable
                  title={"Vendor List"}
                  columns={vendorsColumn({
                    handleVendorDetailsClick,
                    handleVendorStatusChange,
                  })}
                  data={VendorsData.users}
                  options={vendorOptions({
                    data: VendorsData,
                    vendorPayload,
                    setVendorPayload,
                    handleVendorDetailsClick
                  })}

                />
              </TableLoader>
            </ThemeCustomization>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
