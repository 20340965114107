import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Toaster } from "react-hot-toast";
import {
  AppInstance,
  handleError,
  handleRequest,
  handleResponse,
} from "utils/network";
import { setForgerockConfig } from "utils/other/forgeRockService";
import { USER_LOGIN_JOURNEY } from "config";
import { TokenStorage } from "@forgerock/javascript-sdk";
import { AppContext, useGlobalStateMgmt } from "global-state";

setForgerockConfig(USER_LOGIN_JOURNEY);
AppInstance.interceptors.request.use(
  (config) => handleRequest(config),
  (error) => error
);
AppInstance.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error, store)
);

(async function initAndHydrate() {
  let isAuthenticated;
  try {
    isAuthenticated = !!(await TokenStorage.get());
  } catch (err) {
    console.error(`Error: token retrieval for hydration; ${err}`);
  }

  const email = window.sessionStorage.getItem("sdk_email");
  const username = window.sessionStorage.getItem("sdk_username");

  function Init() {
    const stateMgmt = useGlobalStateMgmt({
      email,
      isAuthenticated,
      username,
    });
    return (
      <React.StrictMode>
        <Provider store={store}>
          <Router>
            <Toaster />
            <AppContext.Provider value={stateMgmt}>
              <App />
            </AppContext.Provider>
          </Router>
        </Provider>
      </React.StrictMode>
    );
  }

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<Init />);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
