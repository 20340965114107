import { createSlice } from "@reduxjs/toolkit";
import {
  getBudgetAnalyticsAsyncThunk,
  getGrantApprovalAnalyticsAsyncThunk,
  getGrantEventsAnalyticsAsyncThunk,
  getGrantSubmissionAnalyticsAsyncThunk,
  getGrantsCategoryAnalyticsAsyncThunk,
  getGrantsStatusAnalyticsAsyncThunk,
  getProjectTimeFrameAnalyticsAsyncThunk,
  getVendorApprovalAnalyticsAsyncThunk,
  getVendorStatusAnalyticsAsyncThunk,
} from "redux/asyncThunk";
import _ from "lodash";

const initialState = {
  grantsCategoryAnalytics: [],
  grantsStatusAnalytics: [],
  projectTimeFrameAnalytics: [],
  grantSubmissionAnalytics: [],
  grantApprovalAnalytics: [],
  budgetAnalytics: [],
  grantEventsAnalytics: [],
  vendorStatusAnalytics: [],
  vendorApprovalAnalytics: [],
};

export const AnalyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getGrantsCategoryAnalyticsAsyncThunk.fulfilled,
        (state, action) => {
          state.grantsCategoryAnalytics = action.payload.data;
        }
      )
      .addCase(
        getGrantsStatusAnalyticsAsyncThunk.fulfilled,
        (state, action) => {
          state.grantsStatusAnalytics = action.payload.data;
        }
      )
      .addCase(
        getProjectTimeFrameAnalyticsAsyncThunk.fulfilled,
        (state, action) => {
          state.projectTimeFrameAnalytics = action.payload.data;
        }
      )
      .addCase(
        getGrantSubmissionAnalyticsAsyncThunk.fulfilled,
        (state, action) => {
          state.grantSubmissionAnalytics = action.payload.data;
        }
      )
      .addCase(
        getGrantApprovalAnalyticsAsyncThunk.fulfilled,
        (state, action) => {
          state.grantApprovalAnalytics = action.payload.data;
        }
      )
      .addCase(getBudgetAnalyticsAsyncThunk.fulfilled, (state, action) => {
        state.budgetAnalytics = action.payload.data;
      })
      .addCase(
        getVendorApprovalAnalyticsAsyncThunk.fulfilled,
        (state, action) => {
          let result = _.chain(action.payload.data)
            .groupBy("date")
            .map((arr) => {
              const newObj = { date: arr[0].date };
              _.forEach(arr, (o) => {
                newObj[
                  o.event_type === "created" ? "createdCount" : "approvedCount"
                ] = o.count;
              });
              return newObj;
            })
            .value();
          state.vendorApprovalAnalytics = result;
        }
      )
      .addCase(
        getVendorStatusAnalyticsAsyncThunk.fulfilled,
        (state, action) => {
          state.vendorStatusAnalytics = action.payload.data;
        }
      )
      .addCase(getGrantEventsAnalyticsAsyncThunk.fulfilled, (state, action) => {
        state.grantEventsAnalytics = action.payload.data;
      });
  },
});

export default AnalyticsSlice.reducer;
