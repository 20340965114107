import { Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { SignupContext } from "utils/contexts/signupContext";
import { HeadLogo, LegalInfo, PrimaryContact, SigninInfo } from "UI/molecules";
import { SecondaryContact } from "UI/molecules/SecondaryContact";
import { style } from "./style";
import { THUNK_STATUS } from "utils/constants";
import { LoadingButton } from "@mui/lab";

export const SignupTemplate = () => {
  const {
    handleSignup,
    navigateToSignin,
    registerAPIStatus,
    validationApiStatus,
    isValidLink,
    isInvited,
    isLoading,
    isBtnLoading,
  } = useContext(SignupContext);

  return (
    <Grid item container xs={12}>
      <Stack
        sx={{ width: "100%", height: "100%" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box sx={{ py: 2 }}>
          <HeadLogo />
        </Box>
        {
          (isInvited && isLoading) ?
            <Skeleton variant="rectangular" width="400px">
              <div style={{ paddingTop: "57%" }} />
            </Skeleton>
            :
            validationApiStatus === THUNK_STATUS.PENDING ? (
              <Skeleton variant="rectangular" width="400px">
                <div style={{ paddingTop: "57%" }} />
              </Skeleton>
            ) : isInvited && !isValidLink ? (
              <Stack alignItems={"center"} sx={style.invitationCard} gap={2}>
                <CancelOutlinedIcon sx={{ fontSize: 60 }} color="error" />
                <Typography variant="h3">Ooops! Invitation Expired</Typography>
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  We regret to inform you that your invitation has been
                  expired.Please contact admin for further process
                </Typography>
                <Button variant="contained" color="info">
                  Contact
                </Button>
              </Stack>
            ) : (
              <Grid container>
                <Grid item xs={12} sx={{ ...style.headersStyles, color: "#D4D0CA" }}>
                  <Typography variant="h1" sx={style.typographyStyles}>
                    {isInvited ? "Complete Your Profile" : "Create an account"}
                  </Typography>
                  <Typography variant="body2" sx={style.typographyStyles}>
                    {isInvited
                      ? "Please enter your details to proceed"
                      : "Let’s get started by creating an account"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={style.formSectionStyles}
                  component="form"
                  noValidate
                  onSubmit={handleSignup}
                >
                  <SigninInfo />
                  <LegalInfo />
                  <PrimaryContact />
                  <SecondaryContact />
                  <Grid item xs={12} px={4}>
                    <LoadingButton
                      loading={(registerAPIStatus === THUNK_STATUS.PENDING) || isBtnLoading}
                      loadingPosition={"end"}
                      endIcon={<></>}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      {isInvited ? "Submit" : "Register"}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={10} sm={8} lg={6.5} mt={1.2} mb={2}>
                    {isInvited ? (
                      <Typography variant="body2" sx={style.typographyStyles}>
                        Don't Want to Move? &nbsp;
                        <Box
                          component={"span"}
                          sx={style.logInLinkStyles}
                          onClick={navigateToSignin}
                        >
                          Go Back
                        </Box>
                      </Typography>
                    ) : (
                      <Typography variant="body2" sx={style.typographyStyles}>
                        Already have an account? &nbsp;
                        <Box
                          component={"span"}
                          sx={style.logInLinkStyles}
                          onClick={navigateToSignin}
                        >
                          Log in
                        </Box>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )
        }
      </Stack>
    </Grid>
  );
};
