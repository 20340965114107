import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { vendorProjectStatus } from "utils/other/helper";
import { useContext } from "react";
import { GrantDetailsContext } from "utils/contexts";
import { TitleValue } from ".";
import { formatDate } from "utils/other/helper";
import {
  PROJECT_FREQUENCY_OPTIONS,
  PROJECT_TIMEFRAME_OPTIONS,
} from "utils/data";

export const ProjectInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  const { isAdmin } = useContext(GrantDetailsContext);
  const vendorProjectObj = vendorProjectStatus(data.approval, isAdmin);
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
          <Stack direction={"column"} alignItems={"flex-end"} py={1} px={2}>
            <Box
              sx={{
                ...style.projectCardChip,
                background: vendorProjectObj.color,
              }}
              variant="contained"
              size="small"
            >
              {vendorProjectObj.buttonText}
            </Box>
            <Typography sx={{ color: "black" }}>
              {formatDate(data.createdAt)}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <Stack sx={style.infoCardContent}>
          <TitleValue title={"Project Name"} value={data.projectName} />
          <TitleValue
            title={"Project Time Frame"}
            value={
              PROJECT_TIMEFRAME_OPTIONS.find(
                (p) => p.value === data.projectTimeFrame
              )?.label
            }
          />
          <TitleValue
            title={"Project Frequency"}
            value={
              PROJECT_FREQUENCY_OPTIONS.find((p) => p.value === data.frequency)
                ?.label
            }
          />
          <TitleValue title={"Requested Budget"} value={data.requestedBudget} />
          <TitleValue
            title={"Total Project Cost"}
            value={data.totalProjectCost}
          />
          <TitleValue
            title={"Project Brief"}
            value={data.projectBrief}
            fullWidth
          />
        </Stack>
      </Stack>
    </>
  );
};
