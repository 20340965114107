import { Grid, Typography } from "@mui/material";
import { InputField } from "UI/atoms";
import React, { useContext } from "react";
import { SignupContext } from "utils/contexts/signupContext";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

export const PrimaryContact = () => {
  const { errors, control, setValue, getValues, trigger, touchedFields, isSubmitted } = useContext(SignupContext);
  return (
    <div className="formGroupContainer">
      <Typography
        variant="subtitle2"
        className="formGroupTitle"
        mt={1}
        gutterBottom
      >
        Primary Contact Person
      </Typography>
      <Grid container justifyContent={"center"} spacing={2.5}>
        <Grid item xs={12}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"primaryContact.title"}
            label={"Name"}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"primaryContact.email"}
            label={"Email"}
            type={"email"}
            errors={errors}
            control={control}
            EndIcon={EmailIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"primaryContact.mobile"}
            onBlur={() => {
              setValue('primaryContact.mobile', getValues('primaryContact.mobile'), { shouldTouch: true });
              trigger('primaryContact.mobile');
            }}
            onChange={(e) => {
              const val = e.target.value;
              const cleanedValue = val.replace(/\D/g, '');
              setValue('primaryContact.mobile', cleanedValue);
              if (touchedFields?.primaryContact?.mobile || isSubmitted) {
                trigger('primaryContact.mobile');
              }
            }}
            label={"Mobile"}
            errors={errors}
            control={control}
            EndIcon={PhoneIcon}
          />
        </Grid>
      </Grid>
    </div>
  );
};
