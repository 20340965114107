import "./App.css";
import { Routes } from "./routes";
import ThemeCustomization from "./themes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Versioning } from "UI/molecules";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "config";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);
ChartJS.register(ArcElement, Tooltip, Legend);

function App() {
  return (
    <ThemeCustomization>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MsalProvider instance={msalInstance}>
          <Routes />
        </MsalProvider>
        <Versioning />
      </LocalizationProvider>
    </ThemeCustomization>
  );
}

export default App;
