import { replaceUrl } from "utils/other/helper";
import { METHODS, SERVICE_URL } from "../../utils/constants";
import { AppClient, AuthClient } from "../../utils/network";

// Function for making a login API request
export const loginService = (data) => {
  const updatedData = { ...data, email: data.email.toLowerCase() };
  return AuthClient({
    url: SERVICE_URL.LOGIN,
    method: METHODS.POST,
    data: updatedData,
  });
};

export const registerService = (data) => {
  return AuthClient({
    url: SERVICE_URL.REGISTER,
    method: METHODS.POST,
    data,
  });
};

export const getProfileService = () => {
  return AppClient({
    url: SERVICE_URL.GET_PROFILE,
    method: METHODS.GET,
  });
};

export const validateInvitaionService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.VALIDATE_INVITAIION, payload),
    method: METHODS.GET,
  });
};

export const registerByInviteService = ({ data, ...payload }) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.VALIDATE_INVITAIION, payload),
    data,
    method: METHODS.PUT,
  });
};

export const getCsrfTokenService = () => {
  return AppClient({
    url: SERVICE_URL.GET_CSRF_TOKEN,
    method: METHODS.GET,
  });
};
