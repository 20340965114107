import React from "react";
import { Box, FormHelperText } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";

export const OtpInputAtom = (props) => {
  const { label, fullWidth, EndIcon, name, validate, control, ...rest } = props;
  const { control: initialControl } = useForm({});
  const usedControl = control || initialControl;

  return (
    <Controller
      {...rest}
      name={name}
      control={usedControl}
      rules={{ validate: validate }}
      render={({ field, fieldState }) => (
        <Box>
          <MuiOtpInput {...field} />
          {fieldState.invalid ? (
            <FormHelperText error>{fieldState.error?.message}</FormHelperText>
          ) : null}
        </Box>
      )}
    />
  );
};
