import { Box, FormHelperText, Skeleton, Typography } from "@mui/material";
import React from "react";
import { CheckboxAtom } from "./Checkbox";
import { style } from "./style";

export const GroupCheckBox = ({
  title,
  optionArr,
  control,
  errors,
  name,
  trigger,
  loading = false,
  setValue = () => { },
}) => {
  return (
    <>
      <Box style={style.customInputBg}>
        <Typography sx={style.customInputTitle}>{title}</Typography>
        {optionArr.map((o, ind) => {
          setValue(`${name.arrayName}[${ind}].${name.label}`, o.id);
          return (
            <Box key={ind}>
              <CheckboxAtom
                name={`${name.arrayName}[${ind}].value`}
                control={control}
                label={
                  loading ?
                    <Skeleton sx={{ width: { xs: 150, sm: 200 } }} />
                    : o.question
                }
                trigger={() => trigger(name.arrayName)}
                defaultValue={false}
              />
            </Box>
          );
        })}
      </Box >
      {errors && (
        <FormHelperText sx={{ pl: 2 }} error={Boolean(errors)}>
          {errors?.message}
        </FormHelperText>
      )
      }
    </>
  );
};
