import React, { useEffect, useState } from "react";
import { VendorsTemplate } from "../../../templates";
import { VendorsContext } from "utils/contexts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changeVendorStatusAsyncThunk,
  getAllVendorAsyncThunk,
  getVendorStatesAsyncThunk,
} from "redux/asyncThunk/vendors.asyncThunk";
import { ROUTES } from "utils/constants";
import toast from "react-hot-toast";
import { handleForgerockRegistration } from "utils/other/forgeRockService";
import { VENDOR_STATUS } from "utils/data";

export const Vendors = () => {
  // -----------------Hooks----------------- //
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: VendorsData,
    vendorStates,
    allVendorApiStatus,
  } = useSelector((state) => state.vendor);
  const [vendorPayload, setVendorPayload] = useState({
    params: { pageNo: 1, pageSize: 5 },
  });

  // -----------------Functions----------------- //
  const handleVendorDetailsClick = (id) => {
    navigate(ROUTES.VENDOR_DETAILS, { state: { data: id } });
  };
  const handleVendorStatusChange = async ({ id, status }) => {
    const vendorData = VendorsData?.users?.find((item) => item.id === id);
    if (!vendorData?.vender?.isProfileCompleted && status === VENDOR_STATUS.APPROVED) {
      toast.error("You can't approve vendor whose profile is not completed.");
      return;
    }
    if (vendorData.status === VENDOR_STATUS.REJECTED && status === VENDOR_STATUS.APPROVED && vendorData.vender.approvedBy !== null) {
      dispatch(
        changeVendorStatusAsyncThunk({
          id: vendorData.id,
          status: VENDOR_STATUS.APPROVED,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(getAllVendorAsyncThunk(vendorPayload));
          dispatch(getVendorStatesAsyncThunk());
        })
        .catch(() => { });
    } else if (status === VENDOR_STATUS.APPROVED) {
      const handleNextOperation = () => dispatch(
        changeVendorStatusAsyncThunk({
          id: vendorData.id,
          status: VENDOR_STATUS.APPROVED,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(getAllVendorAsyncThunk(vendorPayload));
          dispatch(getVendorStatesAsyncThunk());
        })
        .catch(() => { });
      handleForgerockRegistration(vendorData, handleNextOperation);
    } else {
      dispatch(
        changeVendorStatusAsyncThunk({ id: vendorData.id, status: VENDOR_STATUS.REJECTED })
      )
        .unwrap()
        .then(() => {
          dispatch(getAllVendorAsyncThunk(vendorPayload));
          dispatch(getVendorStatesAsyncThunk());
        })
        .catch(() => { });
    }
  };

  // -----------------Use Effects----------------- //
  useEffect(() => {
    dispatch(getAllVendorAsyncThunk(vendorPayload));
    // eslint-disable-next-line 
  }, [vendorPayload]);
  useEffect(() => {
    dispatch(getVendorStatesAsyncThunk());
    // eslint-disable-next-line
  }, []);

  // -----------------Context----------------- //
  const vendorsContextValue = {
    VendorsData,
    vendorPayload,
    setVendorPayload,
    handleVendorDetailsClick,
    handleVendorStatusChange,
    vendorStates,
    allVendorApiStatus,
  };
  return (
    <VendorsContext.Provider value={vendorsContextValue}>
      <VendorsTemplate />
    </VendorsContext.Provider>
  );
};
