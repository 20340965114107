import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import {
  getCsrfTokenService,
  getProfileService,
  loginService,
  registerByInviteService,
  registerService,
  validateInvitaionService,
} from "../services";

export const loginAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.LOGIN,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await loginService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.REGISTER,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await registerService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getprofileAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_PROFILE,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getProfileService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const validateInvitationAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.VALIDATE_INVITAIION,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validateInvitaionService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerByInviteAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.REGISTER_BY_INVITE,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await registerByInviteService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCsrfTokenAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_CSRF_TOKEN,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCsrfTokenService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
