import { grey } from "@mui/material/colors";

export const style = {
  logInLinkStyles: {
    color: "primary.main",
    textDecoration: "underline",
    cursor: "pointer",
  },
  typographyStyles: {
    textAlign: "center",
  },
  notificationHeader: {
    px: 2,
    py: 1.5,
    borderBottom: "1px solid",
    borderColor: grey[200],
    position: "sticky",
    top: 0,
    zIndex: 9,
    background: "white",
  },
  ImageBox: {
    background: grey[200],
    borderRadius: "5px",
    border: "1px solid",
    borderColor: grey[300],
    maxWidth: "300px",
    py: 0.5,
    px: 1.5,
    width: "100%",
    opacity: 0.8,
  },
  feedbackTextIcon: {
    fontSize: 20,
    opacity: 0.7,
    mx: 1,
    cursor: "pointer",
  },
  versioning: {
    position: "fixed",
    top: 0,
    right: 0,
    px: 0.5,
    pb: 0.2,
    background: "black",
    color: "white",
  },
  projectCardChip: {
    borderRadius: 3,
    px: 1,
    py: 0.4,
    color: "white",
  },
  goalTableCellHead: {
    position: "sticky",
    right: "151.17px",
    zIndex: 9,
  },
  completionTableCellHead: {
    position: "sticky",
    right: 0,
    zIndex: 9,
  },
  mileStoneTableCellHead: {
    position: "sticky",
    left: 0,
    minWidth: "500px",
    zIndex: 9,
  },
  grantButton: (color) => {
    return {
      color: "white",
      bgcolor: `${color}.main`,
      "&:hover": {
        bgcolor: `${color}.dark`,
      },
    };
  },
  whiteText: {
    color: (theme) => theme.palette.common.white,
    fontWeight: 400,
  },

  videoBox: {
    height: "100%",
    width: "100%",
    display: "flex",
  },
  video: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  loginHeader: {
    textAlign: "center",
    color: "#D4D0CA",
  },
  aldarIconButton: {
    padding: "3px",
    paddingRight: "12px",
    borderRight: "1px solid white",
    display: "flex",
    alignItems: "center",
  },
  container: {
    justifyContent: "center",
  },
  subtitle: {
    textAlign: "right",
    color: "#818181",
  },
  button: {
    mt: -1,
  },
  link: {
    color: "primary.main",
    textDecoration: "underline",
    cursor: "pointer",
  },
  infoCardHead: {
    background: "white",
    borderBottom: "2px solid rgba(0, 0, 0, 0.05)",
    p: 2,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 1.5,
  },
  infoCardHeadIcon: {
    background: (theme) => theme.palette.primary.main,
    borderRadius: "5px",
    p: 0.8,
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  infoCardContent: {
    background: "white",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  infoCardValue: {
    py: 2,
    px: 3,
    color: "black",
    gap: 0.5,
    flexGrow: 1,
    flexShrink: { xs: 1, sm: 0 },
    minWidth: "200px",
    outline: "1px solid rgba(0, 0, 0, 0.05)",
    boxSizing: "border-box",
  },
  infoCardTitle: {
    fontWeight: 500,
    textTransform: "capitalize",
    opacity: 0.5,
  },
  infoCardDesc: { fontWeight: 400, textTransform: "capitalize" },
  infoCardBackground: {
    borderRadius: "12px",
    overflow: "hidden",
    border: "2px solid rgba(0, 0, 0, 0.05)",
  },
  secondaryContainer: {
    width: "100%",
  },
  formCount: {
    background: "black",
    borderRadius: "50%",
    width: "35px",
    height: "35px",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
  },
  vendorProjectCard: {
    height: "100%",
    background: (theme) => theme.palette.common.white,
    borderRadius: "10px",
    overflow: "hidden",
    border: "5px solid",
    flex: 1,
    maxWidth: "450px",
    margin: "auto",
    cursor: "pointer",
  },
  vendorProjectCardHead: {
    background: (theme) => theme.palette.common.black,
    px: 1,
    justifyContent: "space-between",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  vendorProjectContentBox: {
    px: 1,
    py: 2,
  },
  notificationList: {
    maxWidth: "450px",
    p: 0,
    bgcolor: "background.paper",
  },
  blackBackground: {
    background: "#060e0e",
    position: "absolute",
    zIndex: -1,
    width: "100%",
  },
  dateFont: {
    fontWeight: 400,
  },
};
