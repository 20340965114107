import { Grid, Typography } from "@mui/material";
import React from "react";
import { LogoWhite } from "assets/images";
import { AldarIconButton } from "UI/molecules/AldarIconButton";
import { AdminLoginContext } from "utils/contexts/admin/adminLoginContext";
import { useContext } from "react";

export const AdminLoginTemplate = () => {
  const { navigateToBase, handleAdminLogin, isLogging } =
    useContext(AdminLoginContext);
  return (
    <Grid item container xs={12} direction={"column"}>
      {/* Logo */}
      <Grid
        item
        container
        xs={2.3}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <img
            onClick={navigateToBase}
            style={{ cursor: "pointer" }}
            width={75}
            src={LogoWhite}
            alt="Aldar Logo"
          />
        </Grid>
      </Grid>
      {/* Login form */}
      <Grid
        item
        container
        xs={7.4}
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        rowSpacing={6.3}
      >
        {/* Headers */}
        <Grid item>
          <Typography variant="h1" sx={{ textAlign: "center" }}>
            Welcome
          </Typography>
        </Grid>
        {/* Input Fields */}
        <Grid item container justifyContent={"center"} rowSpacing={3}>
          <Grid item xs={10} sm={8} lg={6.5}>
            <AldarIconButton
              loading={isLogging}
              onClick={handleAdminLogin}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
