// ==============================|| OVERRIDES - BADGE ||============================== //

export default function TextField(theme) {
  const isDark = theme.palette.mode === "dark";
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          // "& .MuiInputBase-input": {
          //   padding: "28px 12px 11px 24px",
          // },
          "& .MuiFilledInput-root": {
            borderRadius: "11px",
            ...(isDark && {
              background: "#262b2b",
            }),
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          padding: "28px 12px 11px 24px !important",
        },
      },
    },
  };
}
