import React, { useContext } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { InputField } from "UI/atoms";
import EmailIcon from "@mui/icons-material/Email";
import { ProfileCreateContext } from "utils/contexts";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { LoadingButton } from "@mui/lab";
import { THUNK_STATUS } from "utils/constants";

export const ProfileCompleteForm = () => {
  const { control, errors, handleSignup, createVendorApiStatus } =
    useContext(ProfileCreateContext);
  return (
    <Stack component="form" onSubmit={handleSignup} gap={2}>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack sx={style.infoCardHead}>
          <Stack sx={style.infoCardHeadIcon}>
            <AccountTreeIcon />
          </Stack>
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            Basic Details
          </Typography>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            background: "white",
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"fullname"}
                label={"Entity Legal Name"}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fullWidth={true}
                variant="filled"
                label="Email"
                name={"email"}
                type={"email"}
                errors={errors}
                control={control}
                EndIcon={EmailIcon}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"address.houseNo"}
                label={"House Flat No."}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"address.addressLine1"}
                label={"Address Line 1"}
                errors={errors}
                control={control}
                EndIcon={LocationOnIcon}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"address.addressLine2"}
                label={"Address Line 2"}
                errors={errors}
                control={control}
                EndIcon={LocationOnIcon}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"address.city"}
                label={"City"}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"address.state"}
                label={"State"}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"address.country"}
                label={"Country"}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                fullWidth={true}
                variant="filled"
                type={"number"}
                name={"address.zipCode"}
                label={"Zip Code"}
                errors={errors}
                control={control}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack sx={style.infoCardBackground}>
        <Stack sx={style.infoCardHead}>
          <Stack sx={style.infoCardHeadIcon}>
            <AccountTreeIcon />
          </Stack>
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            Contact Details
          </Typography>
        </Stack>
        <Stack
          sx={{
            background: "white",
            p: 2,
          }}
        >
          <Typography variant="caption">Primary Contact Person</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"primaryContact.title"}
                label={"Name"}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"primaryContact.email"}
                label={"Email"}
                type={"email"}
                errors={errors}
                control={control}
                EndIcon={EmailIcon}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"primaryContact.mobile"}
                label={"Mobile"}
                type={"tel"}
                errors={errors}
                control={control}
                EndIcon={PhoneIcon}
              />
            </Grid>
          </Grid>
          <Typography mt={2} variant="caption">
            Secondary Contact Person
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"secondaryContact.title"}
                label={"Name"}
                errors={errors}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"secondaryContact.email"}
                label={"Email"}
                type={"email"}
                errors={errors}
                control={control}
                EndIcon={EmailIcon}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"secondaryContact.mobile"}
                label={"Mobile"}
                type={"tel"}
                errors={errors}
                control={control}
                EndIcon={PhoneIcon}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <LoadingButton
        type={"submit"}
        sx={{ borderRadius: "5px" }}
        variant="contained"
        loading={createVendorApiStatus === THUNK_STATUS.PENDING}
        loadingPosition="end"
        endIcon={<></>}
        fullWidth
      >
        Submit
      </LoadingButton>
    </Stack>
  );
};
