import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LogoBlack } from "assets/images";
import React from "react";
import { style } from "./style";
import { grey } from "@mui/material/colors";

export const AldarIconButton = (props) => {
  return (
    <LoadingButton
      loading={props.loading}
      loadingPosition="end"
      endIcon={<></>}
      sx={{ py: 1, justifyContent: "flex-start" }}
      startIcon={
        <Box
          sx={{
            ...style.aldarIconButton,
            ...(props.loading && { borderColor: grey[800] }),
          }}
        >
          <img width={30} height={30} src={LogoBlack} alt="Aldar Logo" />
        </Box>
      }
      fullWidth
      variant="contained"
      {...props}
    >
      Log in with Active Directory
    </LoadingButton>
  );
};
