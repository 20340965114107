// ==============================|| OVERRIDES - MDT Table ||============================== //

export default function MDTtable(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#2C2A27",
          fontWeight: 500,
          padding: "20px 10px",
          whiteSpace: "nowrap",
          "&:first-of-type": {
            paddingLeft: "60px",
            [theme.breakpoints.down("sm")]: {
              paddingLeft: "20px",
            },
          },
          "&:last-child": {
            paddingRight: "60px",
            whiteSpace: "normal",
            [theme.breakpoints.down("sm")]: {
              paddingRight: "20px",
            },
          },
          [theme.breakpoints.down("sm")]: {
            "&:first-of-type": {
              paddingLeft: "20px",
            },
            "&:last-child": {
              paddingRight: "20px",
            },
          },
          borderBottom: 0,
        },
        head: {
          padding: "10px 10px",
          "&:first-of-type": {
            paddingLeft: "60px",
            [theme.breakpoints.down("sm")]: {
              paddingLeft: "20px",
            },
          },
          "&:last-child": {
            paddingRight: "60px",
            [theme.breakpoints.down("sm")]: {
              paddingRight: "20px",
            },
          },
          borderBottom: 0,
          color: "#818181",
        },
        footer: {
          "&:first-of-type": {
            paddingLeft: "10px",
            paddingRight: "10px",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": { backgroundColor: "white !important" },
          borderBottom: "1px solid #D4D0CA",
          cursor: "pointer",
        },
        head: {
          borderBottom: 0,
        },
        footer: {
          borderBottom: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 0,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "30px 60px !important",
          [theme.breakpoints.down("sm")]: {
            padding: "10px 20px !important",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          padding: "10px !important",
        },
      },
    },

    // Inputs style
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "unset",
          fontWeight: "unset",
          height: "unset",
          padding: "4px 0 5px",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "unset",
          fontWeight: "unset",
          marginLeft: "unset",
          marginTop: "unset",
        },
      },
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        root: {
          paddingBottom: "10px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        filterPaper: {
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        },
      },
    },
  };
}
