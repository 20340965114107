import React from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  Container,
  Grid,
  Stack,
  Button,
  Pagination,
  Hidden,
  Typography,
} from "@mui/material";
import { style } from "./style";
import { useContext } from "react";
import { UserDashboardContext } from "utils/contexts";
import { PageHeader, VendorPojectCard } from "UI/molecules";
import _ from "lodash";
import { GrantCards } from "utils/data";
import { StatCard } from "UI/atoms";
import NoDataAnimation from "assets/images/vector/no-data.json";
import Lottie from "react-lottie";
import { THUNK_STATUS } from "utils/constants";

export const UserDashboardTemplate = () => {
  const {
    navigateToGrantCreate,
    count,
    grantsData,
    handleGrantPageChange,
    allGrantAPIStatus,
    grantStates,
    handleCardClick,
    activeCard,
    grantPayload,
    userProfile,
  } = useContext(UserDashboardContext);
  return (
    <>
      <Grid
        sx={{ ...style.dashboardBackground }}
        width={"100%"}
        minHeight={"400px"}
      ></Grid>
      {/* Dashboard Description */}
      <Stack pt={8} mb={10}>
        <Container maxWidth="lg">
          <Grid container rowSpacing={2}>
            <PageHeader
              title={`Welcome ${userProfile?.vender?.name || "user"}`}
              desc={
                "Athar grants can help vendors pay for supplies and equipment that they need. Socially responsible and environmentally sustainable vendors often receive these grants."
              }
            />
            <Grid
              item
              container
              xs={12}
              sm={6}
              justifyContent={{ xs: "flex-start", sm: "flex-end" }}
            >
              <Grid item>
                <Button
                  startIcon={<AddRoundedIcon />}
                  variant="contained"
                  color="info"
                  onClick={navigateToGrantCreate}
                >
                  New Grant Request
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item pt={5} xs={12}>
            <Hidden smDown>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 500,
                  color: "white",
                }}
              >
                Overview
              </Typography>
            </Hidden>
            <Stack sx={style.cardContainer}>
              <Grid container gap={2} direction={"row"} display={"flex"}>
                {GrantCards(grantStates).map((card, ind) => {
                  if (card.title === "Revision Requested") {
                    card.title = "Revision Required";
                  } else if (card.title === "Request For Updates") return null;
                  return (
                    <StatCard
                      key={ind}
                      card={card}
                      active={activeCard === card.approval}
                      onClick={() => handleCardClick(card.approval)}
                    />
                  );
                })}
              </Grid>
            </Stack>
          </Grid>
          <Grid container py={5} spacing={4}>
            {allGrantAPIStatus === THUNK_STATUS.PENDING ? (
              Array.from({ length: 6 }).map((_, ind) => {
                return (
                  <Grid key={ind} item xs={12} md={6} lg={4}>
                    <VendorPojectCard loading={true} />
                  </Grid>
                );
              })
            ) : !_.isEmpty(grantsData) ? (
              grantsData.map((grant, ind) => {
                return (
                  <Grid key={ind} item xs={12} md={6} lg={4}>
                    <VendorPojectCard grantDetails={grant} />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Stack py={5} direction="row" gap={3} sx={{ width: '100%' }}>
                  <Stack flex={1} sx={style.graphContainer}>
                    <Lottie
                      options={{
                        animationData: NoDataAnimation,
                      }}
                      height={220}
                      width={220}
                    />
                    <Typography
                      variant="h3"
                      sx={{ opacity: 0.8, textAlign: "center" }}
                    >
                      No Grants Available!
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>
          {Math.floor(count / 6) !== 0 && (
            <Stack alignItems={"center"}>
              <Pagination
                count={Math.ceil(count / 6)}
                shape="rounded"
                page={grantPayload.params.pageNo}
                onChange={handleGrantPageChange}
              />
            </Stack>
          )}
        </Container>
      </Stack>
    </>
  );
};
