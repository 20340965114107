import { replaceUrl } from "utils/other/helper";
import { METHODS, SERVICE_URL } from "../../utils/constants";
import { AppClient } from "../../utils/network";

export const getAllVendorService = (payload) => {
  const { params } = payload;
  return AppClient({
    url: SERVICE_URL.VENDOR,
    method: METHODS.GET,
    params,
  });
};

export const changeVendorStatusService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.VENDOR_STATUS, payload),
    method: METHODS.PUT,
  });
};

export const changePasswordRequestService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.CHANGE_PASSWORD_REQUEST, payload),
    method: METHODS.PUT,
  });
};

export const changePasswordRequestCompletedService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.CHANGE_PASSWORD_REQUEST_COMPLETED, payload),
    method: METHODS.PUT,
  });
};

export const getVendorStatesService = () => {
  return AppClient({
    url: SERVICE_URL.VENDOR_STATES,
    method: METHODS.GET,
  });
};

export const getSingleVendorService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.SINGLE_VENDOR, payload),
    method: METHODS.GET,
  });
};

export const createVendorService = (payload) => {
  const updateId = payload?.id;
  if (updateId) {
    delete payload.id;
    delete payload.email;
  }
  return AppClient({
    url: updateId ? SERVICE_URL.VENDOR + `/${updateId}` : SERVICE_URL.VENDOR,
    method: updateId ? METHODS.PUT : METHODS.POST,
    data: payload,
  });
};

export const getVendorListService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_VENDOR_LIST,
    method: METHODS.GET,
    params: payload,
  });
};

export const uploadDocumentsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.UPLOAD_DOCUMENTS,
    method: METHODS.POST,
    data: payload,
  });
};
