import React from "react";
import { Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { InfoCardTable } from ".";
import { transformMilestoneResponse } from "utils/other/helper";

export const ReportInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  const transformedRowData = transformMilestoneResponse(data);

  const tableData = {
    head: [
      {
        label: "Milestone",
        name: "milestone",
      },
      ...data.map((row) => {
        return {
          label: `Report${row.reportNumber}`,
          name: `report${row.reportNumber}`,
        };
      }),
      {
        label: "Total Achieved",
        name: "totalAchieved",
      },
      {
        label: "Goal",
        name: "goal",
      },
      {
        label: "Completion",
        name: "completion",
      },
    ],
    rowData: transformedRowData,
  };
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <InfoCardTable tableData={tableData} />
      </Stack>
    </>
  );
};
