import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export const CheckboxAtom = (props) => {
  const {
    label,
    control,
    name,
    trigger = () => { },
    onChange = () => { },
    ...rest
  } = props;
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          {...rest}
          render={({ field, fieldState }) => (
            <Checkbox
              {...field}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.target.checked);
                onChange();
                trigger();
              }}
              sx={{
                color: fieldState.invalid ? "red" : "",
              }}
            />
          )}
        />
      }
      label={<Typography variant="caption">{label}</Typography>}
    />
  );
};
