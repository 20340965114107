import React from "react";
import WhiteLogo from "assets/images/logo-white.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";

export const HeadLogo = () => {
  return (
    <Link to={ROUTES.BASE}>
      <img src={WhiteLogo} style={{ maxWidth: "100%" }} alt={'Logo'} />
    </Link>
  );
};
