import { Box, Divider, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { style } from "./style";
import { vendorProjectStatus } from "utils/other/helper";
import { useContext } from "react";
import { UserDashboardContext } from "utils/contexts";
import {
  PROJECT_FREQUENCY_OPTIONS,
  PROJECT_TIMEFRAME_OPTIONS,
} from "utils/data";
import { formatDate } from "utils/other/helper";

export const VendorPojectCard = ({ grantDetails = {}, loading = false }) => {
  const vendorProjectObj = vendorProjectStatus(grantDetails?.approval);
  const { handleGrantDetailsClick } = useContext(UserDashboardContext);
  const {
    id,
    projectName,
    focusAreaApplicable,
    projectTimeFrame,
    requestedBudget,
    totalProjectCost,
    frequency,
    projectBrief,
    expectedImpacts,
    createdAt,
  } = grantDetails;
  return (
    <Box
      sx={{
        ...style.vendorProjectCard,
        borderColor: vendorProjectObj.color,
      }}
      onClick={() => !loading ? handleGrantDetailsClick(id) : null}
    >
      {/* Header */}
      {loading ? (
        <Skeleton variant="rectangular" height={75} />
      ) : (
        <Grid container sx={style.vendorProjectCardHead}>
          <Grid item xs={12} sm={6} py={2}>
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                color: "white",
              }}
            >
              {projectName}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            py={1}
            direction={"column"}
            alignItems={{ xs: "flex-start", sm: "flex-end" }}
          >
            <Grid item>
              <Box
                sx={{
                  ...style.projectCardChip,
                  background: vendorProjectObj.color,
                }}
                variant="contained"
                size="small"
              >
                {vendorProjectObj.buttonText}
              </Box>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "white" }}>
                {formatDate(createdAt)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* Content */}
      <Stack sx={style.vendorProjectContentBox} spacing={2}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Stack flex={1}>
            <Typography variant="subtitle1" color="textlight.main">
              Focus Area
            </Typography>
            <Typography sx={{ textTransform: "capitalize" }}>
              {loading ? <Skeleton /> : focusAreaApplicable || "--"}
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="subtitle1" color="textlight.main">
              Project Time Frame
            </Typography>
            <Typography>
              {loading ? (
                <Skeleton />
              ) : (
                PROJECT_TIMEFRAME_OPTIONS.find(
                  (p) => p.value === projectTimeFrame
                )?.label || "--"
              )}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Stack flex={1}>
            <Typography variant="subtitle1" color="textlight.main">
              Requested Budget
            </Typography>
            <Typography>
              {loading ? <Skeleton /> : "AED " + requestedBudget || "--"}
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="subtitle1" color="textlight.main">
              Total Project Cost
            </Typography>
            <Typography>
              {loading ? <Skeleton /> : "AED " + totalProjectCost || "--"}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Stack flex={1}>
            <Typography variant="subtitle1" color="textlight.main">
              Total Impact
            </Typography>
            <Typography>
              {/* total sum of impacts */}
              {loading ? (
                <Skeleton />
              ) : (
                expectedImpacts?.reduce((sum, item) => sum + item.total, 0) ||
                "--"
              )}
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="subtitle1" color="textlight.main">
              Reporting Frequency
            </Typography>
            <Typography>
              {loading ? (
                <Skeleton />
              ) : (
                PROJECT_FREQUENCY_OPTIONS.find((p) => p.value === frequency)
                  ?.label || "--"
              )}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Divider sx={{ bgcolor: "primary.light" }} />
        </Stack>
        <Stack flex={1}>
          <Typography variant="subtitle1" color="textlight.main">
            Project Brief
          </Typography>
          <Typography>
            {loading ? (
              <>
                <Skeleton />
                <Skeleton width={"40%"} />
              </>
            ) : (
              projectBrief || "--"
            )}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
