import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { MyProfileInfoCard, VendorContactsInfoCard } from "UI/molecules";
import { VendorAddressInfoCard } from "UI/molecules";
import { ProfileDetailsContext } from "utils/contexts";

export const ProfileDetailsTemplate = () => {
  const { userProfile, isAdmin } = useContext(ProfileDetailsContext);
  return (
    <Grid container py={4} px={{ xs: 2, sm: 4 }} spacing={3}>
      {!isAdmin ? (
        <>
          <Grid item xs={12}>
            <VendorAddressInfoCard
              title={"Basic Details"}
              data={userProfile}
            />
          </Grid>
          <Grid item xs={12}>
            <VendorContactsInfoCard
              title={"Contacts"}
              data={userProfile.contacts}
            />
          </Grid>
        </>
      ) :
        <Grid item xs={12} md={isAdmin ? 12 : 6}>
          <MyProfileInfoCard title={"My Profile"} data={userProfile} />
        </Grid>
      }
    </Grid>
  );
};
