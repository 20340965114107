export {
  ASYNC_ROUTES,
  METHODS,
  THUNK_STATUS,
  SERVICE_URL,
} from "./redux.constants";
export { ROUTES } from "./routes.constants";

export const USER_ROLES = {
  ADMIN: "admin",
  USER: "user",
};
