import { Navigate } from "react-router-dom";
import { AuthLayout } from "layout/authLayout/auth.layout";
import { AdminLogin, ForgotPassword, Login, Signup } from "UI/pages";
import { ROUTES } from "utils/constants";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GOOGLE_CAPTCHA_SITE_KEY } from "config";

// Define main application routes
export const authRoutes = [
  {
    path: ROUTES.BASE,
    element: <AuthLayout />,
    children: [
      { path: ROUTES.BASE, element: <Login />, exact: true },
      { path: ROUTES.REGISTER, element: <Signup />, exact: true },
      {
        path: ROUTES.ADMIN_LOGIN,
        element: <AdminLogin />,
        exact: true,
      },
      {
        path: ROUTES.INVITATION,
        element: <Signup />,
        exact: true,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: (
          <>
            <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA_SITE_KEY}>
              <ForgotPassword />
            </GoogleReCaptchaProvider>
          </>
        ),
        exact: true,
      },
      { path: "*", element: <Navigate to={"/"} />, exact: true },
    ],
  },
];
