import React, { useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Controller, useForm } from "react-hook-form";

export const InputField = (props) => {
  const { label, fullWidth, EndIcon, name, type, control, ...rest } = props;
  const { control: initialControl } = useForm({});
  const usedControl = control || initialControl;
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <Controller
      name={name}
      control={usedControl}
      defaultValue=""
      render={({ field, fieldState }) => {
        return (
          <>
            <TextField
              {...field}
              InputProps={{
                disableUnderline: true,
                ...(type === "password" && {
                  endAdornment: (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={handlePasswordVisibility}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </Box>
                  ),
                }),
                ...(EndIcon && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <EndIcon />
                    </InputAdornment>
                  ),
                }),
              }}
              onWheel={(e) => type === "number" ? e.target.blur() : null}
              fullWidth={fullWidth}
              label={label}
              variant="filled"
              type={showPassword ? "text" : type}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...rest}
            />
          </>
        );
      }}
    />
  );
};
