import React, { useContext } from "react";
import _ from "lodash";
import {
  Container,
  Grid,
  Typography,
  Stack,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { style } from "./style";
import { VendorDetailsContext } from "utils/contexts";
import { THUNK_STATUS } from "utils/constants";
import { BasicVendorDetailsInfoCard } from "UI/molecules/BasicVendorDetailsInfoCard";
import { DocumentInfoCard, VendorAddressInfoCard } from "UI/molecules";
import { ApproveRejectButtons, VendorContactsInfoCard } from "UI/molecules";
import { VENDOR_STATUS } from "utils/data";
import { GoBack } from "UI/molecules/GoBack";
import LockResetIcon from '@mui/icons-material/LockReset';
import HttpsOutlined from '@mui/icons-material/HttpsOutlined';
import { ImageIconButton } from "UI/atoms";


export const VendorDetailsTemplate = () => {
  const {
    singleVendorData,
    singleVendorStatus,
    handleVendorStatusChange,
    approveBtnLoading,
    encryptedToken,
    role,
    changePasswordRequest
  } = useContext(VendorDetailsContext);
  return (
    <>
      <Grid sx={style.grantDetailBackground}></Grid>
      <Container maxWidth="lg">
        <Grid container pt={8} mb={10}>
          <Grid item container justifyContent={"space-between"} rowSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <GoBack />
                <Typography variant="h2" sx={style.whiteText}>
                  Vendor Details
                </Typography>
                {singleVendorData?.vender?.isChangePasswordRequested ?
                  <Tooltip title="Requested for Change Password">
                    <HttpsOutlined color="success" />
                  </Tooltip>
                  :
                  <Tooltip title="Request for Change Password">
                    <ImageIconButton onClick={changePasswordRequest} >
                      <LockResetIcon fontSize="large" color="error" />
                    </ImageIconButton>
                  </Tooltip>
                }

              </Stack>
            </Grid>
            {singleVendorStatus !== THUNK_STATUS.PENDING && (
              <ApproveRejectButtons
                handleApproveButton={() =>
                  handleVendorStatusChange({
                    vendorData: singleVendorData,
                    status: VENDOR_STATUS.APPROVED,
                  })
                }
                handleRejectButton={() => {
                  handleVendorStatusChange({
                    vendorData: singleVendorData,
                    status: VENDOR_STATUS.REJECTED,
                  });
                }}
                showApprove={
                  singleVendorData?.status !== VENDOR_STATUS.APPROVED
                }
                showReject={singleVendorData?.status !== VENDOR_STATUS.REJECTED}
                approveLoading={approveBtnLoading}
              />
            )}
          </Grid>
          <Grid item pt={5} xs={12}>
            {singleVendorStatus === THUNK_STATUS.PENDING ? (
              <Stack p={4} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
              </Stack>
            ) : (
              !_.isEmpty(singleVendorData) && (
                <Stack gap={4}>
                  <BasicVendorDetailsInfoCard
                    title={"Basic Details"}
                    data={singleVendorData}
                    status="success"
                  />
                  <VendorContactsInfoCard
                    title={"Contacts"}
                    data={singleVendorData.contacts}
                    status="success"
                  />
                  <VendorAddressInfoCard
                    title={"Address"}
                    data={singleVendorData}
                    status="success"
                  />
                  <DocumentInfoCard
                    title={"Documents"}
                    data={singleVendorData.vender}
                    status="success"
                    encryptedToken={encryptedToken}
                    role={role}
                  />
                </Stack>
              )
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
