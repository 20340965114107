import { Box, Grid, Stack, Typography } from "@mui/material";
import { FileInput, InputField } from "UI/atoms";
import React, { useContext } from "react";
import { SignupContext } from "utils/contexts/signupContext";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
// import '../atoms/Filepond.css'

export const SecondaryContact = () => {
  const { errors, control, setValue, trigger, isSubmitted, touchedFields, getValues } = useContext(SignupContext);
  return (
    <>
      <div className="formGroupContainer">
        <Typography
          variant="subtitle2"
          className="formGroupTitle"
          mt={1}
          gutterBottom
        >
          Secondary Contact Person
        </Typography>
        <Grid container justifyContent={"center"} spacing={2.5}>
          <Grid item xs={12}>
            <InputField
              fullWidth={true}
              variant="filled"
              name={"secondaryContact.title"}
              label={"Name"}
              errors={errors}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={6}>
            <InputField
              fullWidth={true}
              variant="filled"
              name={"secondaryContact.email"}
              label={"Email"}
              type={"email"}
              errors={errors}
              control={control}
              EndIcon={EmailIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={6}>
            <InputField
              fullWidth={true}
              variant="filled"
              name={"secondaryContact.mobile"}
              label={"Mobile"}
              onBlur={() => {
                setValue('secondaryContact.mobile', getValues('secondaryContact.mobile'), { shouldTouch: true });
                trigger('secondaryContact.mobile');
              }}
              onChange={(e) => {
                const val = e.target.value;
                const cleanedValue = val.replace(/\D/g, '');
                setValue('secondaryContact.mobile', cleanedValue);
                if (touchedFields?.secondaryContact?.mobile || isSubmitted) {
                  trigger('secondaryContact.mobile');
                }
              }}
              errors={errors}
              control={control}
              EndIcon={PhoneIcon}
            />
          </Grid>
        </Grid>
      </div>
      {/* Temporary */}
      <div style={{ paddingBottom: 0 }} className="formGroupContainer">
        <Typography
          variant="subtitle2"
          className="formGroupTitle"
          mt={1}
          gutterBottom
        >
          Commercial Registration License
        </Typography>
        <Stack flex={1}>
          <Box className={'dark-filepond'}>
            <FileInput
              name={"documents"}
              errors={errors}
              isSubmitted={isSubmitted}
              {...{ setValue, control, trigger }}
              allowFileTypeValidation={true}
              acceptedFileTypes={["application/pdf"]}
              dropValidation={true}
            />
          </Box>
        </Stack>
      </div>
    </>
  );
};
