export const APP_URL = process.env.REACT_APP_MAIN_APP_URL;
export const FR_APP_URL = process.env.REACT_APP_FR_DEV_URL;
export const AM_URL = process.env.REACT_APP_FR_DEV_AM_URL;
export const WEB_OAUTH_CLIENT = process.env.REACT_APP_FR_TOKEN_CLIENT_ID;
export const USER_LOGIN_JOURNEY = process.env.REACT_APP_FR_USER_LOGIN_JOURNEY;
export const FR_SCOPE = process.env.REACT_APP_FR_SCOPE;
export const USER_FORGET_PASSWORD_JOURNEY =
  process.env.REACT_APP_FR_USER_FORGET_PASSWORD_JOURNEY;
export const USER_CHANGE_PASSWORD_JOURNEY =
  process.env.REACT_APP_FR_USER_CHANGE_PASSWORD_JOURNEY;
export const USER_REGISTRATION_JOURNEY =
  process.env.REACT_APP_FR_USER_REGISTRATION_JOURNEY;
export const INTROSPECT_CLIENT_ID =
  process.env.REACT_APP_FR_INTROSPECT_CLIENT_ID;
export const SALT_KEY = process.env.REACT_APP_SALT_KEY;

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
    redirectUri: "/admin",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["user.read"],
};
export const ENCRYPTED_KEY = process.env.REACT_APP_ENCRYPTED_KEY;
export const STORAGE_ACCOUNT_NAME =
  process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
export const SAS_TOKEN = process.env.REACT_APP_STORAGE_SAS_TOKEN;
export const GOOGLE_CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
