import React from "react";
import { Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PinIcon from "@mui/icons-material/Pin";
import { InfoCardTable, TitleValue } from ".";

export const FocusAreaInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  const tableData = {
    head: [
      {
        label: "Area",
        name: "title",
      },
      {
        label: "Impact in # beneficiaries per month",
        name: "perMonth",
      },
      {
        label: "Total Impact",
        name: "total",
      },
    ],
    rowData: data.expectedImpacts.map((exp) => {
      return {
        title: exp.grantQuestion.question,
        perMonth: exp.perMonth,
        total: exp.total,
      };
    }),
  };
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            ...style.infoCardContent,
          }}
        >
          <TitleValue
            title={"Focus Area Applicable"}
            value={data.focusAreaApplicable}
            fullWidth
          />
          <Stack
            py={2}
            px={3}
            sx={{
              outline: "1px solid rgba(0, 0, 0, 0.05)",
              ...style.secondaryContainer,
            }}
            gap={2}
          >
            {/* Problem Solving */}
            <Stack sx={style.infoCardBackground}>
              {/* Header */}
              <Stack
                sx={{
                  ...style.infoCardHead,
                  p: 0,
                  justifyContent: "space-between",
                }}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
                  <Stack sx={style.infoCardHeadIcon}>
                    <ErrorOutlineIcon />
                  </Stack>
                  <Typography variant="h3" sx={{ fontWeight: 500 }}>
                    Which Problem Are You Solving?
                  </Typography>
                </Stack>
              </Stack>
              {/* Content */}
              <Stack
                sx={{
                  ...style.infoCardContent,
                  background: "white",
                }}
              >
                {data.problemSolvings.map((problem, ind) => {
                  return (
                    <TitleValue
                      key={ind}
                      title={problem.grantQuestion.question}
                      value={problem.value ? "Yes" : "No"}
                      fullWidth
                    />
                  );
                })}
              </Stack>
            </Stack>
            {/* Expected Impacts */}
            <Stack sx={style.infoCardBackground}>
              {/* Header */}
              <Stack
                sx={{
                  ...style.infoCardHead,
                  p: 0,
                  justifyContent: "space-between",
                }}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
                  <Stack sx={style.infoCardHeadIcon}>
                    <PinIcon />
                  </Stack>
                  <Typography variant="h3" sx={{ fontWeight: 500 }}>
                    Expected impact in numbers
                  </Typography>
                </Stack>
              </Stack>
              {/* Content */}
              <InfoCardTable tableData={tableData} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
