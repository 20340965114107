import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import {
  changeFeedbackStatusService,
  changeGrantStatusService,
  changeUpdateStatusService,
  createGrantFeedbackService,
  createGrantService,
  getAllGrantService,
  getGrantStatesService,
  getQuestionsService,
  getReportService,
  getRequestForUpdateGrantService,
  getSingleGrantService,
  submitReportService,
} from "redux/services";

export const createGrantAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.CREATE_GRANT,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createGrantService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getQuestionsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_QUESTIONS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getQuestionsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllGrantAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_ALL_GRANTS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAllGrantService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRequestForUpdateGrantAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_UPDATE_GRANTS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getRequestForUpdateGrantService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSingleGrantAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_SINGLE_GRANT,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSingleGrantService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGrantStatesAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_GRANT_STATES,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getGrantStatesService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createGrantFeedbackAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.CREATE_GRANT_FEEDBACK,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createGrantFeedbackService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeFeedbackStatusAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.CHANGE_FEEDBACK_STATUS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await changeFeedbackStatusService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeGrantStatusAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GRANT_STATUS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await changeGrantStatusService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const submitReportAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.SUBMIT_REPORTS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await submitReportService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getReportsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_REPORTS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getReportService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeUpdateStatusAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.UPDATE_STATUS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await changeUpdateStatusService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
