import { filterEmptyKeys, replaceUrl } from "utils/other/helper";
import { METHODS, SERVICE_URL } from "../../utils/constants";
import { AppClient } from "../../utils/network";

// Function for making a login API request
export const createGrantService = (data) => {
  const isUpdate = data?.id;
  return AppClient({
    url: isUpdate ? SERVICE_URL.GRANT + `/${isUpdate}` : SERVICE_URL.GRANT,
    method: isUpdate ? METHODS.PUT : METHODS.POST,
    data,
  });
};

export const getQuestionsService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.GET_GRANT_QUESTIONS, payload),
    method: METHODS.GET,
  });
};

export const getAllGrantService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GRANT,
    method: METHODS.GET,
    params: filterEmptyKeys(payload?.params || {}),
  });
};

export const getRequestForUpdateGrantService = (payload) => {
  const { params } = payload;
  return AppClient({
    url: SERVICE_URL.GRANT,
    method: METHODS.GET,
    params,
  });
};

export const getSingleGrantService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.SINGLE_GRANT, payload),
    method: METHODS.GET,
  });
};

export const getGrantStatesService = () => {
  return AppClient({
    url: SERVICE_URL.GRANT_STATES,
    method: METHODS.GET,
  });
};

export const createGrantFeedbackService = (payload) => {
  return AppClient({
    url: SERVICE_URL.FEEDBACK,
    method: METHODS.POST,
    data: payload,
  });
};

export const changeFeedbackStatusService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.FEEDBACK_STATUS, payload),
    method: METHODS.PUT,
  });
};

export const changeGrantStatusService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.GRANT_STATUS, payload),
    method: METHODS.PUT,
  });
};

export const submitReportService = (payload) => {
  return AppClient({
    url: SERVICE_URL.REPORTS,
    method: METHODS.POST,
    data: payload,
  });
};

export const getReportService = (payload) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.GET_REPORTS, payload),
    method: METHODS.GET,
  });
};

export const changeUpdateStatusService = (data) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.GRANT_UPDATE_STATUS, data),
    method: METHODS.POST,
  });
};
