import { Grid, Typography } from "@mui/material";
import React from "react";
import { style } from "./style";

export const LoginHeader = ({ title, desc }) => {
  return (
    <Grid item mx={1}>
      <Typography variant="h1" sx={style.loginHeader}>
        {title}
      </Typography>
      <Typography variant="body2" sx={style.loginHeader}>
        {desc}
      </Typography>
    </Grid>
  );
};
