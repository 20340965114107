import React from "react";
import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { InputField, SelectInput } from "UI/atoms";
import { useContext } from "react";
import { GrantCreateContext } from "utils/contexts";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

export const OtherImpactForm = ({ title, Icon = AccountTreeIcon }) => {
  const {
    control,
    errors,
    deleteTargetRow,
    addTargetRow,
    otherTargetField,
    trigger,
    values,
    IMPACT_TARGET_OPTIONS,
    watch,
    setValue,
  } = useContext(GrantCreateContext);
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{
            ...style.infoCardHead,
            justifyContent: "space-between",
          }}
        >
          <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 1.5 }}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
          <IconButton onClick={addTargetRow} color="primary">
            <AddIcon />
          </IconButton>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            background: "white",
            p: 2,
          }}
        >
          {otherTargetField.fields.length ? (
            otherTargetField.fields.map((row, index) => {
              const fieldNamePrefix = `otherTargets[${index}]`;
              return (
                <Grid key={row.id} container spacing={2}>
                  <Grid item xs={12} my={2}>
                    <Stack direction="row" alignItems={"center"}>
                      <Divider variant="fullWidth" sx={{ flex: 1 }}>
                        <Stack sx={style.formCount}>
                          <Typography variant="h4">{index + 1}</Typography>
                        </Stack>
                      </Divider>
                      <IconButton
                        onClick={() => deleteTargetRow(index)}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item container spacing={2} alignItems={"center"}>
                    <Grid item xs={12} md={6}>
                      <SelectInput
                        name={`${fieldNamePrefix}.grantQuestionId`}
                        label={"Impact Target"}
                        options={IMPACT_TARGET_OPTIONS}
                        control={control}
                        errors={errors}
                        values={values}
                        onChange={() => {
                          trigger(`${fieldNamePrefix}.grantQuestionId`);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <InputField
                        name={`${fieldNamePrefix}.perMonth`}
                        type={"number"}
                        label={"Monthly Impact"}
                        errors={errors}
                        control={control}
                        onChange={(e) => {
                          setValue(`${fieldNamePrefix}.perMonth`, e.target.value);
                          trigger(`${fieldNamePrefix}.perMonth`);
                          if (watch(`${fieldNamePrefix}.total`)) {
                            trigger(`${fieldNamePrefix}.total`);
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <InputField
                        name={`${fieldNamePrefix}.total`}
                        type={"number"}
                        label={"Yearly Impact"}
                        errors={errors}
                        control={control}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Stack
              sx={{
                pl: 2,
                pt: 2,
              }}
            >
              <Typography>
                Click + button to add other impact information.
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};
