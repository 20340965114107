import React from "react";
import { Controller } from "react-hook-form";
import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  FormHelperText,
} from "@mui/material";

export const ToggleButtonAtom = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue || ""}
      render={({ field, fieldState }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              ...(props.fullHeight && {
                height: "100%",
              }),
            }}
          >
            <div
              style={{
                borderRadius: "11px",
                background: "rgba(0, 0, 0, 0.06)",
                padding: "15px 24px",
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "18px",
                  fontWeight: 500,
                  pb: 1,
                }}
              >
                {props.label}
              </Typography>
              <ToggleButtonGroup
                orientation={props.orientation}
                exclusive
                fullWidth
                {...field}
                value={field.value}
                onChange={(e, value) => {
                  if (value === null) return;
                  field.onChange(value);
                }}
              >
                {props.options.map((option, ind) => (
                  <ToggleButton
                    key={ind}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "black",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      },
                      fontWeight: 500,
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "15px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                    value={option.value}
                  >
                    {option.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            <FormHelperText sx={{ pl: 2 }} error={fieldState.invalid}>
              {fieldState.error?.message}
            </FormHelperText>
          </div>
        );
      }}
    />
  );
};
