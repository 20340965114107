import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

export const DateInput = (props) => {
  const { label, fullWidth, endIcon, name, type, errors, control, ...rest } =
    props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field, fieldState }) => {
        return (
          <DatePicker
            {...field}
            fullWidth={fullWidth}
            label={label}
            value={dayjs(field.value)}
            {...rest}
            slotProps={{
              textField: {
                variant: "filled",
                fullWidth: true,
                error: fieldState.invalid,
                helperText: fieldState.error?.message,
              },
            }}
            sx={{
              "& .MuiFilledInput-root:before": {
                borderBottom: 0,
              },
              "& .MuiFilledInput-root:hover:before": {
                borderBottom: 0,
              },
            }}
          />
        );
      }}
    />
  );
};
