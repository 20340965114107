import { Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";

export const TextLottie = ({ animationData, text, variant = "h3" }) => {
  return (
    <>
      <Lottie
        options={{
          animationData,
        }}
        height={200}
        width={200}
      />
      <Typography variant={variant} sx={{ opacity: 0.8 }}>
        {text}
      </Typography>
    </>
  );
};
