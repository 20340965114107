export const NotificationDesc = (status) => {
  switch (status) {
    case "grant_approved":
      return "We're excited to let you know that your grant request has been approved!";
    case "grant_in_review":
      return "Your grant request is currently under review. We will notify you once there are updates on its status.";
    case "grant_rejected":
      return "Thank you for submitting your grant request. After careful review, we regret to inform you that your grant request has not been approved.";
    case "grant_update_required":
      return "We wanted to inform you that your grant request is currently in a pending status. Our team requires additional information to complete the review process.";
    case "update_request_approved":
      return "We're excited to let you know that your request for update grant has been approved!";
    case "update_request_rejected":
      return "Thank you for submitting the request. After careful review, we regret to inform you that your request for update grant has not been approved.";
    default:
      return "";
  }
};

export const grantDetailsDesc = {
  approved:
    "Log reports accurately to reflect the status of the milestone and its impact.",
  rejected: "Unfortunately, we cannot accept this grant application.",
  update_required:
    "Athar team requires additional information, please refer feedback and resubmit.",
  draft: "Submit your grant to take action.",
  in_review: "Athar team will review your application.",
};
