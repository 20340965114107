import { createSlice } from "@reduxjs/toolkit";
import {
  getprofileAsyncThunk,
  loginAsyncThunk,
  registerAsyncThunk,
  registerByInviteAsyncThunk,
  validateInvitationAsyncThunk,
} from "redux/asyncThunk";
import { THUNK_STATUS, USER_ROLES } from "utils/constants";

const initialState = {
  user: {},
  token: null,
  loginStatus: null,
  registerStatus: null,
  theme: "light",
  userProfile: {},
  registerAPIStatus: null,
  validationApiStatus: null,
  isValidLink: false,
  invitationData: {},
  registerByInviteApiStatus: null,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state = initialState;
      localStorage.clear();
      window.location.reload();
    },
    resetRedux: (state) => {
      state.loginStatus = null;
      state.registerStatus = null;
      state.registerAPIStatus = null;
      state.registerByInviteApiStatus = null;
      state.isValidLink = false;
    },
    toggleThemeMode: (state, action) => {
      state.mode = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.userProfile = action.payload;
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsyncThunk.pending, (state) => {
        state.loginStatus = THUNK_STATUS.PENDING;
      })
      .addCase(loginAsyncThunk.fulfilled, (state, action) => {
        state.loginStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(loginAsyncThunk.rejected, (state, action) => {
        state.loginStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(getprofileAsyncThunk.fulfilled, (state, action) => {
        if (state?.user?.role !== USER_ROLES.ADMIN) {
          state.userProfile = action.payload.data;
          state.user = action.payload.data;
        }
      })
      .addCase(registerAsyncThunk.pending, (state) => {
        state.registerAPIStatus = THUNK_STATUS.PENDING;
      })
      .addCase(registerAsyncThunk.fulfilled, (state) => {
        state.registerAPIStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(registerAsyncThunk.rejected, (state) => {
        state.registerAPIStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(validateInvitationAsyncThunk.pending, (state) => {
        state.validationApiStatus = THUNK_STATUS.PENDING;
      })
      .addCase(validateInvitationAsyncThunk.fulfilled, (state, action) => {
        state.validationApiStatus = THUNK_STATUS.SUCCESS;
        state.isValidLink = action.payload?.data?.valid;
        state.invitationData = action.payload?.data;
      })
      .addCase(validateInvitationAsyncThunk.rejected, (state, action) => {
        state.validationApiStatus = THUNK_STATUS.REJECTED;
        state.isValidLink = action.payload.response?.data?.valid;
      })
      .addCase(registerByInviteAsyncThunk.pending, (state) => {
        state.registerByInviteApiStatus = THUNK_STATUS.PENDING;
      })
      .addCase(registerByInviteAsyncThunk.fulfilled, (state) => {
        state.registerByInviteApiStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(registerByInviteAsyncThunk.rejected, (state) => {
        state.registerByInviteApiStatus = THUNK_STATUS.REJECTED;
      });
  },
});

export default AuthSlice.reducer;
export const { logout, toggleThemeMode, resetRedux, setToken, setUser } =
  AuthSlice.actions;
