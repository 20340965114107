import React, { forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { GrantDetailsContext } from "utils/contexts/admin/grantsContext";
import { useContext } from "react";
import Typography from "@mui/material/Typography";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Grid, IconButton, Stack, alpha } from "@mui/material";
import { InputField } from "UI/atoms";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { style } from "./style";
import _ from "lodash";
import { GRANT_APPROVAL_STATUS } from "utils/data";
import { LoadingButton } from "@mui/lab";
import { THUNK_STATUS } from "utils/constants";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function GrantRequestDialog() {
  const {
    handleRequestDialogClose,
    grantRequestDialog,
    handleRemoveFeedbackInput,
    handleAddFeedbackInput,
    handleFeedBackSubmit,
    handleSubmit,
    feedBackField,
    control,
    singleGrantData,
    isAdmin,
    changeFeedBackStatus,
    createGrantFeedbackApiStatus
  } = useContext(GrantDetailsContext);
  const pendingFeedbacks =
    singleGrantData?.feedbacks?.filter((i) => !i.status) || [];
  return (
    <>
      <Dialog
        component={"form"}
        onSubmit={handleSubmit(handleFeedBackSubmit)}
        open={!!grantRequestDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleRequestDialogClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Stack
              sx={{
                background: (theme) => theme.palette.info.main,
                p: 0.5,
                borderRadius: 1,
              }}
            >
              <EditNoteIcon sx={{ color: "white" }} />
            </Stack>
            <Stack direction={"column"}>
              <Typography variant="h3">Request Update</Typography>
              <Typography variant="subtitle2" color="textlight.main">
                Add Upto 3 Feedbacks
              </Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack my={2}>
            <Typography variant="h5" sx={{ opacity: 0.8, mb: 1 }}>
              Pending Feedbacks:
            </Typography>
            {pendingFeedbacks?.map((feed, ind) => {
              return (
                <Stack
                  key={ind}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    p: 0.5,
                    background: (t) => alpha(t.palette.error.main, 0.1),
                    my: 0.5,
                    borderRadius: 1,
                  }}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <ErrorOutlineRoundedIcon sx={{ opacity: 0.7 }} />
                    <Typography
                      className="feedback-text"
                      sx={{
                        ...style.infoCardDesc,
                        opacity: 0.7,
                      }}
                    >
                      {feed.feedback}
                    </Typography>
                  </Stack>

                  {isAdmin &&
                    !feed.status &&
                    singleGrantData?.approval ===
                    GRANT_APPROVAL_STATUS.IN_REVIEW && (
                      <Typography
                        color="inherit"
                        sx={{
                          opacity: 0.5,
                          fontSize: "12px",
                          color: "black",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          changeFeedBackStatus(feed.id, "approved")
                        }
                      >
                        Mark as complete
                      </Typography>
                    )}
                </Stack>
              );
            })}
          </Stack>
          <Grid container spacing={2}>
            {!_.isEmpty(feedBackField.fields) &&
              feedBackField.fields.map((input, index) => {
                const fieldNamePrefix = `feedbacks[${index}]`;
                return (
                  <Grid
                    item
                    key={input.id}
                    container
                    xs={12}
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <Grid item xs={11}>
                      <InputField
                        name={`${fieldNamePrefix}.feedback`}
                        label={`Feedback ${index + 1}`}
                        control={control}
                        fullWidth
                        multiline
                        maxRows={1}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => handleRemoveFeedbackInput(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          {feedBackField.fields.length + (pendingFeedbacks?.length || 0) <
            3 && (
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Button
                  variant="contained"
                  onClick={handleAddFeedbackInput}
                  sx={{ mt: 2 }}
                  color="info"
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Grid>
            )}
        </DialogContent>
        <Stack p={1} direction={"row"} justifyContent={"space-between"}>
          <Button
            color="pending"
            startIcon={<ArrowBackIcon />}
            sx={{ px: 1, py: 0 }}
            onClick={handleRequestDialogClose}
          >
            Back
          </Button>
          <LoadingButton
            loading={createGrantFeedbackApiStatus === THUNK_STATUS.PENDING}
            color="info" type="submit" endIcon={<ArrowForwardIcon />}>
            Submit
          </LoadingButton>
        </Stack>
      </Dialog>
    </>
  );
}
