import React, { forwardRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { Box, Grid, Stack } from "@mui/material";
import { InputField } from "UI/atoms";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { submitReportValidationSchema } from "utils/form/validationSchema";
import {
  getReportsAsyncThunk,
  getSingleGrantAsyncThunk,
  submitReportAsyncThunk,
} from "redux/asyncThunk";
import { LoadingButton } from "@mui/lab";
import { THUNK_STATUS } from "utils/constants";
import _ from "lodash";
import { setFieldError } from "utils/other/helper";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function SubmitReportDialog({ singleGrantData }) {
  // -----------------Hooks----------------- //
  const [submitReportDialog, setSubmitReportDialog] = useState(false);
  const {
    grants: { submitReportAPIStatus },
  } = useSelector((state) => state.grant);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    setError,
    register,
  } = useForm({
    defaultValues: {
      grantId: singleGrantData.id,
    },
    resolver: yupResolver(submitReportValidationSchema),
  });

  // -----------------Functions----------------- //
  const handleSubmitReportDialogOpen = () => {
    setSubmitReportDialog(true);
  };
  const handleSubmitReportDialogClose = () => {
    setSubmitReportDialog(false);
  };
  const handleSubmitReport = () => {
    return handleSubmit((data) => {
      dispatch(submitReportAsyncThunk(data))
        .unwrap()
        .then(() => {
          handleSubmitReportDialogClose();
          reset();
          dispatch(getSingleGrantAsyncThunk({ id: singleGrantData.id }));
          dispatch(getReportsAsyncThunk({ id: singleGrantData.id }));
        })
        .catch((err) => setFieldError(err, setError));
    });
  };

  return (
    <>
      <Grid item>
        <Button
          startIcon={<FileCopyOutlinedIcon />}
          variant="contained"
          color="info"
          sx={{ justifyContent: "flex-start" }}
          fullWidth
          onClick={handleSubmitReportDialogOpen}
        >
          Submit Report
        </Button>
      </Grid>
      <Dialog
        component={"form"}
        onSubmit={handleSubmitReport()}
        open={submitReportDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleSubmitReportDialogClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Stack
              sx={{
                background: (theme) => theme.palette.info.main,
                p: 0.5,
                borderRadius: 1,
              }}
            >
              <FileCopyOutlinedIcon sx={{ color: "white" }} />
            </Stack>
            <Stack direction={"column"}>
              <Typography variant="h3">Submit Report</Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <div
              style={{
                borderRadius: "11px",
                background: "rgba(0, 0, 0, 0.06)",
                padding: "15px 24px",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize: "18px",
                  fontWeight: 500,
                  pb: 1,
                }}
              >
                Expected impact in numbers
              </Typography>
              {singleGrantData.expectedImpacts.map((o, i) => {
                return (
                  <Box key={i}>
                    <Typography variant="caption">
                      {i + 1}. {o.grantQuestion.question}
                    </Typography>
                    <Grid container mb={2} spacing={2}>
                      <input
                        type="hidden"
                        defaultValue={"expectedImpact"}
                        {...register(`reports[${i}].questionType`)}
                      />
                      <input
                        type="hidden"
                        defaultValue={o.id}
                        {...register(`reports[${i}].questionId`)}
                      />
                      <Grid item xs={12}>
                        <InputField
                          name={`reports[${i}].achieved`}
                          label={"Target Achieved"}
                          type={"number"}
                          control={control}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </div>

            {!_.isEmpty(singleGrantData.otherTargets) &&
              <div
                style={{
                  borderRadius: "11px",
                  background: "rgba(0, 0, 0, 0.06)",
                  padding: "15px 24px",
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontSize: "18px",
                    fontWeight: 500,
                    pb: 1,
                  }}
                >
                  Other Targets
                </Typography>
                {singleGrantData.otherTargets.map((o, i) => {
                  const fieldNamePrefix = `reports[${i + singleGrantData.expectedImpacts.length
                    }]`;
                  return (
                    <Box key={i}>
                      <Typography variant="caption">
                        {i + 1}. {o.grantQuestion.question}
                      </Typography>
                      <Grid container mb={2} spacing={2}>
                        <input
                          type="hidden"
                          defaultValue={o.id}
                          {...register(`${fieldNamePrefix}.questionId`)}
                        />
                        <input
                          type="hidden"
                          defaultValue={"otherTarget"}
                          {...register(`${fieldNamePrefix}.questionType`)}
                        />
                        <Grid item xs={12}>
                          <InputField
                            name={`${fieldNamePrefix}.achieved`}
                            label={"Target Achieved"}
                            type={"number"}
                            control={control}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </div>}
          </Stack>
        </DialogContent>
        <Stack px={1} pb={1} direction={"row"} justifyContent={"space-between"}>
          <Button
            color="pending"
            startIcon={<ArrowBackIcon />}
            sx={{ px: 1, py: 0 }}
            onClick={handleSubmitReportDialogClose}
          >
            Back
          </Button>
          <LoadingButton
            loading={submitReportAPIStatus === THUNK_STATUS.PENDING}
            loadingPosition="end"
            color="info"
            type="submit"
            endIcon={<ArrowForwardIcon />}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Dialog>
    </>
  );
}
