import React, { useContext } from "react";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SaveIcon from "@mui/icons-material/Save";
import { GRANT_APPROVAL_STATUS, GrantStepFormComponents } from "utils/data";
import { GrantCreateContext } from "utils/contexts";
import { Collapse, Stack } from "@mui/material";
import { style } from "./style";
import { LoadingButton } from "@mui/lab";
import { THUNK_STATUS } from "utils/constants";
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';

export function GrantStepForm() {
  const {
    handleSubmit,
    onSubmit,
    maxGrantSteps,
    grantActiveStep,
    handleGrantBack,
    createGrantAPIStatus,
    grantApproval,
    setGrantApproval,
  } = useContext(GrantCreateContext);
  const isLastStep = grantActiveStep === maxGrantSteps - 1;
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ flexGrow: 1 }}
    >
      <Box sx={{ width: "100%", py: 2 }}>
        {GrantStepFormComponents[grantActiveStep].formComponent}
      </Box>
      <Stack sx={style.infoCardBackground}>
        <MobileStepper
          variant="progress"
          steps={maxGrantSteps}
          position="static"
          activeStep={grantActiveStep}
          nextButton={
            isLastStep ? (
              <Collapse orientation="horizontal" in={isLastStep}>
                <Stack gap={1} direction={{ xs: 'column', md: 'row' }}>
                  <LoadingButton
                    loading={grantApproval === GRANT_APPROVAL_STATUS.DRAFT && createGrantAPIStatus === THUNK_STATUS.PENDING}
                    loadingPosition="end"
                    size="small"
                    variant="contained"
                    color="pending"
                    endIcon={<SaveAsRoundedIcon />}
                    onClick={() => setGrantApproval(GRANT_APPROVAL_STATUS.DRAFT)}
                    type="submit"
                  >
                    Save as Draft
                  </LoadingButton>
                  <LoadingButton
                    loading={grantApproval === GRANT_APPROVAL_STATUS.IN_REVIEW && createGrantAPIStatus === THUNK_STATUS.PENDING}
                    loadingPosition="end"
                    size="small"
                    variant="contained"
                    color="success"
                    endIcon={<SaveIcon />}
                    type="submit"
                    onClick={() => setGrantApproval(GRANT_APPROVAL_STATUS.IN_REVIEW)}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </Collapse>
            ) : (
              <Button
                size="small"
                type={"submit"}
                endIcon={<KeyboardArrowRight />}
              >
                Next
              </Button>
            )
          }
          backButton={
            <>
              <Button
                size="small"
                onClick={handleGrantBack}
                startIcon={<KeyboardArrowLeft />}
              >
                Back
              </Button>
              {/* <Box pr={1}>{`${grantActiveStep + 1}/${maxGrantSteps}`}</Box> */}
            </>
          }
        />
      </Stack>
    </Box>
  );
}
