import React, { useContext } from "react";
import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { style } from "./style";
import { GrantDetailsContext } from "utils/contexts";
import { getTodayDate } from "utils/other/helper";
import { GRANT_APPROVAL_STATUS } from "utils/data";
import { grey } from "@mui/material/colors";

export const GrantPageButtons = ({ disableApprove }) => {
  const { handleRequestDialogOpen, handleGrantStatusChange, singleGrantData } =
    useContext(GrantDetailsContext);
  return (
    <Grid item xs={12} md={6}>
      {/* Button with Text */}
      <Hidden mdDown>
        <Stack alignItems={"flex-end"}>
          <Stack gap={1}>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <Button
                startIcon={<CheckRoundedIcon />}
                variant="contained"
                color="success"
                onClick={() => {
                  if (singleGrantData?.requestForEdit) {
                    handleGrantStatusChange(
                      GRANT_APPROVAL_STATUS.APPROVED,
                      "requestForEdit"
                    );
                  } else {
                    handleGrantStatusChange(GRANT_APPROVAL_STATUS.APPROVED);
                  }
                }}
                sx={{
                  "&.Mui-disabled": {
                    background: grey[600],
                  },
                }}
                disabled={disableApprove}
              >
                Approve
              </Button>
              <Button
                startIcon={<CloseIcon />}
                color="error"
                variant="contained"
                onClick={() => {
                  if (singleGrantData?.requestForEdit) {
                    handleGrantStatusChange(
                      GRANT_APPROVAL_STATUS.REJECTED,
                      "requestForEdit"
                    );
                  } else {
                    handleGrantStatusChange(GRANT_APPROVAL_STATUS.REJECTED);
                  }
                }}
              >
                Reject
              </Button>
              {singleGrantData?.approval === GRANT_APPROVAL_STATUS.IN_REVIEW &&
                !singleGrantData?.requestForEdit && (
                  <Button
                    startIcon={<ModeEditIcon />}
                    variant="contained"
                    color="info"
                    onClick={handleRequestDialogOpen}
                  >
                    Request Update
                  </Button>
                )}
            </Stack>
            <Typography variant="h5" color="textlight.main" sx={style.dateFont}>
              {getTodayDate()}
            </Typography>
          </Stack>
        </Stack>
      </Hidden>
      {/* Icon Buttons  */}
      <Hidden mdUp>
        <Stack gap={1}>
          <Stack direction={"row"} gap={1.5}>
            <IconButton sx={style.grantButton("success")} variant="contained"
              onClick={() => {
                if (singleGrantData?.requestForEdit) {
                  handleGrantStatusChange(
                    GRANT_APPROVAL_STATUS.APPROVED,
                    "requestForEdit"
                  );
                } else {
                  handleGrantStatusChange(GRANT_APPROVAL_STATUS.APPROVED);
                }
              }}
            >
              <CheckRoundedIcon />
            </IconButton>
            <IconButton sx={style.grantButton("error")} variant="contained"
              onClick={() => {
                if (singleGrantData?.requestForEdit) {
                  handleGrantStatusChange(
                    GRANT_APPROVAL_STATUS.REJECTED,
                    "requestForEdit"
                  );
                } else {
                  handleGrantStatusChange(GRANT_APPROVAL_STATUS.REJECTED);
                }
              }}
            >
              <CloseIcon />
            </IconButton>
            {singleGrantData?.approval === GRANT_APPROVAL_STATUS.IN_REVIEW &&
              !singleGrantData?.requestForEdit && (
                <IconButton
                  sx={style.grantButton("info")}
                  variant="contained"
                  onClick={handleRequestDialogOpen}
                >
                  <ModeEditIcon />
                </IconButton>
              )}
          </Stack>
          <Typography variant="h5" color="textlight.main" sx={style.dateFont}>
            {getTodayDate()}
          </Typography>
        </Stack>
      </Hidden>
    </Grid>
  );
};
