import React from "react";
import { Grid, Stack, Typography, Box, Skeleton } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { GroupCheckBox, InputField, ToggleButtonAtom } from "UI/atoms";
import { useContext } from "react";
import { GrantCreateContext } from "utils/contexts";
import { FOCUS_AREA_APPLICABLE_OPTIONS } from "utils/data";
import { THUNK_STATUS } from "utils/constants";

export const FocusAreaForm = ({ title, Icon = AccountTreeIcon }) => {
  const {
    control,
    errors,
    isMatchDownSm,
    trigger,
    questions,
    questionStatus,
    register,
    setValue,
    handleExpectedImpactPerMonthChange
  } = useContext(GrantCreateContext);
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack sx={style.infoCardHead}>
          <Stack sx={style.infoCardHeadIcon}>
            <Icon />
          </Stack>
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            background: "white",
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ToggleButtonAtom
                orientation={isMatchDownSm ? "vertical" : "horizontal"}
                name="focusAreaApplicable"
                label={"Focus Area Applicable"}
                options={FOCUS_AREA_APPLICABLE_OPTIONS}
                control={control}
                errors={errors}
              ></ToggleButtonAtom>
            </Grid>
            <Grid item xs={12} sm={6}>
              <GroupCheckBox
                title={"Which Problem Are You Solving?"}
                optionArr={questions?.problem_solving || []}
                control={control}
                name={{
                  arrayName: "problemSolvings",
                  label: "grantQuestionId",
                }}
                errors={errors?.problemSolvings}
                trigger={trigger}
                loading={questionStatus === THUNK_STATUS.PENDING}
                register={register}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  borderRadius: "11px",
                  background: "rgba(0, 0, 0, 0.06)",
                  padding: "15px 24px",
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontSize: "18px",
                    fontWeight: 500,
                    pb: 1,
                  }}
                >
                  Expected impact in numbers
                </Typography>
                {questions.expected_impact.map((o, i) => {
                  return (
                    <Box key={i}>
                      {
                        <Typography variant="caption" >
                          {
                            questionStatus === THUNK_STATUS.PENDING ?
                              <Skeleton width={'70%'} /> :
                              `${i + 1}. ${o.question}`}
                        </Typography>
                      }
                      <Grid container mb={2} pt={1} spacing={2}>
                        <input
                          type="hidden"
                          defaultValue={o.id}
                          {...register(`expectedImpacts[${i}].grantQuestionId`)}
                        />
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name={`expectedImpacts[${i}].perMonth`}
                            label={"Impact in numbers of beneficiaries per month"}
                            type={"number"}
                            control={control}
                            onChange={(e) => handleExpectedImpactPerMonthChange(e, i)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name={`expectedImpacts[${i}].total`}
                            label={"Total Impact"}
                            type={"number"}
                            control={control}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};
