import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { InputField, ToggleButtonAtom } from "UI/atoms";
import { useContext } from "react";
import { GrantCreateContext } from "utils/contexts";
import {
  PROJECT_FREQUENCY_OPTIONS,
  PROJECT_TIMEFRAME_OPTIONS,
} from "utils/data";

export const ProjectInfoForm = ({ title, Icon = AccountTreeIcon }) => {
  const { control, isMatchDownMd, handleRequestBudgetChange } = useContext(GrantCreateContext);
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack sx={style.infoCardHead}>
          <Stack sx={style.infoCardHeadIcon}>
            <Icon />
          </Stack>
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            background: "white",
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputField
                name={"projectName"}
                label={"Project Name"}
                control={control}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                name={"projectBrief"}
                label={"Project Brief"}
                control={control}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ToggleButtonAtom
                orientation={isMatchDownMd ? "vertical" : "horizontal"}
                name="projectTimeFrame"
                label={"Project Time Frame"}
                options={PROJECT_TIMEFRAME_OPTIONS}
                control={control}
              ></ToggleButtonAtom>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ToggleButtonAtom
                orientation={isMatchDownMd ? "vertical" : "horizontal"}
                name="frequency"
                label={"Project Frequency"}
                options={PROJECT_FREQUENCY_OPTIONS}
                control={control}
              ></ToggleButtonAtom>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                name={"requestedBudget"}
                label={"Requested Budget"}
                type={"number"}
                control={control}
                onChange={handleRequestBudgetChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                name={"totalProjectCost"}
                label={"Total Project Cost"}
                type={"number"}
                control={control}
                fullWidth
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};
