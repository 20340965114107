import { Stack, Typography } from "@mui/material";
import React from "react";
import { style } from "./style";
import ImageIcon from '@mui/icons-material/Image';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ImagePreviewDialog } from ".";


export const ImageValue = ({ title, icon, url, encryptedToken, role }) => {
    return (
        <Stack sx={{ ...style.infoCardValue, justifyContent: 'center', alignItems: 'center' }}>
            <Stack sx={style.ImageBox}
                direction={'row'}
                justifyContent={'space-between'}
                gap={1}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                >
                    <ImageIcon />
                    <Typography>
                        {title}
                    </Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={1}
                    sx={{ opacity: 0.8 }}
                >
                    <ImagePreviewDialog url={url} encryptedToken={encryptedToken} role={role}>
                        <RemoveRedEyeIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                    </ImagePreviewDialog>
                    {/* <Divider orientation="vertical" />
                    <DownloadForOfflineIcon fontSize="small" sx={{ cursor: 'pointer' }} /> */}
                </Stack>
            </Stack>
        </Stack>
    );
};
