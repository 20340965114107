import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Stack, Typography } from '@mui/material';
import { PageHeader } from '.';
import { InputField } from 'UI/atoms';
import { LoadingButton } from '@mui/lab';
import { HeaderContext } from 'utils/contexts/vendorsContext';
import { style } from './style';

export function ChangePasswordDialog({ open }) {
    const { control, errors, handleChangePassword, isChangePasswordLoading, handleLogout } = useContext(HeaderContext);
    return (
        <Dialog
            maxWidth={'sm'}
            open={open}
        >
            <Box
                component={'form'}
                noValidate
                onSubmit={handleChangePassword}
            >
                <Stack sx={{ px: { xs: 1, sm: 4 }, minWidth: { sm: '600px', xs: 'unset' }, py: 4 }} justifyContent={'center'} alignItems={'center'} gap={2}
                >
                    <PageHeader
                        title={"Change Password"}
                        desc={
                            "Please change your password for further process."
                        }
                        black={true}
                    />
                    <InputField
                        fullWidth={true}
                        variant="filled"
                        name={"existingPassword"}
                        label={"Old Password"}
                        type={"password"}
                        autoComplete={"on"}
                        errors={errors}
                        control={control}
                    />
                    <InputField
                        fullWidth={true}
                        variant="filled"
                        name={"password"}
                        label={"Password"}
                        type={"password"}
                        autoComplete={"on"}
                        errors={errors}
                        control={control}
                    />
                    <InputField
                        fullWidth={true}
                        variant="filled"
                        name={"confirmPassword"}
                        label={"Confirm Password"}
                        type={"password"}
                        autoComplete={"on"}
                        errors={errors}
                        control={control}
                    />
                    <LoadingButton
                        loading={isChangePasswordLoading}
                        loadingPosition="end"
                        endIcon={<></>}
                        type="submit"
                        variant="contained"
                        fullWidth
                    >
                        Submit
                    </LoadingButton>
                    <Typography variant="body2" sx={style.typographyStyles}>
                        Don't Want to Move? &nbsp;
                        <Box
                            component={"span"}
                            sx={style.logInLinkStyles}
                            onClick={handleLogout}
                        >
                            Logout
                        </Box>
                    </Typography>
                </Stack>
            </Box>
        </Dialog>
    );
}

