import React from "react";
import { Box, FormHelperText, Grid, Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { CheckboxAtom, FileInput, ToggleButtonAtom } from "UI/atoms";
import { useContext } from "react";
import { GrantCreateContext } from "utils/contexts";
import { YES_NO_OPTION } from "utils/data";

export const DeclarationForm = ({ title, Icon = AccountTreeIcon }) => {
  const { control, errors, similarProjectFileUpload, setValue, trigger } =
    useContext(GrantCreateContext);
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack sx={style.infoCardHead}>
          <Stack sx={style.infoCardHeadIcon}>
            <Icon />
          </Stack>
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            background: "white",
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ToggleButtonAtom
                name="previouslyFundedByAldar"
                label={
                  "Have this entity previously received funding or sponsorship from Aldar?"
                }
                options={YES_NO_OPTION}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ToggleButtonAtom
                name="firstCycleOfInitiative"
                label={"Is this the first cycle of this initiative?"}
                options={YES_NO_OPTION}
                control={control}
                errors={errors}
              ></ToggleButtonAtom>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Stack flex={1}>
                  <ToggleButtonAtom
                    name={"similarProjectDeliveredBefore"}
                    label={
                      "Has there been similar impact project delivered before?"
                    }
                    options={YES_NO_OPTION}
                    control={control}
                    errors={errors}
                  />
                </Stack>
                {/* {!similarProjectFileUpload && ( */}
                <Stack flex={1}>
                  <Box>
                    <FileInput
                      name={"documents"}
                      errors={errors}
                      {...{ setValue, control, trigger }}
                      allowFileTypeValidation={true}
                      acceptedFileTypes={["application/pdf"]}
                      dropValidation={true}
                    />
                  </Box>
                </Stack>
                {/* )} */}
              </Stack>
              <CheckboxAtom
                name={"isAccurateInfo"}
                label={
                  "I hereby confirm that the information provided is accurate and up to date as per my knowledge."
                }
                control={control}
              />
              <CheckboxAtom
                name={"commitMent"}
                label={
                  "I commit to delivering the promised impact and delivering reports within the expected timeframe."
                }
                control={control}
              />
              {(errors?.isAccurateInfo || errors?.commitMent) && (
                <FormHelperText error>
                  *Please select the checkbox
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};
