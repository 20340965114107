import React from "react";
import { Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { TitleValue } from ".";
import { USER_ROLES } from "utils/constants";

export const MyProfileInfoCard = ({
  title,
  data = {},
  Icon = AccountTreeIcon,
}) => {
  return (
    <>
      <Stack
        sx={{
          ...style.infoCardBackground,
          height: "100%",
        }}
      >
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <Stack sx={style.infoCardContent}>
          <TitleValue
            title={"Full Name"}
            value={
              data.vender?.name
            }
          />
          <TitleValue title={"Email"} value={data.email} />
          <TitleValue
            title={"Role"}
            value={data.role === USER_ROLES.USER ? "Vendor" : "Admin"}
          />
        </Stack>
      </Stack>
    </>
  );
};
