import { useLocation, useRoutes } from "react-router-dom";
import { mainRoutes } from "./main.routes";
import { authRoutes } from "./auth.routes";
import { useSelector, useDispatch } from "react-redux";
import { AppInstance } from "utils/network";
// import useSocket from "utils/hooks/useSocket";
import { TokenManager } from "@forgerock/javascript-sdk";
import { setToken } from "redux/slices/auth.slice";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "global-state";
import { getprofileAsyncThunk } from "redux/asyncThunk";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

function useAuthValidation(auth, setAuth, dispatch) {
  const [isValid, setValid] = useState("invalid");
  useEffect(() => {
    async function validateAccessToken() {
      if (auth) {
        try {
          const token = await TokenManager.getTokens();
          dispatch(getprofileAsyncThunk())
            .unwrap()
            .then((res) => {
              dispatch(setToken(token.accessToken));
              setValid("valid");
            })
            .catch((err) => {
              setValid("invalid");
            });
        } catch (err) {
          console.info(`Info: route validation; ${err}`);
          setAuth(false);
          setValid("invalid");
        }
      } else {
        setValid("invalid");
      }
    }
    validateAccessToken();
    // eslint-disable-next-line
  }, [auth]);
  return [
    {
      isValid,
    },
  ];
}
export const Routes = () => {
  const { token, user } = useSelector((state) => state.auth);
  const [pending, setPending] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();

  // MSAL
  const isAdminAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  // Get "global" state from Context API
  const [{ isAuthenticated }, { setAuthentication }] = useContext(AppContext);
  // Custom hook for validating user's access token
  useAuthValidation(isAuthenticated, setAuthentication, dispatch);
  // useSocket(token, user.id);
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      setPending(false);
    }
  }, [inProgress]);
  const role = user?.role;
  AppInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
  // AppInstance.defaults.headers.common["role"] = role;
  AppInstance.defaults.headers.common["base"] =
    role === "admin" ? "aldar" : "forgerock";
  return useRoutes(
    token
      ? mainRoutes(role, location, isAdminAuthenticated, pending)
      : authRoutes
  );
};
