import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import { ImageIconButton, TextLottie } from "UI/atoms";
import { style } from "./style";
import {
  Badge,
  Box,
  Grid,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationAsyncThunk,
  updateNotificationAsyncThunk,
} from "redux/asyncThunk/notification.asyncThunk";
import { NotificationDesc } from "utils/constants/page.constants";
import { useNavigate } from "react-router-dom";
import NotificationAnimation from "assets/images/vector/notification.json";
import { grey } from "@mui/material/colors";
import { LoadingButton } from "@mui/lab";
import { THUNK_STATUS } from "utils/constants";
import { formatNotificationTime } from "utils/other/helper";

export default function Notification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationEl, setNotificationEl] = useState(null);
  const { notifications, showLoadMore, notificationAPIStatus, unreadNotifications } = useSelector(
    (state) => state.notification
  );
  const [notificationParams, setNotificationParams] = useState({
    pageNo: 1,
    pageSize: 5,
    loadMore: false,
  });

  const handleClick = (event) => {
    setNotificationEl(event.currentTarget);
  };

  const handleClose = () => {
    setNotificationEl(null);
  };

  const handleNotificationClick = (not) => {
    dispatch(updateNotificationAsyncThunk({ id: not?.id }))
      .unwrap()
      .then(() => {
        setNotificationParams((prev) => {
          return {
            ...prev,
            pageNo: 1,
            loadMore: false,
          };
        });
        // if (not.redirect_url === window.location.pathname) {
        //   window.location.reload();
        // }
        navigate(not.redirect_url, {
          state: { data: not.meta?.objectId },
          key: not.meta?.objectId,
        });
        handleClose();
      })
      .catch(() => { });
  };
  const handleLoadMoreNotification = () => {
    setNotificationParams((prev) => {
      return {
        ...prev,
        pageNo: notificationParams.pageNo + 1,
        loadMore: true,
      };
    });
  };
  useEffect(() => {
    dispatch(
      getNotificationAsyncThunk({
        params: notificationParams,
      })
    );
    // eslint-disable-next-line
  }, [notificationParams]);

  const open = Boolean(notificationEl);
  const id = open ? "notification-popover" : undefined;
  return (
    <>
      <ImageIconButton onClick={handleClick} sx={style.menuIcons}>
        <Badge
          badgeContent={unreadNotifications}
          color="warning"
          variant={unreadNotifications ? "standard" : "none"}
        >
          <NotificationsIcon sx={{ color: "white" }} />
        </Badge>
      </ImageIconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={notificationEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ minHeight: "400px", maxHeight: "60vh" }}
      >
        <Stack sx={style.notificationHeader} direction={'row'} justifyContent={'space-between'}>
          <Typography variant="h4">
            Notifications
          </Typography>
          {/* <Link>view all</Link> */}
        </Stack>
        <List
          sx={style.notificationList}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {notifications?.length ? (
            notifications.map((item, ind) => {
              return (
                <ListItemButton
                  key={ind}
                  sx={{
                    py: 2,
                    ...(!item.viewed && { backgroundColor: grey[100] }),
                    "&:hover": {
                      backgroundColor: grey[300],
                    },
                  }}
                  onClick={() => handleNotificationClick(item)}
                >
                  <Grid container>
                    <Grid item xs={1}>
                      <Badge
                        color={item.viewed ? "pending" : "warning"}
                        variant="dot"
                      ></Badge>
                    </Grid>
                    <Grid item xs={11} container spacing={1}>
                      {/* Title */}
                      <Grid item container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {item.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            sx={{ fontSize: "12px", textAlign: "right" }}
                            color="textlight.main"
                          >
                            {formatNotificationTime(item.createdAt)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {item.meta?.desc ||
                            NotificationDesc(item.meta?.status)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItemButton>
              );
            })
          ) : (
            <Box sx={{ px: 4, py: 2 }}>
              <TextLottie
                animationData={NotificationAnimation}
                text={"No Notifications Yet"}
                variant={"h4"}
              />
            </Box>
          )}

          {Boolean(notifications?.length) && showLoadMore && (
            <>
              <LoadingButton
                loading={notificationAPIStatus === THUNK_STATUS.PENDING}
                fullWidth
                onClick={handleLoadMoreNotification}
                loadingPosition="end"
                endIcon={<></>}
              >
                Load More...
              </LoadingButton>
            </>
          )}
        </List>
      </Popover>
    </>
  );
}
