import React, { forwardRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Grid, Stack } from "@mui/material";
import { InputField } from "UI/atoms";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AddVendorButton } from "./AddVendorButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addVendorValidationSchema } from "utils/form/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  createVendorAsyncThunk,
  getAllVendorAsyncThunk,
  getVendorStatesAsyncThunk,
} from "redux/asyncThunk";
import { setFieldError } from "utils/other/helper";
import { LoadingButton } from "@mui/lab";
import { THUNK_STATUS } from "utils/constants";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AddVendorDialog() {
  // -----------------Hooks----------------- //
  const [addVendorDialog, setAddVendorDialog] = useState(false);
  const dispatch = useDispatch();
  const { createVendorApiStatus } = useSelector((state) => state.vendor);
  const vendorPayload = {
    params: { pageNo: 1, pageSize: 5 },
  }
  const { control, handleSubmit, reset, setError } = useForm({
    resolver: yupResolver(addVendorValidationSchema),
  });

  // -----------------Functions----------------- //
  const handleAddVendorDialogOpen = () => {
    setAddVendorDialog(true);
  };
  const handleAddVendorDialogClose = () => {
    setAddVendorDialog(false);
  };
  const handleAddVendorSubmit = () => {
    return handleSubmit((data) => {
      dispatch(createVendorAsyncThunk(data))
        .unwrap()
        .then(() => {
          handleAddVendorDialogClose();
          reset();
          dispatch(getVendorStatesAsyncThunk());
          dispatch(getAllVendorAsyncThunk(vendorPayload));
        })
        .catch((err) => setFieldError(err, setError));
    });
  };

  return (
    <>
      <AddVendorButton {...{ handleAddVendorDialogOpen }} />
      <Dialog
        component={"form"}
        onSubmit={handleAddVendorSubmit()}
        open={addVendorDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAddVendorDialogClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Stack
              sx={{
                background: (theme) => theme.palette.info.main,
                p: 0.5,
                borderRadius: 1,
              }}
            >
              <EditNoteIcon sx={{ color: "white" }} />
            </Stack>
            <Stack direction={"column"}>
              <Typography variant="h3">Add Vendor</Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid
            item
            container
            xs={12}
            direction={"row"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <InputField
                control={control}
                name={"fullname"}
                label={"Full Name"}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                control={control}
                name={"email"}
                label={"Email"}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Stack px={1} pb={1} direction={"row"} justifyContent={"space-between"}>
          <Button
            color="pending"
            startIcon={<ArrowBackIcon />}
            sx={{ px: 1, py: 0 }}
            onClick={handleAddVendorDialogClose}
          >
            Back
          </Button>
          <LoadingButton
            loading={createVendorApiStatus === THUNK_STATUS.PENDING}
            color="info" type="submit" endIcon={<ArrowForwardIcon />}>
            Submit
          </LoadingButton>
        </Stack>
      </Dialog>
    </>
  );
}
