export const GrantFormDefaultValues = {
  focusAreaApplicable: "live",
  commitMent: false,
  isAccurateInfo: false,
};

export const updateProfileFormDefaultValues = ({ userProfile }) => {
  const userAddress = userProfile?.addresses[0];
  const userPrimaryContact = userProfile?.contacts?.filter(
    (item) => item.type === "primary"
  )[0];
  const userSecondaryContact = userProfile?.contacts?.filter(
    (item) => item.type === "secondary"
  )[0];
  return {
    email: userProfile.email,
    fullname: userProfile.vender.name,
    id: userProfile.id,
    address: {
      houseNo: userAddress?.houseNumber,
      addressLine1: userAddress?.addressLine2,
      addressLine2: userAddress?.addressLine2,
      city: userAddress?.city,
      state: userAddress?.state,
      country: userAddress?.country,
      zipCode: userAddress?.zipCode,
    },
    primaryContact: {
      title: userPrimaryContact?.name,
      email: userPrimaryContact?.email,
      mobile: userPrimaryContact?.mobile,
    },
    secondaryContact: {
      title: userSecondaryContact?.name,
      email: userSecondaryContact?.email,
      mobile: userSecondaryContact?.mobile,
    },
  };
};
