import React from "react";
import { Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { InfoCardTable } from ".";

export const BudgetInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  const tableData = {
    head: [
      {
        label: "Item",
        name: "item",
      },
      {
        label: "Description",
        name: "description",
      },
      {
        label: "Estimated Price",
        name: "estimatedPrice",
      },
      {
        label: "Estimated Quantity",
        name: "estimatedQuantity",
      },
      {
        label: "Estimated Cost",
        name: "estimatedCost",
      },
      {
        label: "Covered By Partner",
        name: "coveredByPartner",
      },
      {
        label: "Covered By Aldar",
        name: "coveredByAldar",
      },
    ],
    rowData: data.budgets.map((exp) => {
      return {
        item: exp.item,
        description: exp.description,
        estimatedPrice: exp.estimatedPrice,
        estimatedQuantity: exp.estimatedQuantity,
        estimatedCost: exp.estimatedCost,
        coveredByPartner: exp.coveredByPartner,
        coveredByAldar: exp.coveredByAldar,
      };
    }),
  };
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <InfoCardTable tableData={tableData} />
      </Stack>
    </>
  );
};
