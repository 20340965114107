import { METHODS, SERVICE_URL } from "utils/constants";
import { AppClient } from "utils/network";
import { replaceUrl } from "utils/other/helper";

export const getNotificationService = ({ params }) => {
  return AppClient({
    url: SERVICE_URL.NOTIFICATION,
    method: METHODS.GET,
    params,
  });
};

export const updateNotificationService = (data) => {
  return AppClient({
    url: replaceUrl(SERVICE_URL.UPDATE_NOTIFICATION, data),
    method: METHODS.PUT,
  });
};
