export const style = {
  link: {
    color: "primary.main",
    textDecoration: "underline",
    cursor: "pointer",
  },
  container: {
    minHeight: "100vh",
  },
  logoContainer: {
    flexWrap: "nowrap",
  },
  logoImage: {
    width: 75,
    textAlign: "center",
  },
  loginFormContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: 6.3,
    px: 2,
  },
  videoContainer: {
    display: {
      xs: "none",
      md: "flex",
    },
    position: "relative",
  },
};
