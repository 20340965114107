import { Grid, Typography } from "@mui/material";
import { InputField } from "UI/atoms";
import React, { useContext } from "react";
import { SignupContext } from "utils/contexts/signupContext";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const LegalInfo = () => {
  const { errors, control, handleNameChange } = useContext(SignupContext);
  return (
    <div className="formGroupContainer">
      <Typography
        variant="subtitle2"
        className="formGroupTitle"
        mt={1}
        gutterBottom
      >
        Legal Information
      </Typography>
      <Grid container justifyContent={"center"} spacing={2.5}>
        <Grid item xs={12}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"fullname"}
            onChange={handleNameChange}
            label={"Entity Legal Name"}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"address.houseNo"}
            label={"House Flat No."}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"address.addressLine1"}
            label={"Address Line 1"}
            errors={errors}
            control={control}
            EndIcon={LocationOnIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"address.addressLine2"}
            label={"Address Line 2"}
            errors={errors}
            control={control}
            EndIcon={LocationOnIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"address.city"}
            label={"City"}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"address.state"}
            label={"State"}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"address.country"}
            label={"Country"}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"address.zipCode"}
            label={"Zip Code"}
            errors={errors}
            control={control}
          />
        </Grid>
      </Grid>
    </div>
  );
};
