import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { vendorProjectStatus } from "utils/other/helper";
import { TitleValue } from ".";
import { formatDate } from "utils/other/helper";

export const BasicVendorDetailsInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  const vendorProjectObj = vendorProjectStatus(data.status);
  const [firstName, lastName] = data.vender.name;
  const fullName = lastName !== 'NA' ? data.vender.name : firstName;
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
          <Stack direction={"column"} alignItems={"flex-end"} py={1} px={2}>
            <Box
              sx={{
                ...style.projectCardChip,
                background: vendorProjectObj.color,
              }}
              variant="contained"
              size="small"
            >
              {vendorProjectObj.buttonText}
            </Box>
            <Typography sx={{ color: "black" }}>
              {formatDate(data.createdAt)}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <Stack sx={style.infoCardContent}>
          <TitleValue title={"Vendor Name"} value={fullName} />
          <TitleValue title={"Email"} value={data.email} />
        </Stack>
      </Stack>
    </>
  );
};
