import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { SignupContext } from "utils/contexts/signupContext";
import { SignupTemplate } from "UI/templates";
import { signupValidationSchema } from "utils/form/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  registerAsyncThunk,
  registerByInviteAsyncThunk,
  uploadDocumentsAsyncThunk,
  validateInvitationAsyncThunk,
} from "redux/asyncThunk";
import { handleForgerockRegistration } from "utils/other/forgeRockService";
import { logError, setFieldError } from "utils/other/helper";
import { ROUTES } from "utils/constants";

export const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, inviteId, token } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const isInvited = userId && inviteId && token;
  const { isValidLink, validationApiStatus, registerAPIStatus } = useSelector(
    (state) => state.auth
  );
  const {
    handleSubmit,
    control,
    formState: { isSubmitted, errors, touchedFields },
    setValue,
    trigger,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(signupValidationSchema),
    defaultValues: {},
  });
  const handleSignup = async (data) => {
    setIsBtnLoading(true);
    const formData = new FormData();
    formData.append('file', data.documents[0]);
    await dispatch(uploadDocumentsAsyncThunk(formData)).unwrap().then((res) => {
      data.documents = res.data.documents;
      // data.documents = 'https://sacsrqaun001.blob.core.windows.net/csrqa/PXuArVGgiQBXAb5.pdf'
      if (isInvited) {
        const handleNextOperation = () => dispatch(
          registerByInviteAsyncThunk({
            userId,
            inviteId,
            token,
            data,
          })
        )
          .unwrap()
          .then(() => {
            navigateToSignin();
          })
          .catch((err) => setFieldError(err, setError))
          .finally(() => {
            setIsBtnLoading(false);
          })
        handleForgerockRegistration(data, handleNextOperation, setIsBtnLoading);

      } else {
        dispatch(registerAsyncThunk(data))
          .unwrap()
          .then(() => {
            navigateToSignin();
          })
          .catch((err) => setFieldError(err, setError));
      }
    }).catch((err) => { logError(err) })
      .finally(() => {
        setIsBtnLoading(false);
      })
  };

  const handleEmailChange = (e) => {
    setValue('email', e.target.value)
    if (isSubmitted) {
      trigger('email');
      trigger('password');
    }
  }

  const handlePasswordChange = (e) => {
    setValue('password', e.target.value)
    if (isSubmitted) {
      trigger('confirmPassword')
      trigger('password')
    }
  }

  const handleNameChange = (e) => {
    setValue('fullname', e.target.value)
    if (isSubmitted) {
      trigger('fullname')
      trigger('password')
    }
  }

  const navigateToSignin = () => {
    navigate(ROUTES.BASE);
  };

  useEffect(() => {
    if (isInvited) {
      dispatch(
        validateInvitationAsyncThunk({
          userId,
          inviteId,
          token,
        })
      )
        .unwrap()
        .then((res) => {
          setValue("email", res.data.email);
          setValue("fullname", res.data.name);
        })
        .catch(() => { });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    // eslint-disable-next-line
  }, []);


  const SignupContextValue = {
    handleSubmit,
    control,
    errors,
    handleSignup: handleSubmit(handleSignup),
    navigateToSignin,
    registerAPIStatus,
    isValidLink,
    validationApiStatus,
    isInvited,
    handlePasswordChange,
    handleEmailChange,
    isLoading,
    isBtnLoading,
    setValue,
    dispatch,
    trigger,
    isSubmitted,
    touchedFields,
    getValues,
    handleNameChange
  };

  return (
    <SignupContext.Provider value={SignupContextValue}>
      <SignupTemplate />
    </SignupContext.Provider>
  );
};
