import React, { useContext } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { InputField } from "UI/atoms";
import { ProfileCreateContext } from "utils/contexts";
import { LoadingButton } from "@mui/lab";

export const ChangePasswordForm = () => {
    const { changePasswordControl, handleChangePassword, isChangePasswordLoading } =
        useContext(ProfileCreateContext);
    return (
        <Stack component="form" onSubmit={handleChangePassword} gap={2}>
            <Stack sx={style.infoCardBackground}>
                {/* Header */}
                <Stack sx={style.infoCardHead}>
                    <Stack sx={style.infoCardHeadIcon}>
                        <AccountTreeIcon />
                    </Stack>
                    <Typography variant="h3" sx={{ fontWeight: 500 }}>
                        Basic Details
                    </Typography>
                </Stack>
                {/* Content */}
                <Stack
                    sx={{
                        background: "white",
                        p: 2,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <InputField
                                fullWidth={true}
                                variant="filled"
                                label="Existing Password"
                                name={"existingPassword"}
                                autoComplete={"on"}
                                type={"password"}
                                control={changePasswordControl}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputField
                                fullWidth={true}
                                variant="filled"
                                label="New Password"
                                name={"password"}
                                autoComplete={"on"}
                                type={"password"}
                                control={changePasswordControl}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputField
                                fullWidth={true}
                                variant="filled"
                                label="Confirm Password"
                                name={"confirmPassword"}
                                autoComplete={"on"}
                                type={"password"}
                                control={changePasswordControl}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
            <LoadingButton
                type={"submit"}
                sx={{ borderRadius: "5px" }}
                variant="contained"
                loading={isChangePasswordLoading}
                loadingPosition="end"
                endIcon={<></>}
                fullWidth
            >
                Submit
            </LoadingButton>
        </Stack>
    );
};
