import React, { useContext } from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { TitleValue } from ".";
import { USER_ROLES } from "utils/constants";
import { ProfileDetailsContext } from "utils/contexts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useSelector } from "react-redux";

export const VendorAddressInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  const address = data.addresses[0] || {};
  const { navigateToUpdateProfile } = useContext(ProfileDetailsContext);
  const { user } = useSelector((state) => state.auth);
  const isAdmin = user.role === USER_ROLES.ADMIN;

  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
          {
            !isAdmin &&
            <Stack p={2}>
              <Tooltip title="Update Profile">
                <IconButton onClick={navigateToUpdateProfile}>
                  <BorderColorIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        </Stack>
        {/* Content */}
        <Stack sx={style.infoCardContent}>
          {!isAdmin &&
            <>
              <TitleValue
                title={"Full Name"}
                value={
                  data.vender?.name
                }
              />
              <TitleValue title={"Email"} value={data.email} />
              <TitleValue
                title={"Role"}
                value={data.role === USER_ROLES.USER ? "Vendor" : "Admin"}
              />
            </>
          }
          <TitleValue title={"House Number"} value={address.houseNumber} />
          <TitleValue title={"City"} value={address.city} />
          <TitleValue title={"State"} value={address.city} />
          <TitleValue title={"Country"} value={address.country} />
          <TitleValue title={"Zip Code"} value={address.zipCode} />
          <TitleValue
            title={"Address Line 1"}
            value={address.addressLine1}
            fullWidth
          />
          <TitleValue
            title={"Address Line 2"}
            value={address.addressLine2}
            fullWidth
          />
        </Stack>
      </Stack>
    </>
  );
};
