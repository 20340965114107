import React from "react";
import { FormHelperText } from "@mui/material";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

// Register the plugin
import { Controller } from "react-hook-form";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginImagePreview, FilePondPluginFileValidateType);

export function FileInput(props) {
  const { label, setValue, name, errors, control, trigger, watch, ...rest } =
    props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field, fieldState }) => {
          return (
            <>
              <FilePond
                files={field.value}
                onupdatefiles={(fileItems) => {
                  if (fileItems.length > 0) {
                    setValue(
                      name,
                      fileItems.map((fileItem) => fileItem.file)
                    );
                  } else {
                    setValue(name, null);
                  }
                  trigger(name);
                }}
                allowMultiple={false}
                allowFileSizeValidation={true}
                maxFileSize={"4MB"}
                labelMaxFileSize={"Maximum file size is {filesize}"}
                labelIdle={
                  label ||
                  'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                }
                credits={false}
                {...rest}
              />
              <FormHelperText sx={{ pl: 1.4, pb: 1, mt: -1.4 }} error={fieldState.invalid}>
                {fieldState.error?.message}
              </FormHelperText>
            </>
          );
        }}
      />
    </>
  );
}
