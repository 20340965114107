export const style = {
  invitationCard: {
    background: "rgba(255,255,255,0.2)",
    maxWidth: "600px",
    py: 2,
    px: 6,
  },
  fixedVideo: {
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
  },
  videoGrid: {
    display: { xs: "none", md: "flex" },
    maxHeight: "100vh",
    position: "relative",
  },
  headersStyles: {
    marginTop: 3.6,
    marginBottom: 1.5,
    marginLeft: 2,
    marginRight: 2,
  },
  registerButtonStyles: {
    width: "100%",
  },

  typographyStyles: {
    textAlign: "center",
  },

  formSectionStyles: {
    justifyContent: "center",
  },

  logInLinkStyles: {
    color: "primary.main",
    textDecoration: "underline",
    cursor: "pointer",
  },
};
