import { createSlice } from "@reduxjs/toolkit";
import { getNotificationAsyncThunk } from "redux/asyncThunk/notification.asyncThunk";
import { THUNK_STATUS } from "utils/constants";

const initialState = {
  notifications: [],
  unreadNotifications: null,
  showLoadMore: false,
  notificationAPIStatus: null,
};

export const NotificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationAsyncThunk.pending, (state) => {
        state.notificationAPIStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getNotificationAsyncThunk.fulfilled, (state, action) => {
        state.notificationAPIStatus = THUNK_STATUS.SUCCESS;
        const { notifications, unreadNotifications } = action.payload.data;
        state.unreadNotifications = unreadNotifications;
        if (action.meta.arg?.params?.pageSize > notifications.length) {
          state.showLoadMore = false;
        } else {
          state.showLoadMore = true;
        }
        if (action.meta.arg?.params?.loadMore) {
          state.notifications.push(...notifications);
        } else {
          state.notifications = notifications;
        }
      })
      .addCase(getNotificationAsyncThunk.rejected, (state, action) => {
        state.notificationAPIStatus = THUNK_STATUS.REJECTED;
      });
  },
});

export default NotificationSlice.reducer;
