import { ENCRYPTED_KEY } from "config";
import { toast } from "react-hot-toast";
import { logout } from "redux/slices/auth.slice";
import CryptoJS from "crypto-js";

export function handleError(error, store) {
  // handle encryption decryption
  const { encryptedResponse } = error?.response?.data || {};
  if (encryptedResponse) {
    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedResponse,
      ENCRYPTED_KEY
    );
    const decryptedRequest = JSON.parse(
      decryptedBytes.toString(CryptoJS.enc.Utf8)
    );
    error.response.data = decryptedRequest;
  }
  // handle toaster
  let message;
  if (error.request.status >= 400 && error.request.status <= 499)
    message = error.response?.data?.message || error.response?.data?.error;
  else if (error.request.status === 0) message = "Something Went Wrong";
  else if (error.request.status > 499) message = "Internal Server Error";
  message = message || error.message;
  toast.error(message);
  if (error.request.status === 401) {
    store.dispatch(logout());
  }
  return Promise.reject(error);
}

export function handleResponse(response) {
  // handle encryption decryption
  if (response.config.url.includes("/preview_redirect")) {
    console.log("preview respone");
    return Promise.resolve(response);
  }
  const { encryptedResponse } = response.data;
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedResponse, ENCRYPTED_KEY);
  const decryptedRequest = JSON.parse(
    decryptedBytes.toString(CryptoJS.enc.Utf8)
  );
  response.data = decryptedRequest;
  // handle toaster
  const message = response?.data?.message;
  if (
    message === "Notification updated successfully" ||
    ["/user/login", "/user/upload"].includes(response.config.url) ||
    response.config.url.includes("/change-password-request-status")
  )
    return Promise.resolve(response.data);
  if (response.config.method !== "get" && message) {
    toast.success(message);
    return Promise.resolve(response.data);
  }
  if (response.config.method === "post") toast.success("Created Successfully!");
  if (response.config.method === "delete")
    toast.success("Deleted Successfully!");
  if (response.config.method === "put") toast.success("Updated Successfully!");
  return Promise.resolve(response.data);
}

export function handleRequest(config) {
  if (
    config.url === "/user/upload" ||
    config.url.includes("/preview_redirect")
  ) {
    return config;
  }
  const encryptedRequest = CryptoJS.AES.encrypt(
    JSON.stringify(config.data),
    ENCRYPTED_KEY
  ).toString();
  config.data = { encryptedRequest };
  return config;
}
