import { Chip, lighten } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import _ from "lodash";
import { GRANT_APPROVAL_STATUS, PROJECT_FREQUENCY } from "utils/data";

export function vendorProjectStatus(status, isAdmin = false) {
  let obj = {};
  switch (status) {
    case GRANT_APPROVAL_STATUS.APPROVED:
      obj.color = (t) => t.palette.success.main;
      obj.buttonText = "Approved";
      break;
    case GRANT_APPROVAL_STATUS.UPDATE_REQUIRED:
      obj.color = (t) => t.palette.info.main;
      obj.buttonText = isAdmin ? "Revision Requested" : "Revision Required";
      break;
    case GRANT_APPROVAL_STATUS.REJECTED:
      obj.color = (t) => t.palette.error.main;
      obj.buttonText = "Rejected";
      break;
    case GRANT_APPROVAL_STATUS.IN_REVIEW:
      obj.color = (t) => lighten(t.palette.secondary.main, 0.2);
      obj.buttonText = "In-Review";
      break;
    case GRANT_APPROVAL_STATUS.DRAFT:
      obj.color = (t) => t.palette.pending.main;
      obj.buttonText = "Draft";
      break;
    default:
      obj.color = (t) => t.palette.pending.main;
      obj.buttonText = "Pending";
      break;
  }
  return obj;
}

export const grantRequestStatusChip = (status = "") => {
  switch (status.toLowerCase()) {
    case GRANT_APPROVAL_STATUS.DRAFT:
      return (
        <Chip
          icon={<WatchLaterIcon sx={{ color: "pending.dark" }} />}
          label={"Draft"}
        />
      );
    case "pending":
      return (
        <Chip
          icon={<WatchLaterIcon sx={{ color: "pending.dark" }} />}
          label={"Pending"}
        />
      );
    case GRANT_APPROVAL_STATUS.APPROVED:
    case "registered":
      return (
        <Chip
          sx={{ color: "success.darker", bgcolor: "success.light" }}
          icon={
            <CheckCircleIcon
              sx={{
                color: (theme) => theme.palette.success.darker + "!important",
              }}
            />
          }
          label={"Approved"}
        />
      );
    case GRANT_APPROVAL_STATUS.REJECTED:
    case "not registered":
      return (
        <Chip
          sx={{
            color: "error.dark",
            bgcolor: (theme) => lighten(theme.palette.error.main, 0.8),
          }}
          icon={
            <CancelIcon
              sx={{
                color: (theme) => theme.palette.error.dark + "!important",
              }}
            />
          }
          label={"Rejected"}
        />
      );
    case GRANT_APPROVAL_STATUS.UPDATE_REQUIRED:
      return (
        <Chip
          sx={{
            color: "info.dark",
            bgcolor: (theme) => lighten(theme.palette.info.main, 0.8),
          }}
          icon={
            <EditRoundedIcon
              sx={{
                color: (theme) => theme.palette.info.dark + "!important",
              }}
              fontSize="small"
            />
          }
          label={"Revision Requested"}
        />
      );

    case GRANT_APPROVAL_STATUS.IN_REVIEW:
      return (
        <Chip
          sx={{
            color: "secondary.dark",
            bgcolor: (theme) => lighten(theme.palette.secondary.main, 0.6),
          }}
          icon={
            <HistoryRoundedIcon
              sx={{
                color: (theme) => theme.palette.secondary.dark + "!important",
              }}
              fontSize="small"
            />
          }
          label={"In-Review"}
        />
      );
    default:
      return status;
  }
};

// For Logging the error message (Centralised)
export function logError(error) {
  console.error(`An error occurred: ${error.message}`, error);
}

// for setting error in form fields
export function setFieldError(err, setError) {
  const errorData = err?.response?.data || {};
  if (errorData?.message === "Field Error") {
    const errorFields = errorData.error;
    Object.keys(errorFields).forEach((name) => {
      setError(name, {
        type: errorFields[name].type,
        message: errorFields[name].message,
      }, { shouldFocus: true });
    });
  } else {
    logError(err);
  }
}

// Should submit Report
export function shouldSubmitReport(startDate, endDate, projectFrequency) {
  // Convert string dates to Date objects
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  const currentDate = new Date();

  // Calculate the duration between startDate and currentDate in milliseconds
  const duration = Math.floor(
    (currentDate - startDate) / (24 * 60 * 60 * 1000)
  );

  // Check if currentDate is within the start and end date range
  if (startDate <= currentDate && currentDate <= endDate) {
    // Check based on project frequency 
    if (projectFrequency === PROJECT_FREQUENCY.ONE_OFF && currentDate === endDate) {
      return true;
    } else if (projectFrequency === PROJECT_FREQUENCY.WEEKLY && duration % 7 === 0) {
      return true;
    } else if (
      projectFrequency === PROJECT_FREQUENCY.MONTHLY &&
      currentDate.getDate() === startDate.getDate()
    ) {
      return true;
    } else if (projectFrequency === PROJECT_FREQUENCY.QUATERLY && duration % 91 === 0) {
      return true;
    } else if (projectFrequency === PROJECT_FREQUENCY.SEMI_ANNUAL && duration % 182 === 0) {
      return true;
    } else if (
      projectFrequency === PROJECT_FREQUENCY.ANNUAL &&
      currentDate.getDate() === startDate.getDate() &&
      currentDate.getMonth() === startDate.getMonth()
    ) {
      return true;
    }
  }

  return false;
}


// Get end date of Project TimeFrame
export function calculateFinalDate(interval, startDate) {
  if (!interval) return;
  let [duration, unit] = interval.split("_");
  const endDate = new Date(startDate);
  if (duration === "6-12") {
    duration = 12;
  } else if (duration === "1-3") {
    duration = 3;
  } else if (duration === "+4") {
    duration = 4;
  }

  if (unit === "weeks") {
    endDate.setDate(startDate.getDate() + parseInt(duration) * 7);
  } else if (unit === "months") {
    endDate.setMonth(startDate.getMonth() + parseInt(duration));
  } else if (unit === "years") {
    endDate.setFullYear(startDate.getFullYear() + parseInt(duration));
  } else {
    throw new Error("Invalid interval format");
  }

  return endDate;
}

export function formatNotificationTime(inputTime) {
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.format(new Date(inputTime));
}

export const getTodayDate = () => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentDate = new Date();
  const dayOfWeek = daysOfWeek[currentDate.getDay()];
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  const formattedDate = `Today is ${dayOfWeek} - ${day} ${month} ${year}`;
  return formattedDate;
};

export function replaceUrl(urlTemplate, replacements) {
  let modifiedUrl = urlTemplate;

  for (const key in replacements) {
    if (replacements.hasOwnProperty(key)) {
      const placeholder = `:${key}`;
      const value = replacements[key];
      modifiedUrl = modifiedUrl.replace(placeholder, value);
    }
  }

  return modifiedUrl;
}

export function formatDate(inputDate) {
  const dateObject = new Date(inputDate);

  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = dateObject.toLocaleDateString("en-US", options);

  return formattedDate;
}

export function transformMilestoneResponse(data) {
  const transformedData = {};

  // Iterate through the array of objects
  data.forEach((report) => {
    report.reportValues.forEach((reportValue) => {
      const { expectedImpact, otherTarget } = reportValue;
      const { grantQuestion, total } = expectedImpact || otherTarget;

      if (grantQuestion) {
        const milestone = grantQuestion.question;
        const reportKey = `report${report.reportNumber}`;

        if (!transformedData[milestone]) {
          // Initialize the milestone if not present
          transformedData[milestone] = {
            milestone,
            [reportKey]: reportValue.achieved,
            goal: total,
          };
        } else {
          // Update existing milestone data
          transformedData[milestone][reportKey] = reportValue.achieved;
        }
      }
    });
  });

  // Convert the object values to an array
  return Object.values(transformedData).map((milestoneData) => {
    var totalAchieved = 0;
    for (var key in milestoneData) {
      if (key.startsWith("report")) {
        totalAchieved += milestoneData[key];
      }
    }
    const percentage = (totalAchieved / milestoneData.goal) * 100;
    return {
      ...milestoneData,
      totalAchieved,
      completion:
        percentage >= 100
          ? percentage === Infinity
            ? "N/A"
            : "100%"
          : _.isNaN(percentage)
            ? "N/A"
            : ~~percentage + "%",
    };
  });
}

export function filterEmptyKeys(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) =>
        value !== "" &&
        value !== null &&
        value !== undefined &&
        !(typeof value === "number" && isNaN(value))
    )
  );
}


// convert string to pascal case such as "hello world to Hello World"
export function toPascalCase(str) {
  // Split the string into words
  let words = str.split(/\s+/);

  // Capitalize the first letter of each word
  let pascalCaseWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the capitalized words together with spaces
  let pascalCaseStr = pascalCaseWords.join(' ');

  return pascalCaseStr;
}


// remove special characters from string
export function removeSpecialCharacters(str) {
  // Define a regular expression to match special characters
  const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g; // eslint-disable-line

  // Replace special characters with an empty string
  return str.replace(regex, '');
}