// material-ui
import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
  return createTheme({
    palette: {
      mode,
      common: {
        black: "#000",
        white: "#fff",
      },
      primary: {
        main: "#e11b22",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#002A4E",
      },
      info: {
        main: "#61B1E8",
        contrastText: "black",
      },
      success: {
        main: "#CBDA00",
        darker: "#4C5A31",
      },
      error: {
        main: "#E45400",
      },
      pending: {
        light: grey[200],
        main: grey[400],
        dark: grey[600],
      },
      violet: {
        light: "#be76e6",
        main: "#7a389e",
        dark: "#4c1967",
      },
      textlight: {
        main: "#818181",
        subtitle: "#D4D0CA",
      },
      background: {
        default: mode === "light" ? "#f3f1f0" : "#101011",
      },
    },
  });
};

export default Palette;
