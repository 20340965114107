import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { Paper, Typography } from "@mui/material";

export function LineChartAtom(props) {
  const { xAxis, series, title } = props;
  return (
    <Paper
      sx={{
        p: 2,
        // position: "relative",
      }}
    >
      <Typography variant="h5" textAlign={"center"}>
        {title}
      </Typography>
      <LineChart
        xAxis={xAxis}
        series={series}
        sx={{ width: "100%" }}
        height={400}
        {...props}
      />
    </Paper>
  );
}
