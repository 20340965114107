import { FRAuth } from "@forgerock/javascript-sdk";
import { yupResolver } from "@hookform/resolvers/yup";
import { USER_FORGET_PASSWORD_JOURNEY } from "config";
import React, { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordTemplate } from "UI/templates";
import { ROUTES } from "utils/constants";
import { ForgotPasswordContext } from "utils/contexts";
import {
  forgotPasswordValidationSchema,
  otpValidationSchema,
  passwordValidationSchema,
} from "utils/form/validationSchema";
import { setForgerockConfig } from "utils/other/forgeRockService";

export const ForgotPassword = () => {
  // -----------------Hooks----------------- //
  const navigate = useNavigate();
  const [timer, setTimer] = useState(120);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [formStep, setFormStep] = useState({
    step: 0,
    prevFRRes: {}
  });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues
  } = useForm({
    resolver: yupResolver(
      (() => {
        switch (formStep.step) {
          case 0:
            return forgotPasswordValidationSchema;
          case 1:
            return otpValidationSchema;
          case 2:
            return passwordValidationSchema;
          default:
            return;
        }
      })()
    ),
  });

  // -----------------Constants----------------- //
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  // -----------------Functions----------------- //
  const handleForgotPassword = async (data) => {
    try {
      setLoadingBtn(true);
      switch (formStep.step) {
        case 0:
          await handleStep0(data);
          break;
        case 1:
          await handleStep1(data, formStep.prevFRRes);
          break;
        case 2:
          await handleStep2(data, formStep.prevFRRes);
          break;
        default:
          break;
      }
    } catch (err) {
      toast.error(err?.message || "Something went wrong!");
    } finally {
      setLoadingBtn(false);
    }
  };

  // Email Step
  const handleStep0 = async (data, resendOTP = false) => {
    setForgerockConfig(USER_FORGET_PASSWORD_JOURNEY);
    let frLoginRes0 = await FRAuth.start();
    if (!executeRecaptcha) return;
    const reCaptchaToken = await executeRecaptcha();
    let frLoginReq1 = { ...frLoginRes0 };
    frLoginReq1.payload.callbacks[0].input[0].value = data.email;
    frLoginReq1.payload.callbacks[1].input[0].value = reCaptchaToken;
    let frLoginRes1 = await FRAuth.next(frLoginReq1);
    if (resendOTP) {
      setFormStep((prev) => {
        return {
          ...prev,
          prevFRRes: frLoginRes1,
        }
      });
    } else {
      handleNextStep(frLoginRes1, "email");
    }
  };

  // OTP Step
  const handleStep1 = async (data, prevFRRes) => {
    let frLoginReq2 = { ...prevFRRes };
    if (frLoginReq2?.payload?.callbacks?.[1]?.type == "ConfirmationCallback") {
      frLoginReq2.payload.callbacks[1].input[0].value = 1;
      let frInvalidOtpRes = await FRAuth.next(frLoginReq2);
      frInvalidOtpRes.payload.callbacks[0].input[0].value = data.otp;
      frLoginReq2 = frInvalidOtpRes;
    } else {
      frLoginReq2.payload.callbacks[0].input[0].value = data.otp;
    }
    let frLoginRes2 = await FRAuth.next(frLoginReq2);
    handleNextStep(frLoginRes2, "otp");
  };

  // Change Password Step
  const handleStep2 = async (data, prevFRRes) => {
    let frLoginReq3 = { ...prevFRRes };
    frLoginReq3.payload.callbacks[0].input[0].value = data.password;
    const frLoginRes3 = await FRAuth.next(frLoginReq3);
    if (frLoginRes3?.type === "LoginSuccess") {
      toast.success("Password updated successfully!");
      // dispatch(changePasswordRequestCompletedAsyncThunk({ id: user.id }));
      navigateToLogin();
    } else {
      let errMessage = frLoginRes3?.payload?.message || "Something went wrong!";
      switch (errMessage) {
        case 'Failed policy validation':
          errMessage = 'Current password must be different from the 3 previous password. Try again with the different password!';
          break;
        case 'User Locked Out.':
          errMessage = 'Your account has been temporarily locked. Please try again later.';
          break;
        default:
          break;
      }
      toast.error(errMessage);
    }
  };

  const handleNextStep = (frLoginRes, field) => {
    if (frLoginRes?.payload?.message) {
      toast.error(frLoginRes.payload.message);
    } else if (frLoginRes?.payload?.callbacks?.[0]?.output?.[0]?.name === "message") {
      if (frLoginRes.payload.callbacks[0].output[0].value == "OTP is invalid") {
        setFormStep((prev) => {
          return { ...prev, prevFRRes: frLoginRes }
        });
      }
      setError(field, {
        type: "manual",
        message: frLoginRes.payload.callbacks[0].output[0].value,
      });
    } else {
      setFormStep((prev) => {
        return {
          step: prev.step + 1,
          prevFRRes: frLoginRes,
        }
      });
    }
  };

  function navigateToSignup() {
    navigate(ROUTES.REGISTER);
  }
  function navigateToLogin() {
    navigate(ROUTES.BASE);
  }
  function moveBack() {
    setFormStep((prev) => {
      return {
        step: prev.step - 1,
        prevFRRes: {}
      }
    });
  }
  const handleResendOTP = async () => {
    setIsTimerRunning(true);
    handleStep0({ email: getValues('email') }, true);
  }

  // -----------------Use Effect----------------- //
  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning]);

  useEffect(() => {
    if (timer === 0) {
      setTimer(120);
      setIsTimerRunning(false);
    }
  }, [timer]);

  // -----------------Contexts----------------- //
  const ForgotPasswordContextValue = {
    handleSubmit,
    control,
    errors,
    handleForgotPassword: handleSubmit(handleForgotPassword),
    navigateToSignup,
    formStep,
    loadingBtn,
    handleResendOTP,
    minutes,
    seconds,
    isTimerRunning,
    navigateToLogin,
    moveBack
  };
  return (
    <ForgotPasswordContext.Provider value={ForgotPasswordContextValue}>
      <ForgotPasswordTemplate />
    </ForgotPasswordContext.Provider>
  );
};
