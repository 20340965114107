import React from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const GoBack = ({ sx }) => {
    const navigate = useNavigate();
    return (
        <IconButton onClick={() => { navigate(-1) }} sx={{ color: 'white', ...sx }}>
            <ArrowBackRoundedIcon />
        </IconButton>
    )
}
