import React, { useEffect } from "react";
import { GrantDetailsTemplate } from "UI/templates/admin/grants/grantDetails";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { ROUTES, USER_ROLES } from "utils/constants";
import { GrantDetailsContext } from "utils/contexts";
import {
  changeFeedbackStatusAsyncThunk,
  changeGrantStatusAsyncThunk,
  changeUpdateStatusAsyncThunk,
  createGrantAsyncThunk,
  createGrantFeedbackAsyncThunk,
  getReportsAsyncThunk,
  getSingleGrantAsyncThunk,
} from "redux/asyncThunk";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedBackValidationSchema } from "utils/form/validationSchema";
import { useLocation, useNavigate } from "react-router-dom";
import { calculateFinalDate, logError, setFieldError, shouldSubmitReport } from "utils/other/helper";


export const GrantDetails = () => {
  // ----------------- Initial Constant ----------------- //
  const location = useLocation();
  const singleGrantID = location?.state?.data;

  // ----------------- Hooks ----------------- //
  const [grantRequestDialog, setGrantRequestDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadGrant, setLoadGrant] = useState(true);
  const { user, token } = useSelector((state) => state.auth);
  const {
    grants: {
      createGrantAPIStatus,
      singleGrantData,
      singleGrantStatus: grantRequestStatus,
      changeGrantStatusApiStatus
    },
    reports,
    createGrantFeedbackApiStatus,
  } = useSelector((state) => state.grant);
  const isAdmin = user.role === USER_ROLES.ADMIN;
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(feedBackValidationSchema()),
    defaultValues: {
      feedbacks: [],
      grantId: singleGrantID,
    },
  });
  const feedBackField = useFieldArray({
    control,
    name: "feedbacks",
  });
  const isAllFeedBackMarked = Boolean(
    singleGrantData?.feedbacks?.filter((f) => !f.status)?.length
  );
  const startDate = new Date(singleGrantData?.approvedAt);
  const endDate = calculateFinalDate(
    singleGrantData?.projectTimeFrame,
    startDate
  );
  const submitReport = shouldSubmitReport(
    startDate,
    endDate,
    singleGrantData?.frequency
  );
  const encryptedToken = token;
  const role = user?.role;
  //  CryptoJS.AES.encrypt(
  //   JSON.stringify(token),
  //   ENCRYPTED_KEY
  // ).toString();

  // -----------------Functions----------------- //
  const handleRequestDialogOpen = () => {
    feedBackField.remove();
    setGrantRequestDialog(true);
  };

  const handleRequestDialogClose = () => {
    setGrantRequestDialog(false);
  };

  const handleAddFeedbackInput = () => {
    feedBackField.append({ feedback: "" });
  };

  const handleRemoveFeedbackInput = (index) => {
    feedBackField.remove(index);
  };

  const handleFeedBackSubmit = (data) => {
    dispatch(createGrantFeedbackAsyncThunk(data))
      .unwrap()
      .then(() => {
        handleRequestDialogClose();
        return dispatch(getSingleGrantAsyncThunk({ id: singleGrantID }));
      })
      .catch((err) => setFieldError(err, setError));
  };

  const handleGrantStatusChange = (status, type = "") => {
    dispatch(
      type === "requestForEdit"
        ? changeUpdateStatusAsyncThunk({
          id: singleGrantID,
          action: status,
        })
        : changeGrantStatusAsyncThunk({ id: singleGrantID, status })
    )
      .unwrap()
      .then(() => {
        if (type === "requestForEdit") {
          navigate(ROUTES.GRANTS);
        } else {
          dispatch(getSingleGrantAsyncThunk({ id: singleGrantID }))
        }
      }
      )
      .catch((err) => logError(err));
  };

  const navigateToUpdateGrant = (isRequestForUpdate) => {
    if (isRequestForUpdate) {
      dispatch(createGrantAsyncThunk({ id: singleGrantData.id, requestForEdit: true }))
        .unwrap()
        .then(() => {
          return dispatch(getSingleGrantAsyncThunk({ id: singleGrantID }))
        })
        .catch((err) => logError(err));
    } else {
      navigate(ROUTES.GRANT_UPDATE, {
        state: { data: singleGrantData },
      });
    }
  };

  const changeFeedBackStatus = (id, status) => {
    setLoadGrant(false);
    dispatch(changeFeedbackStatusAsyncThunk({ id, status }))
      .unwrap()
      .then(() => dispatch(getSingleGrantAsyncThunk({ id: singleGrantID })))
      .finally(() => setLoadGrant(true))
      .catch((err) => logError(err));
  };

  // -----------------Use Effects----------------- //
  useEffect(() => {
    dispatch(getReportsAsyncThunk({ id: singleGrantID }));
    dispatch(getSingleGrantAsyncThunk({ id: singleGrantID }));
    // eslint-disable-next-line 
  }, [singleGrantID]);

  const grantDetailsContextValue = {
    grantRequestStatus,
    handleRequestDialogOpen,
    handleRequestDialogClose,
    grantRequestDialog,
    isAdmin,
    control,
    errors,
    singleGrantData,
    singleGrantID,
    handleRemoveFeedbackInput,
    handleAddFeedbackInput,
    handleFeedBackSubmit,
    handleSubmit,
    feedBackField,
    handleGrantStatusChange,
    navigateToUpdateGrant,
    reports,
    createGrantAPIStatus,
    changeFeedBackStatus,
    loadGrant,
    submitReport,
    isAllFeedBackMarked,
    changeGrantStatusApiStatus,
    createGrantFeedbackApiStatus,
    encryptedToken,
    role
  };
  return (
    <GrantDetailsContext.Provider value={grantDetailsContextValue}>
      <GrantDetailsTemplate />
    </GrantDetailsContext.Provider>
  );
};
