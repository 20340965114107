import React from "react";
import {
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { DateInput, InputField, TimeInput } from "UI/atoms";
import { useContext } from "react";
import { GrantCreateContext } from "utils/contexts";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

export const EventInfoForm = ({ title, Icon = AccountTreeIcon }) => {
  const { control, errors, eventsField, addEventRow, deleteEventRow } =
    useContext(GrantCreateContext);
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{
            ...style.infoCardHead,
            justifyContent: "space-between",
          }}
        >
          <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 1.5 }}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
          <IconButton onClick={addEventRow} color="primary">
            <AddIcon />
          </IconButton>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            background: "white",
            p: 2,
          }}
        >
          <>
            {eventsField.fields.length ? (
              eventsField.fields.map((row, index) => {
                const fieldNamePrefix = `events[${index}]`;
                const errorMessage = errors?.events?.[index]?.root?.message;
                return (
                  <Grid key={row.id} container spacing={2}>
                    <Grid item xs={12} my={2}>
                      <Stack direction="row" alignItems={"center"}>
                        <Divider variant="fullWidth" sx={{ flex: 1 }}>
                          <Stack sx={style.formCount}>
                            <Typography variant="h4">{index + 1}</Typography>
                          </Stack>
                        </Divider>
                        <IconButton
                          onClick={() => deleteEventRow(index)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    </Grid>
                    {errorMessage && (
                      <Grid item>
                        <FormHelperText error={true}>
                          *{errorMessage}
                        </FormHelperText>
                      </Grid>
                    )}
                    <Grid item container spacing={2} alignItems={"center"}>
                      <Grid item xs={12} sm={6} lg={2.4}>
                        <DateInput
                          label="Date"
                          name={`${fieldNamePrefix}.date`}
                          errors={errors}
                          control={control}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} lg={2.4}>
                        <TimeInput
                          label="Time"
                          name={`${fieldNamePrefix}.time`}
                          errors={errors}
                          control={control}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2.4}>
                        <InputField
                          name={`${fieldNamePrefix}.location`}
                          label={"Location"}
                          errors={errors}
                          control={control}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2.4}>
                        <InputField
                          name={`${fieldNamePrefix}.targetedAudience`}
                          label={"Target Audience"}
                          errors={errors}
                          control={control}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={2.3}>
                        <InputField
                          name={`${fieldNamePrefix}.objective`}
                          label={"Objective"}
                          errors={errors}
                          control={control}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Stack
                sx={{
                  pl: 2,
                  pt: 2,
                }}
              >
                <Typography>
                  Click + button to add events information.
                </Typography>
              </Stack>
            )}
          </>
        </Stack>
      </Stack>
    </>
  );
};
