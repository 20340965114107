import React, { useContext } from "react";
import { Container, Grid, Stack, Box, Typography } from "@mui/material";
import { style } from "./style";
import { ChangePasswordForm, PageHeader } from "UI/molecules";
import { ProfileCompleteForm } from "UI/molecules/ProfileCompleteForm";
import { ProfileCreateContext } from "utils/contexts";
import { GoBack } from "UI/molecules/GoBack";

export const ProfileCreateTemplate = () => {
  const { isChangePasswordPage } = useContext(ProfileCreateContext);
  return (
    <>
      <Stack sx={style.background} pt={8} pb={4}>
        <Container maxWidth="lg">
          <Grid item container justifyContent={"space-between"}>
            <PageHeader
              title={
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <GoBack />
                  <Typography variant="h2" sx={style.whiteText}>
                    {isChangePasswordPage ? "Change Password" : "Update Profile"}
                  </Typography>
                </Stack>
              }
              desc={""}
            />
          </Grid>
        </Container>
      </Stack>
      <Box py={2}>
        <Container maxWidth="lg">
          {
            isChangePasswordPage ? <ChangePasswordForm /> : <ProfileCompleteForm />
          }
        </Container>
      </Box>
    </>
  );
};
