import { createSlice } from "@reduxjs/toolkit";
import {
  changeGrantStatusAsyncThunk,
  createGrantAsyncThunk,
  createGrantFeedbackAsyncThunk,
  getAllGrantAsyncThunk,
  getGrantStatesAsyncThunk,
  getQuestionsAsyncThunk,
  getReportsAsyncThunk,
  getRequestForUpdateGrantAsyncThunk,
  getSingleGrantAsyncThunk,
  submitReportAsyncThunk,
} from "redux/asyncThunk";
import { THUNK_STATUS } from "utils/constants";

const initialState = {
  questions: {
    problem_solving: ["", "", ""],
    expected_impact: ["", "", ""],
    other_impact: [],
  },
  questionStatus: null,
  reports: [],
  createGrantFeedbackApiStatus: null,
  grants: {
    data: [],
    requestForUpdateData: {
      status: null,
    },
    allGrantAPIStatus: null,
    singleGrantID: null,
    singleGrantData: [],
    singleGrantStatus: null,
    count: null,
    currentPage: null,
    currentPageSize: null,
    grantStates: {},
    createGrantAPIStatus: null,
    submitReportAPIStatus: null,
    changeGrantStatusApiStatus: null,
  },
};

export const GrantSlice = createSlice({
  name: "grant",
  initialState,
  reducers: {
    setGrantID: (state, action) => {
      state.grants.singleGrantID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsAsyncThunk.pending, (state) => {
        state.questionStatus = THUNK_STATUS.PENDING;
        state.questions = initialState.questions;
      })
      .addCase(getQuestionsAsyncThunk.fulfilled, (state, action) => {
        state.questionStatus = THUNK_STATUS.SUCCESS;
        state.questions = action.payload.data;
      })
      .addCase(getQuestionsAsyncThunk.rejected, (state, action) => {
        state.questionStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(getAllGrantAsyncThunk.pending, (state) => {
        state.grants.allGrantAPIStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getAllGrantAsyncThunk.fulfilled, (state, action) => {
        state.grants.allGrantAPIStatus = THUNK_STATUS.SUCCESS;
        state.grants.data = action.payload.data.grants || [];
        state.grants.count = action.payload.data.count;
        state.grants.currentPageSize = action.payload.data.currentPageSize;
        state.grants.currentPage = action.payload.data.currentPage;
      })
      .addCase(getAllGrantAsyncThunk.rejected, (state) => {
        state.grants.allGrantAPIStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(getRequestForUpdateGrantAsyncThunk.pending, (state) => {
        state.grants.requestForUpdateData.status = THUNK_STATUS.PENDING;
      })
      .addCase(
        getRequestForUpdateGrantAsyncThunk.fulfilled,
        (state, action) => {
          state.grants.requestForUpdateData = action.payload.data || {};
          state.grants.requestForUpdateData.status = THUNK_STATUS.SUCCESS;
        }
      )
      .addCase(getRequestForUpdateGrantAsyncThunk.rejected, (state) => {
        state.grants.requestForUpdateData.status = THUNK_STATUS.REJECTED;
      })
      .addCase(getSingleGrantAsyncThunk.pending, (state) => {
        state.grants.singleGrantStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getSingleGrantAsyncThunk.fulfilled, (state, action) => {
        state.grants.singleGrantData = action.payload.data;
        state.grants.singleGrantStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(getSingleGrantAsyncThunk.rejected, (state) => {
        state.grants.singleGrantData = null;
        state.grants.singleGrantStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(getGrantStatesAsyncThunk.fulfilled, (state, action) => {
        state.grants.grantStates = action.payload.data;
      })
      .addCase(getReportsAsyncThunk.fulfilled, (state, action) => {
        state.reports = action.payload.data;
      })
      .addCase(createGrantAsyncThunk.pending, (state) => {
        state.grants.createGrantAPIStatus = THUNK_STATUS.PENDING;
      })
      .addCase(createGrantAsyncThunk.fulfilled, (state, action) => {
        state.grants.createGrantAPIStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(createGrantAsyncThunk.rejected, (state) => {
        state.grants.createGrantAPIStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(submitReportAsyncThunk.pending, (state) => {
        state.grants.submitReportAPIStatus = THUNK_STATUS.PENDING;
      })
      .addCase(submitReportAsyncThunk.fulfilled, (state, action) => {
        state.grants.submitReportAPIStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(submitReportAsyncThunk.rejected, (state) => {
        state.grants.submitReportAPIStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(changeGrantStatusAsyncThunk.pending, (state) => {
        state.grants.changeGrantStatusApiStatus = THUNK_STATUS.PENDING;
      })
      .addCase(changeGrantStatusAsyncThunk.fulfilled, (state, action) => {
        state.grants.changeGrantStatusApiStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(changeGrantStatusAsyncThunk.rejected, (state) => {
        state.grants.changeGrantStatusApiStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(createGrantFeedbackAsyncThunk.pending, (state) => {
        state.createGrantFeedbackApiStatus = THUNK_STATUS.PENDING;
      })
      .addCase(createGrantFeedbackAsyncThunk.fulfilled, (state, action) => {
        state.createGrantFeedbackApiStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(createGrantFeedbackAsyncThunk.rejected, (state) => {
        state.createGrantFeedbackApiStatus = THUNK_STATUS.REJECTED;
      });
  },
});

export default GrantSlice.reducer;
export const { setGrantID } = GrantSlice.actions;
