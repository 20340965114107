export const ASYNC_ROUTES = {
  // AUTH
  LOGIN: "auth/login",
  REGISTER: "auth/register",
  GET_PROFILE: "get/profile",
  VALIDATE_INVITAIION: "validate-invitation",
  REGISTER_BY_INVITE: "register/invite",
  GET_CSRF_TOKEN: "get/csrf-token",

  // GRANTS
  CREATE_GRANT: "grants/create",
  GET_QUESTIONS: "get/questions",
  GET_ALL_GRANTS: "get/all_grants",
  GET_UPDATE_GRANTS: "get/update_grants",
  GET_SINGLE_GRANT: "get/single_grant",
  GET_GRANT_STATES: "get/grant_states",
  CREATE_GRANT_FEEDBACK: "create/grant_feedback",
  CHANGE_FEEDBACK_STATUS: "update/feedback_status",
  GRANT_STATUS: "change/grant/status",
  SUBMIT_REPORTS: "submit/report",
  GET_REPORTS: "get/report",
  UPDATE_STATUS: "update/status",

  // VENDORS
  GET_ALL_VENDORS: "get/all_vendors",
  VENDOR_STATUS: "change/vendor/status",
  CHANGE_PASSWORD_REQUEST: "request/change_password",
  CHANGE_PASSWORD_REQUEST_COMPLETED: "request/change_password/completed",
  GET_VENDOR_STATES: "get/vendor_states",
  GET_SINGLE_VENDOR: "get/single_vendor",
  CREATE_VENDOR: "create/vendor",
  GET_VENDOR_LIST: "get/vendor_list",
  UPLOAD_DOCUMENTS: "upload/documents",

  // Notifications
  GET_NOTIFICATIONS: "get/notifications",
  UPDATE_NOTIFICATIONS: "update/notifications",

  // Analytics
  GET_GRANTS_CATEGORY_ANALYTICS: "get/grant-category-analytics",
  GET_GRANTS_STATUS_ANALYTICS: "get/grant-status-analytics",
  GET_PROJECT_TIMEFRAME_ANALYTICS: "get/grant-project-timeframe-analytics",
  GET_GRANT_SUBMISSION_ANALYTICS: "get/grant-submission-analytics",
  GET_GRANT_APPROVAL_ANALYTICS: "get/grant-approval-analytics",
  GET_BUDGET_ANALYTICS: "get/budget-analytics",
  GET_VENDOR_APPROVAL_ANALYTICS: "get/vendor-approval-analytics",
  GET_VENDOR_STATUS_ANALYTICS: "get/vendor-status-analytics",
  GET_EVENT_ANALYTICS: "get/event-analytics",
};

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const THUNK_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  REJECTED: "reject",
};

export const SERVICE_URL = {
  // AUTH
  LOGIN: "/user/login",
  REGISTER: "/user/create-user",
  GET_PROFILE: "/user",
  VALIDATE_INVITAIION: "/user/:inviteId/:userId/:token",
  GET_CSRF_TOKEN: "/csrf-token",

  // GRANTS:
  GRANT: "/grant",
  SINGLE_GRANT: "/grant/:id",
  GRANT_STATES: "/grant/states",
  GET_GRANT_QUESTIONS: "/grantquestions/:focusAreaApplicable",
  GRANT_STATUS: "/grant/:id/status/:status",
  GRANT_UPDATE_STATUS: "/grant/:id/request-edit-status/:action",

  // FEEDBACKS:
  FEEDBACK: "/feedback",
  FEEDBACK_STATUS: "/feedback/:id/status/:status",

  // VENDOR:
  VENDOR: "/vendor",
  VENDOR_STATUS: "/vendor/:id/status/:status",
  VENDOR_STATES: "/vendor/states",
  SINGLE_VENDOR: "/vendor/:id",
  GET_VENDOR_LIST: "/user/search",
  UPLOAD_DOCUMENTS: "/user/upload",
  CHANGE_PASSWORD_REQUEST: "/vendor/:id/request-change-password",
  CHANGE_PASSWORD_REQUEST_COMPLETED:
    "/vendor/:id/change-password-request-status",

  // REPORTS
  REPORTS: "/reports",
  GET_REPORTS: "/reports/:id",

  // NOTIFICATIONS
  NOTIFICATION: "/notifications",
  UPDATE_NOTIFICATION: "/notifications/:id",

  // ANALYTICS
  GET_GRANTS_CATEGORY_ANALYTICS: "/analytics/grant-categories-distribution",
  GET_GRANTS_STATUS_ANALYTICS: "/analytics/grant-statuses-distribution",
  GET_PROJECT_TIMEFRAME_ANALYTICS: "/analytics/grant-project-time-frames",
  GET_GRANT_SUBMISSION_ANALYTICS: "/analytics/grant-submitted",
  GET_GRANT_APPROVAL_ANALYTICS: "/analytics/grant-approved",
  GET_BUDGET_ANALYTICS: "/analytics/grant-budget-vs-project-vs-time",
  GET_GRANT_EVENTS_ANALYTICS: "/analytics/grant-events-per-month",
  GET_VENDOR_STATUS_ANALYTICS: "/analytics/vender-statuses-distribution",
  GET_VENDOR_APPROVAL_ANALYTICS: "/analytics/vender-time-series",
};
