export const style = {
  videoGrid: {
    display: { xs: "none", md: "flex" },
    maxHeight: "100vh",
    position: "relative",
  },
  AuthLayoutLeftGrid: {
    py: 2,
    height: "100vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
};
