import { Box, IconButton, Stack } from "@mui/material";
import { formatDate } from "utils/other/helper";
import { grantRequestStatusChip } from "utils/other/helper";
import { MenuAtom } from "UI/atoms/MenuAtom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import { GRANT_APPROVAL_STATUS } from "..";

export const grantRequestColumn = ({
  handleGrantDetailsClick,
  requestForUpdateTable = false,
  handleUpdateRequest = () => {},
}) => {
  return [
    {
      name: "projectName",
      label: "Project Name",
      options: {
        filter: false,
        filterType: "textField",
      },
    },
    {
      name: "focusAreaApplicable",
      label: "Type",
      options: {
        filter: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Live", "Belong", "Sustain"],
        },
        customBodyRender: (value) => (
          <Box sx={{ textTransform: "capitalize" }}>{value}</Box>
        ),
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: false,
        customBodyRender: (value) => <>{formatDate(value)}</>,
      },
    },
    {
      name: "user",
      label: "Vendor",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value.vender.name;
        },
      },
    },
    {
      name: "approval",
      label: "Approval",
      options: {
        filter: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Approved", "In-Review", "Rejected", "Revision Requested"],
        },
        customBodyRender: (value) => <>{grantRequestStatusChip(value)}</>,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Box sx={{ color: "#818181" }}>
            <MenuAtom
              menuOptions={[
                {
                  label: (
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <VisibilityOutlinedIcon />
                      View
                    </Stack>
                  ),
                  onClick: () => handleGrantDetailsClick(value),
                },
                ...(requestForUpdateTable
                  ? [
                      {
                        label: (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <DoneIcon color="success" />
                            Approve
                          </Stack>
                        ),
                        onClick: () => handleUpdateRequest(value, true),
                      },
                      {
                        label: (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <BlockIcon color="error" />
                            Reject
                          </Stack>
                        ),
                        onClick: () => handleUpdateRequest(value, false),
                      },
                    ]
                  : []),
              ]}
              stopPropagation={true}
            >
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </MenuAtom>
          </Box>
        ),
      },
    },
    {
      name: "id",
      label: "",
      options: {
        display: false,
        filter: false,
      },
    },
  ];
};

export const grantRequestOption = ({
  data,
  setGrantPayload,
  grantPayload,
  handleGrantDetailsClick,
}) => {
  return {
    count: data.count,
    rowsPerPageOptions: [5, 10, 15],
    rowsPerPage: grantPayload.params.pageSize,
    serverSide: true,
    onSearchChange: (searchText) => {
      setTimeout(() => {
        setGrantPayload((prev) => {
          return {
            ...prev,
            params: {
              ...prev.params,
              pageNo: 1,
              pageSize: 5,
              search: searchText,
            },
          };
        });
      }, 600);
    },
    onChangePage: (currentPage) => {
      setGrantPayload((prev) => {
        return { ...prev, params: { ...prev.params, pageNo: currentPage + 1 } };
      });
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setGrantPayload((prev) => {
        let curPageNo = 1;
        if (numberOfRows * prev.params.pageNo > data.count) {
          curPageNo = Math.ceil(data.count / numberOfRows);
        } else {
          curPageNo = prev.params.pageNo;
        }
        return {
          ...prev,
          params: { pageNo: curPageNo, pageSize: numberOfRows },
        };
      });
    },
    onRowClick: (rowData) =>
      handleGrantDetailsClick(rowData[rowData.length - 1]),
    onFilterChange: (changedColumn, filterList, type) => {
      if (type === "reset") {
        setGrantPayload({
          params: {
            pageNo: 1,
            pageSize: 5,
            approval: "",
          },
        });
      }
      if (changedColumn === "approval") {
        setGrantPayload((prev) => {
          return {
            ...prev,
            params: {
              ...prev.params,
              pageNo: 1,
              pageSize: 5,
              approval: (() => {
                switch (filterList[4][0]) {
                  case "Revision Requested":
                    return GRANT_APPROVAL_STATUS.UPDATE_REQUIRED;
                  case "In-Review":
                    return GRANT_APPROVAL_STATUS.IN_REVIEW;
                  default:
                    return filterList[4][0]?.toLowerCase();
                }
              })(),
            },
          };
        });
      } else if (changedColumn === "focusAreaApplicable") {
        setGrantPayload((prev) => {
          return {
            ...prev,
            params: {
              ...prev.params,
              pageNo: 1,
              pageSize: 5,
              focusAreaApplicable: filterList[1][0]?.toLowerCase(),
            },
          };
        });
      }
    },
  };
};
