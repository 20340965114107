import { UserDashboardTemplate } from "UI/templates";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllGrantAsyncThunk,
  getGrantStatesAsyncThunk,
  getprofileAsyncThunk,
} from "redux/asyncThunk";
import { ROUTES } from "utils/constants";
import { UserDashboardContext } from "utils/contexts";
import Swal from "sweetalert2";

export const UserDashboard = () => {
  // -----------------Hooks----------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState("");
  const {
    grants: { count, data: grantsData, allGrantAPIStatus, grantStates },
  } = useSelector((state) => state.grant);
  const { userProfile } = useSelector((state) => state.auth);
  const [grantPayload, setGrantPayload] = useState({
    params: {
      pageNo: 1,
      pageSize: 6,
      approval: "",
    },
  });

  // -----------------Functions----------------- //
  const navigateToGrantCreate = () => {
    if (userProfile?.vender?.isProfileCompleted) {
      navigate(ROUTES.GRANT_CREATE);
      return;
    }
    Swal.fire({
      title: "Your Profile is not Completed!",
      text: "Click proceed to complete your profile",
      icon: "warning",
      confirmButtonText: "Yes, Proceed!",
      showCancelButton: true,
      confirmButtonColor: "#CBDA00",
      cancelButtonColor: "#E45400",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(ROUTES.PROFILE_COMPLETE);
      }
    });
  };
  const handleGrantDetailsClick = (id) => {
    navigate(ROUTES.GRANT_DETAILS, { state: { data: id } });
  };
  const handleGrantPageChange = (e, pageNumber) => {
    setGrantPayload((prev) => {
      return {
        ...prev,
        params: {
          ...prev.params,
          pageNo: pageNumber,
        },
      };
    });
  };
  const handleCardClick = (title) => {
    setGrantPayload((prev) => {
      return {
        ...prev,
        params: {
          pageNo: 1,
          pageSize: 6,
          approval: title,
        },
      };
    });
    setActiveCard(title);
  };

  // -----------------UseEffects----------------- //
  useEffect(() => {
    dispatch(getAllGrantAsyncThunk(grantPayload));
    dispatch(getGrantStatesAsyncThunk());
    // eslint-disable-next-line
  }, [grantPayload]);
  useEffect(() => {
    dispatch(getprofileAsyncThunk());
    // eslint-disable-next-line
  }, []);

  // -----------------Context----------------- //
  const userDashboardContextValue = {
    navigateToGrantCreate,
    handleGrantDetailsClick,
    grantsData,
    count,
    grantPayload,
    setGrantPayload,
    allGrantAPIStatus,
    handleGrantPageChange,
    grantStates,
    handleCardClick,
    activeCard,
    userProfile,
  };
  return (
    <UserDashboardContext.Provider value={userDashboardContextValue}>
      <UserDashboardTemplate />
    </UserDashboardContext.Provider>
  );
};
