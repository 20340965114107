import React, { useEffect, useState } from "react";
import { AdminDashboardTemplate } from "../../templates";
import { AdminDashboardContext } from "utils/contexts/admin/adminDashboardContext";
import { useDispatch, useSelector } from "react-redux";
import {
  getBudgetAnalyticsAsyncThunk,
  getGrantApprovalAnalyticsAsyncThunk,
  getGrantEventsAnalyticsAsyncThunk,
  getGrantStatesAsyncThunk,
  getGrantSubmissionAnalyticsAsyncThunk,
  getGrantsCategoryAnalyticsAsyncThunk,
  getGrantsStatusAnalyticsAsyncThunk,
  getProjectTimeFrameAnalyticsAsyncThunk,
  getRequestForUpdateGrantAsyncThunk,
  getVendorApprovalAnalyticsAsyncThunk,
  getVendorListAsyncThunk,
  getVendorStatesAsyncThunk,
  getVendorStatusAnalyticsAsyncThunk,
  getprofileAsyncThunk,
} from "redux/asyncThunk";

export const AdminDashboard = () => {
  // -----------------Hooks----------------- //
  const dispatch = useDispatch();
  const { grants: grantRequestData } = useSelector((state) => state.grant);
  const { vendorStates, vendorList, vendorListApiStatus } = useSelector(
    (state) => state.vendor
  );
  const [analyticsFilter, setAnalyticsFilter] = useState({
    grantCategory: null,
    grantStatus: null,
    userId: null,
  });
  const [updateGrantPayload, setUpdateGrantPayload] = useState({
    params: { pageNo: 1, pageSize: 5, requestForEdit: true },
  });
  const {
    grantsCategoryAnalytics,
    grantsStatusAnalytics,
    projectTimeFrameAnalytics,
    grantApprovalAnalytics,
    grantSubmissionAnalytics,
    budgetAnalytics,
    grantEventsAnalytics,
    vendorStatusAnalytics,
    vendorApprovalAnalytics,
  } = useSelector((state) => state.analytics);

  // -----------------Constants----------------- //
  const vendorNameOptions = vendorList.map((item) => {
    return {
      label: item.vender.name,
      value: item.id,
    };
  });

  // -----------------Functions----------------- //
  const handleAnalyticsFilterChange = (type, value) => {
    const filterValues = value.map(obj => obj.value) || null;
    setAnalyticsFilter((prev) => {
      prev[type] = filterValues;
      return {
        ...prev,
      };
    });
  };
  const handleVendorSearchChange = (v) => {
    // setTimeout(() => {
    //   dispatch(getVendorListAsyncThunk({ search: v || null }));
    // }, 600);
  };

  // -----------------Use Effects----------------- //
  useEffect(() => {
    dispatch(getGrantStatesAsyncThunk());
    dispatch(getVendorStatesAsyncThunk());
    dispatch(getprofileAsyncThunk());
    dispatch(getRequestForUpdateGrantAsyncThunk(updateGrantPayload));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getGrantsCategoryAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getGrantsStatusAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getProjectTimeFrameAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getGrantSubmissionAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getGrantApprovalAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getBudgetAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getVendorApprovalAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getVendorStatusAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getGrantEventsAnalyticsAsyncThunk(analyticsFilter));
    dispatch(getVendorListAsyncThunk(analyticsFilter));
    // eslint-disable-next-line
  }, [analyticsFilter]);

  // -----------------Context----------------- //
  const adminDashboardContextValue = {
    grantRequestData,
    vendorStates,
    grantsCategoryAnalytics,
    grantsStatusAnalytics,
    projectTimeFrameAnalytics,
    grantApprovalAnalytics,
    grantSubmissionAnalytics,
    budgetAnalytics,
    grantEventsAnalytics,
    vendorStatusAnalytics,
    vendorApprovalAnalytics,
    analyticsFilter,
    handleAnalyticsFilterChange,
    handleVendorSearchChange,
    vendorNameOptions,
    vendorListApiStatus,
    setUpdateGrantPayload
  };
  return (
    <AdminDashboardContext.Provider value={adminDashboardContextValue}>
      <AdminDashboardTemplate />
    </AdminDashboardContext.Provider>
  );
};
