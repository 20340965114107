import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableBody,
  TableHead,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { grey } from "@mui/material/colors";
import { style } from "./style";

export const InfoCardTable = ({ tableData }) => {
  const themes = useTheme();
  const isMatchDownMd = useMediaQuery(themes.breakpoints.down("md"));
  return (
    <TableContainer sx={{ background: "white" }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableData.head.map((item, ind) => (
              <TableCell
                key={ind}
                sx={{
                  background: grey[200],
                  borderBottom: "2px solid rgba(0,0,0,0.05)",
                  ...(isMatchDownMd
                    ? item.name === "milestone" && {
                      minWidth: "500px",
                    }
                    : {
                      ...(item.name === "milestone" &&
                        style.mileStoneTableCellHead),
                      ...(item.name === "completion" &&
                        style.completionTableCellHead),
                      ...(item.name === "goal" && style.goalTableCellHead),
                    }),
                }}
              >
                <Typography variant="h4" sx={{ ...style.infoCardTitle }}>
                  {item.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.rowData.length === 0 ? (
            <TableRow>
              <TableCell align="center" colSpan={tableData.head.length}>
                No Data Available!
              </TableCell>
            </TableRow>
          ) : (
            tableData.rowData.map((rowDataItem, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {tableData.head.map((headItem, headIndex) => {
                  const rowVal = rowDataItem[headItem.name];
                  return (
                    <TableCell
                      key={headIndex}
                      sx={{
                        borderBottom: "2px solid rgba(0,0,0,0.05)",
                        background: "white",
                        ...(isMatchDownMd
                          ? headItem.name === "milestone" && {
                            minWidth: "500px",
                          }
                          : {
                            ...(headItem.name === "milestone" && {
                              ...style.mileStoneTableCellHead,
                              background: grey[50],
                            }),
                            ...(headItem.name === "completion" && {
                              ...style.completionTableCellHead,
                              background: grey[50],
                            }),
                            ...(headItem.name === "goal" && {
                              ...style.goalTableCellHead,
                              background: grey[50],
                            }),
                          }),
                      }}
                    >
                      <Typography variant="h5" sx={style.infoCardDesc}>
                        {Boolean(rowVal) === rowVal
                          ? rowVal
                            ? "Yes"
                            : "No"
                          : rowVal}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
