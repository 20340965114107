import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Stack } from "@mui/material";

export function MenuAtom({ children, menuOptions = [], stopPropagation = false }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (stopPropagation) {
      event.stopPropagation()
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    if (stopPropagation) {
      event.stopPropagation()
    }
    setAnchorEl(null);
  };
  const uniqueId = new Date().getTime();

  return (
    <div>
      <Stack
        id={uniqueId}
        aria-controls={open ? uniqueId + 10 : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {children}
      </Stack>
      <Menu
        id={uniqueId + 10}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": { uniqueId },
        }}
        sx={{ "& .MuiMenu-paper": { boxShadow: 3 } }}
      >
        {menuOptions.length ? (
          menuOptions.map((menu, ind) => (
            <MenuItem
              key={ind}
              onClick={(event) => {
                menu.onClick();
                handleClose(event);
              }}
            >
              {menu.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>No Options Available</MenuItem>
        )}
      </Menu>
    </div>
  );
}
