import {
  Container,
  Grid,
  Stack,
  Typography,
  Hidden,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import { style } from "./style";
import { GRANT_APPROVAL_STATUS, GrantCards } from "utils/data";
import { MDTtable, PageHeader } from "../../../molecules";
import { grantRequestColumn, grantRequestOption } from "utils/data/table";
import ThemeCustomization from "themes";
import MDTtableTheme from "themes/overrides/MDTtable";
import { StatCard, TableLoader } from "UI/atoms";
import { GrantsContext } from "utils/contexts";
import { THUNK_STATUS } from "utils/constants";

export const GrantsTemplate = () => {
  const {
    grantRequestData,
    setGrantPayload,
    grantPayload,
    handleGrantDetailsClick,
    updateGrantPayload,
    setUpdateGrantPayload,
    handleUpdateRequest,
  } = useContext(GrantsContext);
  const theme = useTheme();
  return (
    <>
      {/* Dashboard Description */}
      <Stack sx={style.dashboardBackground} pt={8} mb={10}>
        <Grid container>
          <Container maxWidth="lg">
            <Grid item container justifyContent={"space-between"}>
              <PageHeader title={"Grant Management"} />
            </Grid>
          </Container>
          <Grid
            item
            pt={5}
            xs={12}
            sx={{ background: "linear-gradient(#060e0e 75%, #f3f1f0 30%)" }}
          >
            <Container maxWidth="lg">
              <Hidden smDown>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500,
                    ...style.whiteText,
                  }}
                >
                  Overview
                </Typography>
              </Hidden>
              <Stack sx={style.cardContainer}>
                <Grid container gap={2} direction={"row"} display={"flex"}>
                  {GrantCards(
                    grantRequestData.grantStates,
                    grantRequestData.requestForUpdateData.count
                  ).map((card, ind) => {
                    if (card.approval === GRANT_APPROVAL_STATUS.DRAFT) return null;
                    return <StatCard key={ind} card={card} />;
                  })}
                </Grid>
              </Stack>
            </Container>
          </Grid>
        </Grid>
      </Stack>
      {/* Overview */}
      <Container maxWidth="lg">
        <Grid container spacing={4} pb={10}>
          <Grid item xs={12}>
            <ThemeCustomization overRides={() => MDTtableTheme(theme)}>
              <TableLoader
                loading={
                  grantRequestData.allGrantAPIStatus === THUNK_STATUS.PENDING
                }
              >
                <MDTtable
                  title={"Grant Requests"}
                  columns={grantRequestColumn({ handleGrantDetailsClick })}
                  data={grantRequestData.data}
                  options={grantRequestOption({
                    data: grantRequestData,
                    setGrantPayload,
                    grantPayload,
                    handleGrantDetailsClick
                  })}
                />
              </TableLoader>
            </ThemeCustomization>
          </Grid>
          <Grid item xs={12}>
            <ThemeCustomization overRides={() => MDTtableTheme(theme)}>
              <TableLoader
                loading={
                  grantRequestData.requestForUpdateData.status ===
                  THUNK_STATUS.PENDING
                }
              >
                <MDTtable
                  title={"Requested For Updates"}
                  columns={grantRequestColumn({
                    handleGrantDetailsClick,
                    requestForUpdateTable: true,
                    handleUpdateRequest,
                  })}
                  data={grantRequestData.requestForUpdateData.grants}
                  options={{
                    ...grantRequestOption({
                      data: grantRequestData.requestForUpdateData,
                      setGrantPayload: setUpdateGrantPayload,
                      grantPayload: updateGrantPayload,
                      handleGrantDetailsClick
                    }),
                    filter: false,
                  }}
                />
              </TableLoader>
            </ThemeCustomization>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
