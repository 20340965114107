import React from "react";
import { Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { ImageValue } from ".";

export const DocumentInfoCard = ({
    title,
    data = {},
    Icon = AccountTreeIcon,
    encryptedToken,
    role
}) => {
    return (
        <>
            <Stack sx={style.infoCardBackground}>
                {/* Header */}
                <Stack
                    sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
                >
                    <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
                        <Stack sx={style.infoCardHeadIcon}>
                            <Icon />
                        </Stack>
                        <Typography variant="h3" sx={{ fontWeight: 500 }}>
                            {title}
                        </Typography>
                    </Stack>
                </Stack>
                {/* Content */}
                <Stack sx={style.infoCardContent}>
                    <ImageValue title={'Documents.pdf'}
                        url={data.documents}
                        encryptedToken={encryptedToken}
                        role={role}
                    />
                </Stack>
            </Stack>
        </>
    );
};
