import { combineReducers } from "redux";
import authSlice from "./auth.slice";
import grantSlice from "./grants.slice";
import vendorSlice from "./vendor.slice";
import notificationSlice from "./notification.slice";
import analyticsSlice from "./analytics.slice";

// Combine multiple reducers into a single rootReducer
const rootReducer = combineReducers({
  auth: authSlice,
  grant: grantSlice,
  vendor: vendorSlice,
  notification: notificationSlice,
  analytics: analyticsSlice,
});

export default rootReducer;
