import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { HeadLogo, LoginForm, LoginHeader } from "UI/molecules";
import { style } from "./style";
import { LoginContext } from "utils/contexts/loginContext";
import { OtpInputAtom } from "UI/atoms";
import { LoadingButton } from "@mui/lab";

export const LoginTemplate = () => {
  const { formStep } = useContext(LoginContext);
  return (
    <Grid item container xs={12} sx={style.logoContainer} direction={"column"}>
      {/* Logo */}
      <Grid
        item
        container
        xs={2.3}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <HeadLogo />
      </Grid>
      {/* Login form */}
      <Grid item container xs={7.4} sx={style.loginFormContainer}>
        {formStep.step === 0 &&
          <LoginHeader
            title={"Welcome"}
            desc={"Please enter your details to proceed"}
          />
        }
        {formStep.step === 1 && (
          <LoginHeader
            title={"Verify OTP"}
            desc={
              <>
                OTP has been sent to your registered email.
                <br />
                Please enter OTP to proceed.
              </>
            }
          />
        )}
        <LoginForm />
      </Grid>
    </Grid>
  );
};
