import React, { useEffect, useState } from "react";
import { AdminLoginTemplate } from "../../templates";
import { AdminLoginContext } from "../../../utils/contexts/admin/adminLoginContext";
import { useNavigate } from "react-router-dom";
import { ROUTES, USER_ROLES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { setToken, setUser } from "redux/slices/auth.slice";
import { jwtDecode } from "jwt-decode";

export const AdminLogin = () => {
  // -----------------Hooks----------------- //
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogging, setIsLogging] = useState(true);
  const { loginStatus } = useSelector((state) => state.auth);

  // -----------------Constants----------------- //
  // MSAL Integration
  const request = {
    scopes: ["user.read"],
  };

  // -----------------Functions----------------- //
  const navigateToBase = () => {
    navigate(ROUTES.BASE);
  };
  const handleAdminLogin = () => {
    instance.loginRedirect({
      scopes: ["user.read"],
    })
  };

  // -----------------Use Effects----------------- //
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          ...request,
          account: accounts[0],
        })
        .then((res) => {
          const accessToken = res.accessToken;
          dispatch(setToken(accessToken));
          let userData = jwtDecode(accessToken)
          dispatch(setUser({ role: USER_ROLES.ADMIN, email: userData.unique_name, vender: { name: userData.name } }))
          setIsLogging(false);
        })
        .catch((error) => {
          instance
            .acquireTokenRedirect(request)
            .then((res) => {
              const accessToken = res.accessToken;
              dispatch(setToken(accessToken));
              let userData = jwtDecode(accessToken)
              dispatch(setUser({ role: USER_ROLES.ADMIN, email: userData.unique_name, vender: { name: userData.name } }))
              setIsLogging(false);
            })
            .catch((err) => {
              setIsLogging(false);
              console.log('Error in acquiring token by redirect:', err)
            });
        });
    }
    // eslint-disable-next-line 
  }, [inProgress]);

  // -----------------Context----------------- //
  const adminLoginContextValue = {
    navigateToBase,
    handleAdminLogin,
    loginStatus,
    isLogging
  };
  return (
    <AdminLoginContext.Provider value={adminLoginContextValue}>
      <AdminLoginTemplate />
    </AdminLoginContext.Provider>
  );
};
