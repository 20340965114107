import React, { useContext } from "react";
import { Stack, Typography, alpha } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { GrantDetailsContext } from "utils/contexts";
import { GRANT_APPROVAL_STATUS } from "utils/data";

export const FeedbackInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  const { changeFeedBackStatus, isAdmin } = useContext(GrantDetailsContext);
  const grantStatus = data?.approval;
  data = data?.feedbacks;
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
          <Stack
            direction={"column"}
            alignItems={"flex-end"}
            py={1}
            px={2}
          ></Stack>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            ...style.infoCardContent,
            p: 2,
            flexDirection: "column",
          }}
        >
          {data.map((feed, ind) => {
            return (
              <Stack
                key={ind}
                direction={"row"}
                alignItems={"center"}
                gap={1}
                sx={{
                  p: 0.5,
                  background: (t) => {
                    return !feed.status
                      ? alpha(t.palette.error.main, 0.1)
                      : alpha(t.palette.success.main, 0.2);
                  },
                  my: 0.5,
                  borderRadius: 1,
                }}
              >
                <ErrorOutlineRoundedIcon sx={{ opacity: 0.7 }} />
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                  gap={1}
                >
                  <Typography
                    className="feedback-text"
                    sx={{
                      ...style.infoCardDesc,
                      opacity: 0.7,
                      ...(feed?.status && {
                        textDecoration: "line-through",
                      }),
                    }}
                  >
                    {feed.feedback}
                  </Typography>
                  {isAdmin &&
                    !feed.status &&
                    grantStatus === GRANT_APPROVAL_STATUS.IN_REVIEW && (
                      <Typography
                        color="inherit"
                        sx={{
                          opacity: 0.5,
                          fontSize: "12px",
                          color: "black",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          changeFeedBackStatus(feed.id, "approved")
                        }
                      >
                        Mark as complete
                      </Typography>
                    )}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};
