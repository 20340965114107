// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function InputBase(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "18px",
          fontWeight: 500,
          height: "27px",
          // padding: "28px 12px 11px 24px !important",
        },
      },
    },
  };
}
