import React, { useContext } from "react";
import { Container, Grid, Stack, Box } from "@mui/material";
import { style } from "./style";
import { GrantStepForm, PageHeader } from "UI/molecules";
import { GrantCreateContext } from "utils/contexts";

export const GrantCreateTemplate = () => {
  const { urlData } = useContext(GrantCreateContext);
  return (
    <>
      <Stack sx={style.background} pt={8} pb={4}>
        <Container maxWidth="lg">
          <Grid item container justifyContent={"space-between"}>
            <PageHeader
              title={urlData ? "Update Grant" : "Create Grant"}
              desc={""}
            />
          </Grid>
        </Container>
      </Stack>
      <Box py={2}>
        <Container maxWidth="lg">
          <GrantStepForm />
        </Container>
      </Box>
    </>
  );
};
