// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
