import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { resetRedux, toggleThemeMode } from "redux/slices/auth.slice";
import { style } from "./style";
import { Grid } from "@mui/material";
import { VideoComponent } from "UI/molecules";

// Auth layout component for rendering nested routes
export const AuthLayout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleThemeMode("dark"));
    dispatch(resetRedux());
    // eslint-disable-next-line
  }, []);
  return (
    <Grid container>
      {/* Auth Content */}
      <Grid item container xs={12} md={6} sx={style.AuthLayoutLeftGrid}>
        <Outlet />
      </Grid>

      {/* Video Section */}
      <Grid item xs={6} sx={style.videoGrid}>
        <VideoComponent />
      </Grid>
    </Grid>
  );
};
