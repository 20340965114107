export const style = {
  dashboardBackground: {
    background: "#060e0e",
    position: "absolute",
    zIndex: -1,
  },
  dateFont: {
    fontWeight: 400,
    mt: 1.5,
  },
  cardContainer: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 2,
    mt: 2,
  },
  cardImgBackground: (color) => {
    return {
      justifyContent: "center",
      alignItems: "center",
      width: "46px",
      height: "38px",
      background: color,
    };
  },
  graphContainer: {
    background: (theme) => theme.palette.common.white,
    borderRadius: "12px",
    p: 4,
  },
};
