import { Grid, Stack, Typography } from "@mui/material";
import { style } from "./style";

export const StatCard = (props) => {
  const { card, active, onClick } = props;
  return (
    <Grid
      item
      container
      xs={12}
      sm={3}
      lg={2}
      xl={2}
      onClick={onClick}
      sx={{
        ...style.statCardBox(card.color),
        ...(active && {
          borderBottom: "3px solid",
          borderColor: card.color,
          marginBottom: "-3px",
        }),
      }}
    >
      <Grid item flex={1}>
        <Stack sx={style.cardImgBackground(card.color)}>
          <card.icon sx={{ color: "white" }} />
        </Stack>
        <Typography variant="subtitle1" color="textlight.main">
          {card.title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h2">
          {isNaN(Number(card.value)) ? "--" : card.value}
        </Typography>
      </Grid>
    </Grid>
  );
};
