import React from "react";
import { Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { DocumentInfoCard, TitleValue } from ".";

export const DeclarationInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <Stack sx={style.infoCardContent}>
          <TitleValue
            title={
              "Have This Entity Previously Received Funding Or Sponsorship From Aldar?"
            }
            value={data.previouslyFundedByAldar}
            fullWidth
          />
          <TitleValue
            title={"Is This The First Cycle Of This Initiative?"}
            value={data.firstCycleOfInitiative}
            fullWidth
          />
          <TitleValue
            title={"Has There Been Similar Impact Project Delivered Before?"}
            value={data.similarProjectDeliveredBefore}
            fullWidth
          />
        </Stack>
      </Stack>
    </>
  );
};
