import { Outlet } from "react-router-dom";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { logout, toggleThemeMode } from "redux/slices/auth.slice";
import { ChangePasswordDialog } from "UI/molecules";
import { HeaderContext } from "utils/contexts/vendorsContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordValidationSchema } from "utils/form/validationSchema";
import toast from "react-hot-toast";
import { setForgerockConfig } from "utils/other/forgeRockService";
import { USER_CHANGE_PASSWORD_JOURNEY } from "config";
import { FRAuth, FRUser } from "@forgerock/javascript-sdk";
import { changePasswordRequestCompletedAsyncThunk } from "redux/asyncThunk/vendors.asyncThunk";
import { getprofileAsyncThunk } from "redux/asyncThunk";

// Main layout component for rendering nested routes
export const MainLayout = () => {
  const dispatch = useDispatch();
  const { userProfile, user } = useSelector(state => state.auth);
  const isChangePasswordDialog = userProfile?.vender?.isChangePasswordRequested;
  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePasswordValidationSchema),
  });

  const handleLogout = async () => {
    await FRUser.logout();
    dispatch(logout());
  }

  const handleChangePassword = async (data) => {
    try {
      setIsChangePasswordLoading(true);
      const { existingPassword, password, confirmPassword } = data;
      setForgerockConfig(USER_CHANGE_PASSWORD_JOURNEY);
      let frRegistrationRes0 = await FRAuth.start();
      let frRegistrationReq1 = { ...frRegistrationRes0 };
      frRegistrationReq1.payload.callbacks[0].input[0].value = existingPassword;
      frRegistrationReq1.payload.callbacks[1].input[0].value = password;
      frRegistrationReq1.payload.callbacks[2].input[0].value = confirmPassword;
      const frRegistrationRes1 = await FRAuth.next(frRegistrationReq1);
      // if frRegistrationRes1 got error

      if (frRegistrationRes1?.type === "LoginSuccess") {
        dispatch(changePasswordRequestCompletedAsyncThunk({ id: user.id })).unwrap().then(() => {
          dispatch(getprofileAsyncThunk())
            .unwrap().then(() => {
              toast.success('Password Changed Successfully!');
              setIsChangePasswordLoading(false);
            }).catch(() => { });
        }).catch(() => { });
      } else if (frRegistrationRes1?.type === "Step") {
        toast.error('Invalid Credentials!');
        setIsChangePasswordLoading(false);
      } else if (frRegistrationRes1?.payload?.message === "Failed policy validation") {
        toast.error('Current password must be different from the 3 previous password. Try again with the different password!');
        setIsChangePasswordLoading(false);
      } else if (frRegistrationRes1?.payload?.message === "User Locked Out.") {
        toast.error('Your account has been temporarily locked. Please try again later.');
        setIsChangePasswordLoading(false);
      } else if (frRegistrationRes1?.payload?.message) {
        toast.error(frRegistrationRes1?.payload?.message);
        setIsChangePasswordLoading(false);
      } else {
        toast.error('Something went wrong!');
        setIsChangePasswordLoading(false);
      }
    } catch (e) {
      toast.error(e.message);
      setIsChangePasswordLoading(false);
    }
  }

  useEffect(() => {
    dispatch(toggleThemeMode("light"));
    // eslint-disable-next-line
  }, []);

  const headerContextValue = {
    isChangePasswordLoading,
    handleChangePassword: handleSubmit(handleChangePassword),
    control,
    errors,
    handleLogout
  }
  return (
    <>
      <HeaderContext.Provider value={headerContextValue}>
        <Header />
        <Outlet />
        <ChangePasswordDialog open={isChangePasswordDialog} />
      </HeaderContext.Provider>
    </>
  );
};
