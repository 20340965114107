import { removeSpecialCharacters, toPascalCase } from "utils/other/helper";
import * as yup from "yup";

// eslint-disable-next-line
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

const standardPasswordSchema = ({
  minLength = 8,
  requireUppercase = true,
  requireLowercase = true,
  requireNumber = true,
  requireSpecialChar = true,
  maxLength = 16,
} = {}) => {
  let passwordSchema = yup
    .string()
    .min(minLength, `Password must be at least ${minLength} characters`)
    .max(maxLength, `Password should not exceed ${maxLength} characters`);

  if (requireUppercase) {
    passwordSchema = passwordSchema.matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least one uppercase letter"
    );
  }

  if (requireLowercase) {
    passwordSchema = passwordSchema.matches(
      /^(?=.*[a-z])/,
      "Password must contain at least one lowercase letter"
    );
  }

  if (requireNumber) {
    passwordSchema = passwordSchema.matches(
      /^(?=.*\d)/,
      "Password must contain at least one number"
    );
  }

  if (requireSpecialChar) {
    passwordSchema = passwordSchema.matches(
      /^(?=.*[@$!%*?&])/,
      "Password must contain at least one special character"
    );
  }

  return passwordSchema.required("Password is required");
};

export const loginValidationSchema = yup.object().shape({
  email: yup.string().required("User Name is required").email("Invalid email"),
  password: yup.string().required("Password is required"),
});

export const passwordValidationSchema = yup.object().shape({
  password: standardPasswordSchema(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const signupValidationSchema = yup.object().shape({
  fullname: yup
    .string()
    .trim()
    .transform((val) => {
      return toPascalCase(removeSpecialCharacters(val));
    })
    .required("Name is required")
    .max(200, "Name should not exceed 200 characters"),
  email: yup
    .string()
    .trim()
    .lowercase()
    .email("Invalid email")
    .required("Email is required")
    .max(200, "Email should not exceed 200 characters"),
  password: standardPasswordSchema().test(
    "matchSubstring",
    "Password substring should not match with prefilled values i.e. (name, email,...etc)",
    function (value) {
      const [fname, lname] = this.parent.fullname.toLowerCase().split(" ");
      const email = this.parent.email.toLowerCase();
      if (fname && lname && email)
        return (
          !value.toLowerCase().includes(fname) &&
          !value.toLowerCase().includes(lname) &&
          !value.toLowerCase().includes(email)
        );
      return true;
    }
  ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  address: yup.object().shape({
    houseNo: yup
      .string()
      .trim()
      .uppercase()
      .required("House Number is required")
      .max(200, "House Number should not exceed 200 characters"),
    addressLine1: yup
      .string()
      .trim()
      .required("Address Line 1 is required")
      .max(200, "Address Line 1 should not exceed 200 characters"),
    addressLine2: yup
      .string()
      .trim()
      .required("Address Line 2 is required")
      .max(200, "Address Line 2 should not exceed 200 characters"),
    city: yup
      .string()
      .trim()
      .required("City is required")
      .max(200, "City should not exceed 200 characters"),
    state: yup
      .string()
      .trim()
      .required("State is required")
      .max(200, "State should not exceed 200 characters"),
    country: yup
      .string()
      .trim()
      .required("Country is required")
      .max(200, "Country should not exceed 200 characters"),
    zipCode: yup
      .string()
      .trim()
      .uppercase()
      .required("Zip Code is required")
      .max(200, "Zip Code should not exceed 200 characters"),
  }),
  primaryContact: yup.object().shape({
    title: yup
      .string()
      .trim()
      .transform((val) => {
        return toPascalCase(removeSpecialCharacters(val));
      })
      .required("Title is required")
      .max(200, "Title should not exceed 200 characters"),
    email: yup
      .string()
      .trim()
      .lowercase()
      .required("Email is required")
      .email("Invalid email")
      .max(200, "Email should not exceed 200 characters"),
    mobile: yup
      .string()
      .trim()
      .required("Mobile is required")
      .matches(phoneRegExp, "Invalid mobile format")
      .max(200, "Mobile should not exceed 200 characters"),
  }),
  secondaryContact: yup.object().shape({
    title: yup
      .string()
      .trim()
      .transform((val) => {
        return toPascalCase(removeSpecialCharacters(val));
      })
      .required("Title is required")
      .max(200, "Title should not exceed 200 characters"),
    email: yup
      .string()
      .trim()
      .lowercase()
      .required("Email is required")
      .email("Invalid email")
      .max(200, "Email should not exceed 200 characters"),
    mobile: yup
      .string()
      .trim()
      .required("Mobile is required")
      .matches(phoneRegExp, "Invalid mobile format")
      .max(200, "Mobile should not exceed 200 characters"),
  }),
  // documents: yup.mixed().required("Documents are required"),
});

export const ProjectInfoValidationSchema = yup.object().shape({
  projectTimeFrame: yup.string().required("Project Time Frame is required"),
  frequency: yup.string().required("Project Frequency is required"),
  projectName: yup
    .string()
    .trim()
    .transform((val) => toPascalCase(val))
    .required("Project Name is required")
    .max(200, "Project Name should not exceed 200 characters"),
  projectBrief: yup.string().trim().required("Project Brief is required"),
  requestedBudget: yup
    .number()
    .typeError("Requested Budget must be a number")
    .required("Requested Budget is required")
    .positive("Requested Budget must be a positive number"),
  totalProjectCost: yup
    .number()
    .typeError("Total Project Cost must be a number")
    .required("Total Project Cost is required")
    .positive("Total Project Cost must be a positive number")
    .test(
      "requestedBudget",
      "Total Project Cost must be greater than Requested Budget",
      function (value) {
        const requestedBudget = this.parent.requestedBudget;
        return value > requestedBudget - 1;
      }
    ),
});

export const FocusAreaValidationSchema = () => {
  return yup.object().shape({
    focusAreaApplicable: yup
      .string()
      .required("Focus Area Applicable is required")
      .oneOf(["live", "belong", "sustain"], "Invalid value"),
    problemSolvings: yup.array().test({
      message: "At least one option must be checked",
      test: (obj) => {
        return obj.map((item) => item.value).some((value) => value);
      },
    }),
    expectedImpacts: yup.array().of(
      yup.object().shape({
        grantQuestionId: yup.string().required(),
        perMonth: yup
          .number()
          .positive("Monthly Impact must be a positive number")
          .typeError("Monthly Impact must be a number")
          .required("Monthly Impact is required"),
        total: yup
          .number()
          .positive("Total Impact must be a positive number")
          .typeError("Total impact must be a number")
          .required("Total impact is required")
          .test(
            "perMonthValid",
            "Total Impact must be greater than Monthly Impact",
            function (value) {
              const perMonth = this.parent.perMonth;
              return value >= perMonth;
            }
          ),
      })
    ),
  });
};

export const otherImpactValidationSchema = () => {
  return yup.object().shape({
    otherTargets: yup.array().of(
      yup.object().shape({
        grantQuestionId: yup.string().required("Impact Target is Required"),
        perMonth: yup
          .number()
          .typeError("Monthly Impact must be a number")
          .required("Monthly Impact is required"),
        total: yup
          .number()
          .typeError("Total impact must be a number")
          .required("Total impact is required")
          .test(
            "perMonthValid",
            "Total Impact must be greater than Monthly Impact",
            function (value) {
              const perMonth = this.parent.perMonth;
              return value >= perMonth;
            }
          ),
      })
    ),
  });
};

export const DeclarationValidationSchema = yup.object().shape({
  previouslyFundedByAldar: yup
    .boolean()
    .typeError("Answer is Required")
    .required("Answer is Required"),
  firstCycleOfInitiative: yup
    .boolean()
    .typeError("Answer is Required")
    .required("Answer is Required"),
  similarProjectDeliveredBefore: yup
    .boolean()
    .typeError("Answer is Required")
    .required("Answer is Required"),
  documents: yup.mixed("Document is Required").required("Document is Required"),
  commitMent: yup.bool().oneOf([true], "Field must be checked"),
  isAccurateInfo: yup.bool().oneOf([true], "Field must be checked"),
});

export const BudgetInfoValidationSchema = () => {
  return yup.object().shape({
    budgets: yup.array().of(
      yup.object().shape({
        item: yup.string().required("Item is required"),
        description: yup.string().required("Description is required"),
        estimatedPrice: yup
          .number()
          .typeError("Estimated Price must be a number")
          .positive("Estimated Price must be a positive number")
          .required("Estimated Price is required"),
        estimatedQuantity: yup
          .number()
          .typeError("Estimated Quantity must be a number")
          .integer("Estimated Quantity must be an integer")
          .min(0, "Estimated Quantity must be at least 0")
          .required("Estimated Quantity is required"),
        estimatedCost: yup
          .number()
          .typeError("Estimated Cost must be a number")
          .positive("Estimated Cost must be a positive number")
          .required("Estimated Cost is required"),
        coveredByPartner: yup
          .number()
          .typeError("Covered By Partner must be a number"),
        coveredByAldar: yup
          .number()
          .typeError("Covered By Aldar must be a number"),
      })
    ),
  });
};

export const EventInfoValidationSchema = () => {
  return yup.object().shape({
    events: yup.array().of(
      yup
        .object()
        .shape({
          date: yup.string(),
          time: yup.string(),
          location: yup
            .string()
            .max(200, "Location should not exceed 200 characters"),
          targetedAudience: yup
            .string()
            .max(200, "Target Audience should not exceed 200 characters"),
          objective: yup.string(),
        })
        .test(
          "at-least-two-options",
          "At least two options are required",
          function (value) {
            const { date, time, location, targetedAudience, objective } = value;
            const hasTwoOptions =
              (date && time) ||
              (date && location) ||
              (date && targetedAudience) ||
              (date && objective) ||
              (time && location) ||
              (time && targetedAudience) ||
              (time && objective) ||
              (location && targetedAudience) ||
              (location && objective) ||
              (targetedAudience && objective);
            return hasTwoOptions;
          }
        )
    ),
  });
};

export const feedBackValidationSchema = () => {
  return yup.object().shape({
    feedbacks: yup.array().of(
      yup.object().shape({
        feedback: yup.string().required("FeedBack is required"),
      })
    ),
  });
};

export const addVendorValidationSchema = yup.object().shape({
  fullname: yup
    .string()
    .trim()
    .transform((val) => {
      return toPascalCase(removeSpecialCharacters(val));
    })
    .required("Full Name is required"),
  email: yup.string().required("Email is required").email(),
});

export const submitReportValidationSchema = yup.object().shape({
  grantId: yup.string().required("Option is Required"),
  reports: yup.array().of(
    yup.object().shape({
      questionId: yup.string().required(),
      questionType: yup.string().required(),
      achieved: yup
        .number()
        .typeError("Target Achieved must be a number")
        .required("Target Achieved is required"),
    })
  ),
});

export const forgotPasswordValidationSchema = yup.object().shape({
  email: yup.string().required("Email is required").email("Invalid email"),
});

export const otpValidationSchema = yup.object().shape({
  otp: yup.string().required("OTP is required"),
});

export const changePasswordValidationSchema = yup.object().shape({
  existingPassword: yup.string().required("Existing password is required"),
  ...passwordValidationSchema.fields,
});
