import React from "react";
import { Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { InfoCardTable } from ".";

export const EventInfoCard = ({ title, data = [], Icon = AccountTreeIcon }) => {
  const tableData = {
    head: [
      {
        label: "Objective",
        name: "objective",
      },
      {
        label: "Target Audience",
        name: "targetedAudience",
      },
      {
        label: "Location",
        name: "location",
      },
      {
        label: "Time",
        name: "time",
      },
      {
        label: "Date",
        name: "date",
      },
    ],
    rowData:
      data?.events?.map((exp) => {
        return {
          objective: exp.objective,
          targetedAudience: exp.targetedAudience,
          location: exp.location,
          time: exp.time,
          date: exp.date,
        };
      }) || [],
  };
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <InfoCardTable tableData={tableData} />
      </Stack>
    </>
  );
};
