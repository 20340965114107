import { Stack, Typography } from "@mui/material";
import React from "react";
import { style } from "./style";

export const TitleValue = ({ title, value, fullWidth = false }) => {
  return (
    <Stack
      sx={{
        ...style.infoCardValue,
        ...(fullWidth && { minWidth: "100%", flexShrink: "1 !important" }),
      }}
    >
      <Typography variant="h4" sx={style.infoCardTitle}>
        {title}
      </Typography>
      <Typography variant="h5" sx={style.infoCardDesc}>
        {Boolean(value) === value
          ? value === true
            ? "Yes"
            : "No"
          : value || "--"}
      </Typography>
    </Stack>
  );
};
