import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import {
  changePasswordRequestCompletedService,
  changePasswordRequestService,
  changeVendorStatusService,
  createVendorService,
  getAllVendorService,
  getSingleVendorService,
  getVendorListService,
  getVendorStatesService,
  uploadDocumentsService,
} from "redux/services";

export const getAllVendorAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_ALL_VENDORS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAllVendorService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeVendorStatusAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.VENDOR_STATUS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await changeVendorStatusService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestForChangePasswordAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.CHANGE_PASSWORD_REQUEST,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await changePasswordRequestService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePasswordRequestCompletedAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.CHANGE_PASSWORD_REQUEST_COMPLETED,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await changePasswordRequestCompletedService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorStatesAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_VENDOR_STATES,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getVendorStatesService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSingleVendorAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_SINGLE_VENDOR,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSingleVendorService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createVendorAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.CREATE_VENDOR,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createVendorService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorListAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_VENDOR_LIST,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getVendorListService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadDocumentsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.UPLOAD_DOCUMENTS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await uploadDocumentsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
