import axios from "axios";
import { handleError, handleRequest, handleResponse } from "./api";
const backendUrl = process.env.REACT_APP_BACKEND_URL;
// const backendUrl = "https://app-middleware-csr-qa-un-001.azurewebsites.net";
// const backendUrl = "http://localhost:4000";

export const createAxiosInstance = (baseUrl) => {
  const instance = axios.create({
    baseURL: baseUrl,
  });

  instance.interceptors.request.use(
    (config) => handleRequest(config),
    (error) => error
  );
  instance.interceptors.response.use(
    (response) => handleResponse(response),
    (error) => handleError(error)
  );

  return instance;
};

export const AuthInstance = createAxiosInstance(`${backendUrl}/api`);
export const AppInstance = axios.create({
  baseURL: `${backendUrl}/api`,
});

// Authentication client for making HTTP requests with AuthInstance
export const AuthClient = ({
  url,
  method,
  data = {},
  params = {},
  headers = {},
}) => {
  return AuthInstance.request({ url, method, data, headers, params });
};

// Application client for making HTTP requests with AppInstance
export const AppClient = ({
  url,
  method,
  data = {},
  params = {},
  headers = {},
}) => {
  return AppInstance.request({ url, method, data, headers, params });
};

export { handleResponse, handleError, handleRequest };
