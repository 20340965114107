import { AM_URL } from "config";
import { Config, FRAuth } from "@forgerock/javascript-sdk";
import { WEB_OAUTH_CLIENT } from "config";
import { APP_URL } from "config";
import { FR_SCOPE } from "config";
import { SALT_KEY } from "config";
import CryptoJS from "crypto-js";
import { USER_REGISTRATION_JOURNEY } from "config";
import toast from "react-hot-toast";
import _ from "lodash";

const forceAuthMiddleware = (req, action, next) => {
  switch (action.type) {
    case "START_AUTHENTICATE":
      req.url.searchParams.set("ForceAuth", "true");
      break;
    case "AUTHENTICATE":
      req.url.searchParams.set("ForceAuth", "true");
      break;
    default:
  }
  next();
};

export const setForgerockConfig = (tree) => {
  try {
    Config.set({
      clientId: WEB_OAUTH_CLIENT,
      middleware: [forceAuthMiddleware],
      redirectUri: `${APP_URL}`,
      scope: `${FR_SCOPE}`,
      serverConfig: {
        baseUrl: `${AM_URL}`,
        timeout: 10000,
      },
      realmPath: "alpha",
      tree: tree,
    });
  } catch (err) {
    console.log("Error in setting Configuration of ForgeRock");
  }
};

export const handleForgerockRegistration = async (
  vendorData,
  handleNextOperation = () => {},
  setIsBtnLoading = () => {}
) => {
  setIsBtnLoading(true);
  try {
    const { password, vender, fullname, email, addresses, address } =
      vendorData;
    const { city, state, country, zipCode, addressLine1 } =
      addresses?.[0] || address;
    const decryptedPassword = CryptoJS.AES.decrypt(
      password,
      `${SALT_KEY}`
    ).toString(CryptoJS.enc.Utf8);
    const [firstName, lastName] = !_.isEmpty(vender)
      ? vender.name.split(" ")
      : fullname.split(" ");

    setForgerockConfig(USER_REGISTRATION_JOURNEY);
    let frRegistrationRes0 = await FRAuth.start();

    let frRegistrationReq1 = { ...frRegistrationRes0 };
    frRegistrationReq1.payload.callbacks[0].input[0].value = email;
    frRegistrationReq1.payload.callbacks[1].input[0].value = firstName || "NA";
    frRegistrationReq1.payload.callbacks[2].input[0].value = lastName || " ";
    frRegistrationReq1.payload.callbacks[3].input[0].value = city;
    frRegistrationReq1.payload.callbacks[4].input[0].value = state;
    frRegistrationReq1.payload.callbacks[5].input[0].value = country;
    frRegistrationReq1.payload.callbacks[6].input[0].value = zipCode.toString();
    frRegistrationReq1.payload.callbacks[7].input[0].value = addressLine1;
    frRegistrationReq1.payload.callbacks[8].input[0].value =
      decryptedPassword || password;
    const frRegistrationRes1 = await FRAuth.next(frRegistrationReq1);

    if (frRegistrationRes1?.payload?.message) {
      toast.error(frRegistrationRes1?.payload?.message);
      setIsBtnLoading(false);
    }

    if (
      frRegistrationRes1?.type === "LoginSuccess" ||
      frRegistrationRes1?.payload?.callbacks?.[0]?.output?.[0]?.value ===
        "Your account already exists. please login."
    ) {
      handleNextOperation();
    } else if (
      frRegistrationRes1?.payload?.callbacks?.[0]?.output?.[0]?.name ===
      "message"
    ) {
      toast.error(
        frRegistrationRes1?.payload?.callbacks?.[0]?.output?.[0]?.value
      );
      setIsBtnLoading(false);
    } else if (!frRegistrationRes1?.payload?.message) {
      toast.error("Something went wrong!");
      setIsBtnLoading(false);
    }
  } catch (error) {
    setIsBtnLoading(false);
    toast.error(error.message);
  }
};
