import { IconButton, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { grantRequestStatusChip } from "utils/other/helper";
import { formatDate } from "utils/other/helper";
import { MenuAtom } from "UI/atoms/MenuAtom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import { GRANT_APPROVAL_STATUS, VENDOR_STATUS } from "..";

export const vendorsColumn = ({
  handleVendorDetailsClick,
  handleVendorStatusChange,
}) => {
  return [
    {
      label: "Vendor Name",
      name: "vender",
      options: {
        filter: false,
        filterType: "textField",
        customBodyRender: (val) => {
          const [firstName, lastName] = val.name;
          const fullName = lastName !== "NA" ? val.name : firstName;
          return fullName;
        },
      },
    },
    {
      name: "createdAt",
      label: "Registration Date",
      options: {
        filter: false,
        customBodyRender: (val) => formatDate(val),
      },
    },
    {
      label: "No. of Grants",
      name: "grants",
      options: {
        filter: false,
        customBodyRender: (val) => {
          const filterDrafGrants = val.filter(
            (grant) => grant.approval !== GRANT_APPROVAL_STATUS.DRAFT
          );
          return <>{filterDrafGrants?.length}</>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Pending", "Approved", "Rejected"],
        },
        customBodyRender: (value) => <>{grantRequestStatusChip(value)}</>,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "center" }),
        customBodyRender: (value, tableMeta) => {
          return (
            <Stack
              direction="row"
              justifyContent={"center"}
              spacing={1}
              sx={{ color: "#818181" }}
            >
              {/* <IconButton onClick={() => handleVendorDetailsClick(value)}> */}

              <MenuAtom
                menuOptions={[
                  {
                    label: (
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <VisibilityOutlinedIcon />
                        View
                      </Stack>
                    ),
                    onClick: () => handleVendorDetailsClick(value),
                  },
                  ...(tableMeta.rowData[3] !== VENDOR_STATUS.APPROVED
                    ? [
                        {
                          label: (
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <DoneIcon color="success" />
                              Approve
                            </Stack>
                          ),
                          onClick: () =>
                            handleVendorStatusChange({
                              id: value,
                              status: VENDOR_STATUS.APPROVED,
                            }),
                        },
                      ]
                    : []),
                  ...(tableMeta.rowData[3] !== VENDOR_STATUS.REJECTED
                    ? [
                        {
                          label: (
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={1}
                            >
                              <BlockIcon color="error" />
                              Reject
                            </Stack>
                          ),
                          onClick: () =>
                            handleVendorStatusChange({
                              id: value,
                              status: VENDOR_STATUS.REJECTED,
                            }),
                        },
                      ]
                    : []),
                ]}
                stopPropagation={true}
              >
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              </MenuAtom>
            </Stack>
          );
        },
      },
    },
    {
      name: "id",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
  ];
};

export const vendorOptions = ({
  data,
  vendorPayload,
  setVendorPayload,
  handleVendorDetailsClick,
}) => {
  return {
    count: data.count,
    rowsPerPageOptions: [5, 10, 15],
    rowsPerPage: vendorPayload.params.pageSize,
    serverSide: true,
    onSearchChange: (searchText) => {
      setTimeout(() => {
        setVendorPayload((prev) => {
          return {
            ...prev,
            params: {
              ...prev.params,
              pageNo: 1,
              pageSize: 5,
              search: searchText,
            },
          };
        });
      }, 600);
    },
    onRowClick: (rowData) =>
      handleVendorDetailsClick(rowData[rowData.length - 1]),
    onFilterChange: (changedColumn, filterList) => {
      if (changedColumn === "status") {
        setVendorPayload((prev) => {
          return {
            ...prev,
            params: {
              ...prev.params,
              pageNo: 1,
              pageSize: 5,
              status: filterList[3][0]?.toLowerCase(),
            },
          };
        });
      }
    },
    onChangePage: (currentPage) => {
      setVendorPayload((prev) => {
        return { ...prev, params: { ...prev.params, pageNo: currentPage + 1 } };
      });
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setVendorPayload((prev) => {
        let curPageNo = 1;
        if (numberOfRows * prev.params.pageNo > data.count) {
          curPageNo = Math.ceil(data.count / numberOfRows);
        } else {
          curPageNo = prev.params.pageNo;
        }
        return {
          ...prev,
          params: { pageNo: curPageNo, pageSize: numberOfRows },
        };
      });
    },
  };
};
