import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { Box, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BACKEND_URL } from 'config';
import { AppClient } from 'utils/network';
import { METHODS } from 'utils/constants';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
    const { onClose, selectedValue, open, url, encryptedToken, role } = props;
    const handleClose = () => {
        onClose(selectedValue);
    };
    const [objectUrl, setObjectUrl] = useState('');

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await AppClient({
                    url: `${BACKEND_URL}/api/preview_redirect`,
                    method: METHODS.GET,
                    params: {
                        fileUrl: url.split("/").pop(),
                    },
                    headers: { responseType: 'blob' }
                });
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const objectUrl = URL.createObjectURL(blob);
                setObjectUrl(objectUrl);

                return () => {
                    URL.revokeObjectURL(objectUrl);
                };
            } catch (error) {
                console.error('Error fetching the document:', error);
            }
        };
        if (url) fetchDocument();
    }, [url, encryptedToken, role]);

    return (
        <Dialog onClose={handleClose} open={open}
            PaperProps={{
                sx: {
                    maxWidth: "90%",
                    maxHeight: "80%",
                    objectFit: "cover",
                }
            }}
        >
            <Box sx={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}>
                <CloseIcon onClick={handleClose} />
            </Box>
            {/* <img src={UserProfile} alt="Documents" /> */}
            {url ?
                <object className={"pdf"}
                    data={objectUrl}
                    width="900"
                    height="600">
                </object> :
                <Stack sx={{ px: 6, py: 4 }} justifyContent={'center'} alignItems={'center'}>
                    No Preview Available
                </Stack>
            }
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export function ImagePreviewDialog({ children, url, encryptedToken, role }) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <>
            <Stack sx={{ alignItems: 'center' }} onClick={handleClickOpen}>
                {children}
            </Stack>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                url={url}
                encryptedToken={encryptedToken}
                role={role}
            />
        </>
    );
}