import React from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { InputField, SliderAtom } from "UI/atoms";
import { useContext } from "react";
import { GrantCreateContext } from "utils/contexts";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

export const BudgetInfoForm = ({ title, Icon = AccountTreeIcon }) => {
  const {
    control,
    errors,
    budgetsField,
    deleteBudgetRow,
    addBudgetRow,
    watch,
    setValue,
  } = useContext(GrantCreateContext);

  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{
            ...style.infoCardHead,
            justifyContent: "space-between",
          }}
        >
          <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 1.5 }}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
          <IconButton onClick={addBudgetRow} color="primary">
            <AddIcon />
          </IconButton>
        </Stack>
        {/* Content */}
        <Stack
          sx={{
            background: "white",
            p: 2,
          }}
        >
          <>
            {budgetsField.fields.length ? (
              budgetsField.fields.map((row, index) => {
                const fieldNamePrefix = `budgets[${index}]`;
                const coveredByAldar = watch(
                  `${fieldNamePrefix}.coveredByAldar`
                );
                const coveredByPartner = 100 - coveredByAldar;
                setValue(`${fieldNamePrefix}.coveredByPartner`, coveredByPartner);
                return (
                  <Grid key={row.id} container spacing={2}>
                    <Grid item xs={12} my={2}>
                      <Stack direction="row" alignItems={"center"}>
                        <Divider variant="fullWidth" sx={{ flex: 1 }}>
                          <Stack sx={style.formCount}>
                            <Typography variant="h4">{index + 1}</Typography>
                          </Stack>
                        </Divider>
                        <IconButton
                          color="error"
                          onClick={() => deleteBudgetRow(index)}
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid
                      key={index}
                      item
                      container
                      spacing={2}
                      alignItems={"center"}
                    >
                      <Grid item container spacing={2} xs={12}>
                        <Grid item xs={12} sm={6} md={3}>
                          <InputField
                            name={`${fieldNamePrefix}.item`}
                            label={"Item"}
                            errors={errors}
                            control={control}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <InputField
                            name={`${fieldNamePrefix}.estimatedPrice`}
                            label={"Estimated Price"}
                            type={"number"}
                            errors={errors}
                            control={control}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <InputField
                            name={`${fieldNamePrefix}.estimatedQuantity`}
                            label={"Estimated Quantity"}
                            type={"number"}
                            errors={errors}
                            control={control}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <InputField
                            name={`${fieldNamePrefix}.estimatedCost`}
                            label={"Estimated Cost"}
                            type={"number"}
                            errors={errors}
                            control={control}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name={`${fieldNamePrefix}.description`}
                            label={"Description"}
                            errors={errors}
                            control={control}
                            fullWidth
                            multiline
                            maxRows={0.5}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SliderAtom
                            aria-label="Covered By"
                            defaultValue={50}
                            name={`${fieldNamePrefix}.coveredByAldar`}
                            control={control}
                            label={
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                gap={2}
                              >
                                <Stack>
                                  Covered By Partner:
                                  <Box sx={{ color: "black" }}>
                                    {coveredByPartner}%
                                  </Box>
                                </Stack>
                                <Stack>
                                  Covered By Aldar:
                                  <Box sx={{ color: "black" }}>
                                    {coveredByAldar}%
                                  </Box>
                                </Stack>
                              </Stack>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Stack
                sx={{
                  pl: 2,
                  pt: 2,
                }}
              >
                <Typography>
                  Click + button to add budget information.
                </Typography>
              </Stack>
            )}
          </>
        </Stack>
      </Stack>
    </>
  );
};
