import { Grid, Typography } from "@mui/material";
import { InputField } from "UI/atoms";
import React, { useContext } from "react";
import { SignupContext } from "utils/contexts/signupContext";
import EmailIcon from "@mui/icons-material/Email";

export const SigninInfo = () => {
  const { control, errors, isInvited, handlePasswordChange, handleEmailChange } = useContext(SignupContext);
  return (
    <div className="formGroupContainer">
      <Typography
        variant="subtitle2"
        className="formGroupTitle"
        mt={1}
        gutterBottom
      >
        Log in Information
      </Typography>
      <Grid container justifyContent={"center"} spacing={2.5}>
        <Grid item xs={12}>
          <InputField
            fullWidth={true}
            variant="filled"
            label="Email"
            name={"email"}
            type={"email"}
            onChange={handleEmailChange}
            errors={errors}
            control={control}
            EndIcon={EmailIcon}
            disabled={isInvited ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"password"}
            label={"Password"}
            type={"password"}
            autoComplete={"on"}
            errors={errors}
            control={control}
            onChange={handlePasswordChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <InputField
            fullWidth={true}
            variant="filled"
            name={"confirmPassword"}
            label={"Confirm Password"}
            type={"password"}
            autoComplete={"on"}
            errors={errors}
            control={control}
          />
        </Grid>
      </Grid>
    </div>
  );
};
