export const ROUTES = {
  DASHBOARD: "/",
  BASE: "/",
  GRANTS: "/grants",
  VENDORS: "/vendors",
  VENDOR_DETAILS: "/vendors/vendor-details",
  GRANT_DETAILS: "/grants/grant-details",
  GRANT_CREATE: "/grants/create",
  GRANT_UPDATE: "/grants/update",
  INVITATION: "/auth/:userId/invite/:inviteId/:token/",
  FORGOT_PASSWORD: "/forgot-password",
  REGISTER: "/register",
  ADMIN_LOGIN: "/admin",
  CHANGE_PASSWORD: "/change-password",

  // PROFILE
  PROFILE: "/profile",
  PROFILE_COMPLETE: "/profile/update",
};
