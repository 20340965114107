import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ProfileCreateTemplate } from "UI/templates/profileCreate";
import { ProfileCreateContext } from "utils/contexts";
import { changePasswordValidationSchema, signupValidationSchema } from "utils/form/validationSchema";
import { createVendorAsyncThunk, getprofileAsyncThunk } from "redux/asyncThunk";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { updateProfileFormDefaultValues } from "utils/form/initialValues";
import { ROUTES } from "utils/constants";
import { USER_CHANGE_PASSWORD_JOURNEY } from "config";
import { FRAuth } from "@forgerock/javascript-sdk";
import { setForgerockConfig } from "utils/other/forgeRockService";
import toast from "react-hot-toast";
import { setFieldError } from "utils/other/helper";
import { changePasswordRequestCompletedAsyncThunk } from "redux/asyncThunk/vendors.asyncThunk";

export const ProfileCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isChangePasswordPage = location.pathname.includes(ROUTES.CHANGE_PASSWORD);
  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
  const { userProfile, user } = useSelector((state) => state.auth);
  const { createVendorApiStatus } = useSelector((state) => state.vendor);
  const profileCompleteSchema = yup.object().shape({
    ...signupValidationSchema.fields,
    password: yup.mixed(),
    confirmPassword: yup.mixed(),
    email: yup.mixed(),
    documents: yup.mixed(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(profileCompleteSchema),
    defaultValues: updateProfileFormDefaultValues({ userProfile }),
  });
  const {
    handleSubmit: handleChangePasswordSubmit,
    control: changePasswordControl,
  } = useForm({
    resolver: yupResolver(changePasswordValidationSchema),
  });
  const handleSignup = (data) => {
    dispatch(createVendorAsyncThunk(data))
      .unwrap()
      .then(() => {
        dispatch(getprofileAsyncThunk());
        navigate(-1);
      })
      .catch((err) => setFieldError(err, setError));
  };
  const handleChangePassword = async (data) => {
    try {
      setIsChangePasswordLoading(true);
      const { existingPassword, password, confirmPassword } = data;
      setForgerockConfig(USER_CHANGE_PASSWORD_JOURNEY);
      let frRegistrationRes0 = await FRAuth.start();
      let frRegistrationReq1 = { ...frRegistrationRes0 };
      frRegistrationReq1.payload.callbacks[0].input[0].value = existingPassword;
      frRegistrationReq1.payload.callbacks[1].input[0].value = password;
      frRegistrationReq1.payload.callbacks[2].input[0].value = confirmPassword;
      const frRegistrationRes1 = await FRAuth.next(frRegistrationReq1);
      // if frRegistrationRes1 got error
      setIsChangePasswordLoading(false);
      if (frRegistrationRes1?.type === "LoginSuccess") {
        toast.success('Password Changed Successfully!');
        dispatch(changePasswordRequestCompletedAsyncThunk({ id: user.id }));
        navigate(-1);
      } else if (frRegistrationRes1?.type === "Step") {
        toast.error('Invalid Credentials!');
      } else if (frRegistrationRes1?.payload?.message === "Failed policy validation") {
        toast.error('Current password must be different from the 3 previous password. Try again with the different password!');
      } else if (frRegistrationRes1?.payload?.message === "User Locked Out.") {
        toast.error('Your account has been temporarily locked. Please try again later.');
      } else if (frRegistrationRes1?.payload?.message) {
        toast.error(frRegistrationRes1?.payload?.message);
      } else {
        toast.error('Something went wrong!');
      }
    } catch (e) {
      toast.error(e.message);
      setIsChangePasswordLoading(false);
    }
  }
  const profileCreateContextValue = {
    handleSubmit,
    control,
    errors,
    userProfile,
    createVendorApiStatus,
    handleSignup: handleSubmit(handleSignup),
    handleChangePassword: handleChangePasswordSubmit(handleChangePassword),
    isChangePasswordPage,
    changePasswordControl,
    isChangePasswordLoading
  };

  return (
    <ProfileCreateContext.Provider value={profileCreateContextValue}>
      <ProfileCreateTemplate />
    </ProfileCreateContext.Provider>
  );
};
