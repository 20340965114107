import { ProfileDetailsTemplate } from "UI/templates";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES, USER_ROLES } from "utils/constants";
import { ProfileDetailsContext } from "utils/contexts";

export const ProfileDetails = () => {
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state.auth);
  const isAdmin = userProfile?.role === USER_ROLES.ADMIN;

  const navigateToUpdateProfile = () => {
    navigate(ROUTES.PROFILE_COMPLETE);
  };
  const profileDetailsContextValue = {
    userProfile,
    isAdmin,
    navigateToUpdateProfile,
  };
  return (
    <ProfileDetailsContext.Provider value={profileDetailsContextValue}>
      <ProfileDetailsTemplate />
    </ProfileDetailsContext.Provider>
  );
};
