import { Navigate } from "react-router-dom";
import { MainLayout } from "layout/mainLayout/main.layout";
import { ROUTES } from "utils/constants";
import { ProfileCreate } from "UI/pages/profileCreate";
import _ from "lodash";
import {
  AdminDashboard,
  GrantCreate,
  GrantDetails,
  Grants,
  ProfileDetails,
  UserDashboard,
  VendorDetails,
  Vendors,
} from "UI/pages";

// Define main application routes
export const mainRoutes = (role, location, isAdminAuthenticated, pending) => {
  const commonRoutes = [
    {
      element: <MainLayout />,
      children: [
        { path: ROUTES.PROFILE, element: <ProfileDetails />, exact: true },
      ],
    },
  ];
  let adminRoutes = [
    {
      path: "*",
      element: <></>,
      exact: true,
    },
  ];
  if (!isAdminAuthenticated && !pending) {
    adminRoutes = [
      {
        path: "*",
        element: <Navigate to={ROUTES.ADMIN_LOGIN} />,
        exact: true,
      },
    ];
  } else if (isAdminAuthenticated && !pending) {
    adminRoutes = [
      {
        element: <MainLayout />,
        children: [
          {
            path: ROUTES.DASHBOARD,
            element: <AdminDashboard />,
            exact: true,
          },
          {
            path: ROUTES.VENDORS,
            children: [
              {
                path: ROUTES.VENDORS,
                element: <Vendors />,
                exact: true,
              },
              {
                path: ROUTES.VENDOR_DETAILS,
                element: _.isEmpty(location.state) ? (
                  <Navigate to={ROUTES.BASE} />
                ) : (
                  <VendorDetails />
                ),
                exact: true,
              },
            ],
            exact: true,
          },
          {
            path: ROUTES.GRANTS,
            children: [
              {
                path: ROUTES.GRANTS,
                element: <Grants />,
                exact: true,
              },
              {
                path: ROUTES.GRANT_DETAILS,
                element: _.isEmpty(location.state) ? (
                  <Navigate to={ROUTES.BASE} />
                ) : (
                  <GrantDetails />
                ),
                exact: true,
              },
            ],
          },
          {
            path: "*",
            element: <Navigate to={ROUTES.BASE} />,
            exact: true,
          },
        ],
      },
    ];
  }

  const userRoutes = [
    {
      element: <MainLayout />,
      children: [
        {
          path: ROUTES.DASHBOARD,
          children: [
            {
              path: ROUTES.DASHBOARD,
              element: <UserDashboard />,
              exact: true,
            },
            {
              path: ROUTES.PROFILE_COMPLETE,
              element: <ProfileCreate />,
              exact: true,
            },
            {
              path: ROUTES.GRANT_DETAILS,
              element: _.isEmpty(location.state) ? (
                <Navigate to={ROUTES.BASE} />
              ) : (
                <GrantDetails />
              ),
              exact: true,
            },
            {
              path: ROUTES.GRANT_CREATE,
              element: <GrantCreate />,
              exact: true,
            },
            {
              path: ROUTES.GRANT_UPDATE,
              element: _.isEmpty(location.state) ? (
                <Navigate to={ROUTES.BASE} />
              ) : (
                <GrantCreate />
              ),
              exact: true,
            },
            {
              path: ROUTES.CHANGE_PASSWORD,
              element: <ProfileCreate />,
              exact: true,
            },
          ],
        },
        { path: "*", element: <Navigate to={ROUTES.BASE} />, exact: true },
      ],
    },
  ];

  switch (role) {
    case "admin":
      return [...adminRoutes, ...commonRoutes];
    default:
      return [...userRoutes, ...commonRoutes];
  }
};
