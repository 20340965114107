import { Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";

export const MDTtable = ({ title, columns = [], data = [], options }) => {
  let globalOptions = {
    filterType: "checkbox",
    elevation: 0,
    selectableRows: "none",
    sort: false,
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    responsive: "standard",
    tableBodyMaxHeight: "600px",
  };
  globalOptions = {
    ...globalOptions,
    ...options,
  };
  return (
    <MUIDataTable
      title={
        <Typography sx={{ fontSize: "36px", fontWeight: 700 }}>
          {title}
        </Typography>
      }
      data={data}
      columns={columns}
      options={globalOptions}
    />
  );
};
