import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

export const SelectInput = ({ onChange = () => { }, control, ...props }) => {
  const { control: initialControl } = useForm({});
  const usedControl = control || initialControl;
  return (
    <Controller
      name={props.name}
      control={usedControl}
      disabled={props.disabled}
      defaultValue={props.defaultValue || ""}
      render={({ field, fieldState }) => {
        return (
          <FormControl fullWidth variant="filled" error={!!fieldState.error}>
            <InputLabel
              size="small"
              id={`${props.name}-label`}
              sx={{
                color: "black", // Text color
              }}
            >
              {props.label}
            </InputLabel>
            <Select
              size="small"
              labelId={`${props.name}-label`}
              id={props.name}
              {...field}
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e);
                onChange(e);
              }}
              label={props.label}
              sx={{
                color: "black", // Text color
                borderRadius: "11px",
                borderColor: "black",
                "&:before": {
                  borderBottomColor: "black", // Underline color
                },
                "&:after": {
                  borderBottomColor: "black", // Underline color when focused
                },
                "&:focus": {
                  borderColor: "black", // Background color when focused
                },
                "&:active": {
                  borderColor: "black", // Background color when focused
                },
              }}
              error={fieldState.invalid}
              disableUnderline
            >
              {!props.options || props.options.length === 0 ? (
                <MenuItem>No Options Available</MenuItem>
              ) : (
                props.options.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    value={option.value}
                    sx={{
                      "&:hover": {
                        backgroundColor: "black", // Background color
                        color: "white", // Font color
                      },
                    }}
                    disabled={
                      props?.values &&
                      props.values.otherTargets
                        .map((q) => q.grantQuestionId)
                        .includes(option.value)
                    }
                  >
                    {option.label}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText
              error={fieldState.invalid}
              sx={{
                color: "black", // Text color
              }}
            >
              {fieldState.error?.message}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
