export const style = {
  bottomBorder: {
    borderBottom: "1px solid #818181",
    background: "#060e0e",
    color: "white",
  },
  activePage: {
    borderBottom: "6px solid white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  smallActivePage: {
    // borderBottom: "6px solid white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  activeTitle: {
    fontWeight: 700,
    ml: 1,
  },
  inActiveTitle: {
    fontWeight: 300,
    ml: 1,
  },
  cursorPointer: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  avatarBackground: {
    background: "#D4D0CA",
    p: 0.4,
    ml: 1,
    borderRadius: "50%",
    height: "50%",
  },
  semiBold: { fontWeight: 500 },
  menuIconContainer: {
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    gap: 1,
  },
  menuIcons: { "&:hover": { background: "rgba(255,255,255,0.2)" } },
};
