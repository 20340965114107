import { METHODS, SERVICE_URL } from "utils/constants";
import { AppClient } from "utils/network";

export const getGrantsCategoryAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_GRANTS_CATEGORY_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};

export const getGrantsStatusAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_GRANTS_STATUS_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};

export const getProjectTimeFrameAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_PROJECT_TIMEFRAME_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};

export const getGrantSubmissionAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_GRANT_SUBMISSION_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};

export const getGrantApprovalAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_GRANT_APPROVAL_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};

export const getBudgetAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_BUDGET_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};

export const getGrantEventsAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_GRANT_EVENTS_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};

export const getVendorStatusAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_VENDOR_STATUS_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};

export const getVendorApprovalAnalyticsService = (payload) => {
  return AppClient({
    url: SERVICE_URL.GET_VENDOR_APPROVAL_ANALYTICS,
    method: METHODS.GET,
    params: payload,
  });
};
