import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import {
  getNotificationService,
  updateNotificationService,
} from "redux/services";

export const getNotificationAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_NOTIFICATIONS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getNotificationService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateNotificationAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.UPDATE_NOTIFICATIONS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateNotificationService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
