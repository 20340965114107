import { FRUser } from "@forgerock/javascript-sdk";
import React, { useState } from "react";

export function useGlobalStateMgmt({ email, isAuthenticated, username }) {
  const [authenticated, setAuthentication] = useState(isAuthenticated || false);
  const [mail, setEmail] = useState(email || "");
  const [name, setUser] = useState(username || "");

  async function setAuthenticationWrapper(value) {
    if (value === false) {
      try {
        await FRUser.logout();
      } catch (err) {
        console.error(`Error: logout did not successfully complete; ${err}`);
      }
    }
    setAuthentication(value);
  }

  function setEmailWrapper(value) {
    window.sessionStorage.setItem("sdk_email", `${value}`);
    setEmail(value);
  }

  function setUserWrapper(value) {
    window.sessionStorage.setItem("sdk_username", `${value}`);
    setUser(value);
  }

  return [
    {
      isAuthenticated: authenticated,
      email: mail,
      username: name,
    },
    {
      setAuthentication: setAuthenticationWrapper,
      setEmail: setEmailWrapper,
      setUsername: setUserWrapper,
    },
  ];
}

export const AppContext = React.createContext([{}, {}]);
