import { grey } from "@mui/material/colors";

export const style = {
  inputBackground: {
    borderRadius: "11px",
    background: "rgba(0, 0, 0, 0.06)",
    padding: "15px 24px",
  },
  inputLabel: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "18px",
    fontWeight: 500,
    pb: 1,
  },
  customInputTitle: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "18px",
    fontWeight: 500,
  },
  customInputBg: {
    borderRadius: "11px",
    background: "rgba(0, 0, 0, 0.06)",
    padding: "15px 24px",
  },
  cardImgBackground: (color) => {
    return {
      justifyContent: "center",
      alignItems: "center",
      width: "46px",
      height: "38px",
      bgcolor: color,
    };
  },
  statCardBox: (color) => {
    return {
      background: "white",
      borderRadius: "4px",
      minWidth: "250px",
      width: "100%",
      px: 2.5,
      py: 2,
      flexDirection: "column",
      cursor: "pointer",
      "&:hover": {
        background: grey[50],
      },
    };
  },
  tableLoader: {
    position: "absolute",
    background: "rgba(0,0,0,0.1)",
    top: 0,
    left: 0,
    zIndex: 9,
    width: "100%",
    height: "100%",
  },
};
