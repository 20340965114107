import { Button, Grid, Typography } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { style } from "./style";
import { getTodayDate } from "utils/other/helper";

export const AddVendorButton = ({ handleAddVendorDialogOpen }) => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={6}
      justifyContent={{ xs: "flex-start", sm: "flex-end" }}
    >
      <Grid item>
        <Button
          startIcon={<AddRoundedIcon />}
          variant="contained"
          color="info"
          sx={{ justifyContent: "flex-start" }}
          fullWidth
          onClick={handleAddVendorDialogOpen}
        >
          Add Vendors
        </Button>
        <Typography
          variant="h5"
          color="textlight.main"
          mt={1}
          sx={style.dateFont}
        >
          {getTodayDate()}
        </Typography>
      </Grid>
    </Grid>
  );
};
