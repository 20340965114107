import { Box, CircularProgress, Stack } from "@mui/material";
import React from "react";
import { style } from "./style";

export const TableLoader = ({ loading, children }) => {
  return (
    <Box sx={{ position: "relative" }}>
      {loading && (
        <Stack
          sx={style.tableLoader}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Stack>
      )}
      {children}
    </Box>
  );
};
