import { ROUTES } from "utils/constants";
import DashboardIcon from "assets/images/icons/dashboard.svg";
import GrantsIcon from "assets/images/icons/grants.svg";
import VendorsIcon from "assets/images/icons/vendors.svg";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelIcon from "@mui/icons-material/Cancel";
import SystemUpdateAltRoundedIcon from "@mui/icons-material/SystemUpdateAltRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import {
  BudgetInfoForm,
  DeclarationForm,
  EventInfoForm,
  FocusAreaForm,
  OtherImpactForm,
  ProjectInfoForm,
} from "UI/molecules";
import { lighten } from "@mui/material";

export const Pages = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    path: ROUTES.DASHBOARD,
  },
  {
    title: "Grants",
    icon: GrantsIcon,
    path: ROUTES.GRANTS,
  },
  {
    title: "Vendors",
    icon: VendorsIcon,
    path: ROUTES.VENDORS,
  },
];

export const GrantCards = (status = {}, requestForUpdateCounts) => {
  const allGrants = Object.values(status).reduce(
    (acc, total) => total + acc,
    0
  );
  return [
    {
      color: (theme) => theme.palette.primary.main,
      icon: FormatListBulletedIcon,
      title: "All Grants",
      value: allGrants,
      approval: "",
    },
    {
      color: (theme) => theme.palette.success.main,
      icon: CheckCircleIcon,
      title: "Approved Grants",
      value: status.approved,
      approval: GRANT_APPROVAL_STATUS.APPROVED,
    },
    {
      color: (theme) => theme.palette.error.main,
      icon: CancelIcon,
      title: "Rejected Grants",
      value: status.rejected,
      approval: GRANT_APPROVAL_STATUS.REJECTED,
    },
    {
      color: (theme) => theme.palette.pending.main,
      icon: AccessTimeIcon,
      title: "Draft Grants",
      value: status.draft,
      approval: GRANT_APPROVAL_STATUS.DRAFT,
    },
    {
      color: (theme) => lighten(theme.palette.secondary.main, 0.2),
      icon: HistoryRoundedIcon,
      title: "In-Review Grants",
      value: status.in_review,
      approval: GRANT_APPROVAL_STATUS.IN_REVIEW,
    },

    {
      color: (theme) => theme.palette.info.main,
      icon: EditOutlinedIcon,
      title: "Revision Requested",
      value: status.update_required,
      approval: "update_required",
    },
    {
      color: (theme) => theme.palette.primary.main,
      icon: SystemUpdateAltRoundedIcon,
      title: "Request For Updates",
      value: requestForUpdateCounts,
    },
  ];
};

export const DashboardCards = (status, vendorStates) => {
  return [
    {
      color: (theme) => theme.palette.secondary.main,
      icon: FormatListBulletedIcon,
      title: "Total Requests",
      value:
        Object.values(vendorStates || {}).reduce(
          (sum, count) => sum + count,
          0
        ) +
        Object.values(status.grantStates || {}).reduce(
          (sum, count) => sum + count,
          0
        ),
    },
    ...VendorCards(vendorStates),
    ...GrantCards(status.grantStates, status.requestForUpdateData.count),
  ];
};

export const VendorCards = (vendorStates) => {
  return [
    {
      color: (theme) => theme.palette.success.main,
      icon: CheckCircleIcon,
      title: "Approved Vendors",
      value: vendorStates?.approved,
    },
    {
      color: (theme) => theme.palette.pending.main,
      icon: AccessTimeIcon,
      title: "Pending Vendor Requests",
      value: vendorStates?.pending,
    },
    {
      color: (theme) => theme.palette.error.main,
      icon: CancelIcon,
      title: "Rejected Vendors",
      value: vendorStates?.rejected,
    },
  ];
};

export const Declaration = [
  {
    title:
      "Have this entity previously received funding or sponsorship from Aldar?",
    value: "Yes",
    fullWidth: true,
  },
  {
    title: "Is this the first cycle of this initiative?",
    value: "No",
    fullWidth: true,
  },
  {
    title: "Has there been similar impact project delivered before?",
    value: "Yes",
    fullWidth: true,
  },
];

export const PROJECT_TIMEFRAME_OPTIONS = [
  { value: "6-12_weeks", label: "6-12 Weeks" },
  { value: "6-12_months", label: "6-12 Months" },
  { value: "1-3_years", label: "1-3 Years" },
  { value: "+4_years", label: "+4 Years" },
];

export const PROJECT_FREQUENCY_OPTIONS = [
  { value: "one_off", label: "One Off" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "quaterly", label: "Quarterly" },
  { value: "semi-annual", label: "Semi Annual" },
  { value: "annual", label: "Annual" },
];

export const FOCUS_AREA_APPLICABLE_OPTIONS = [
  { value: "live", label: "Live" },
  { value: "belong", label: "Belong" },
  { value: "sustain", label: "Sustain" },
];

export const INITIAL_TARGET_ROW = {
  grantQuestionId: "",
  perMonth: "",
  total: "",
};
export const INITIAL_EVENT_ROW = {
  date: "",
  time: "",
  location: "",
  targetedAudience: "",
  objective: "",
};

export const INITIAL_BUDGET = {
  item: "",
  description: "",
  estimatedPrice: "",
  estimatedQuantity: "",
  estimatedCost: "",
  coveredByPartner: 50,
  coveredByAldar: 50,
};

export const YES_NO_OPTION = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const GrantStepFormComponents = [
  {
    formComponent: (
      <ProjectInfoForm title={"Project Information"} Icon={AccountTreeIcon} />
    ),
  },
  {
    formComponent: (
      <FocusAreaForm
        title={"Focus Area & Impact Information"}
        Icon={FilterTiltShiftIcon}
      />
    ),
  },
  {
    formComponent: (
      <OtherImpactForm
        title={"Other Impact Information"}
        Icon={MoreHorizRoundedIcon}
      />
    ),
  },
  {
    formComponent: (
      <EventInfoForm title={"Events Information"} Icon={EventRoundedIcon} />
    ),
  },
  {
    formComponent: (
      <BudgetInfoForm title={"Budget Information"} Icon={LocalAtmRoundedIcon} />
    ),
  },
  {
    formComponent: (
      <DeclarationForm title={"Declaration"} Icon={ChecklistRoundedIcon} />
    ),
  },
];

export const notificationData = [
  {
    title: "New Grant Request",
    date: "2:00 PM - 24/04/2023",
    desc: "Grant request sent by {vendor name}. Please review the request.",
  },
  {
    title: "Vendor Registered",
    date: "2:00 PM - 24/04/2023",
    desc: "Grant request sent by {vendor name}. Please review the request.",
  },
  {
    title: "Impacts Reported",
    date: "2:00 PM - 24/04/2023",
    desc: "Grant request sent by {vendor name}. Please review the request.",
  },
];

export const GRANT_APPROVAL_STATUS = {
  DRAFT: "draft",
  IN_REVIEW: "in_review",
  APPROVED: "approved",
  REJECTED: "rejected",
  UPDATE_REQUIRED: "update_required",
};

export const VENDOR_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
  INCOMPLETE_PROFILE: "incomplete_profile",
};

export const PROJECT_FREQUENCY = {
  ONE_OFF: "one_off",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  QUATERLY: "quaterly",
  SEMI_ANNUAL: "semi-annual",
  ANNUAL: "annual",
};

export const GRANT_STATUS_OPTIONS = [
  { value: "approved", label: "Approved" },
  { value: "rejected", label: "Rejected" },
  { value: "in_review", label: "In-Review" },
  { value: "update_required", label: "Revision Requested" },
];

export const VENDOR_STATUS_OPTIONS = [
  { value: "approved", label: "Approved" },
  { value: "rejected", label: "Rejected" },
  { value: "pending", label: "Pending" },
];
