import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import {
  getBudgetAnalyticsService,
  getGrantApprovalAnalyticsService,
  getGrantEventsAnalyticsService,
  getGrantSubmissionAnalyticsService,
  getGrantsCategoryAnalyticsService,
  getGrantsStatusAnalyticsService,
  getProjectTimeFrameAnalyticsService,
  getVendorApprovalAnalyticsService,
  getVendorStatusAnalyticsService,
} from "redux/services";

export const getGrantsCategoryAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_GRANTS_CATEGORY_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getGrantsCategoryAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGrantsStatusAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_GRANTS_STATUS_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getGrantsStatusAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProjectTimeFrameAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_PROJECT_TIMEFRAME_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getProjectTimeFrameAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGrantSubmissionAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_GRANT_SUBMISSION_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getGrantSubmissionAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGrantApprovalAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_GRANT_APPROVAL_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getGrantApprovalAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBudgetAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_BUDGET_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getBudgetAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGrantEventsAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_EVENT_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getGrantEventsAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorStatusAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_VENDOR_STATUS_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getVendorStatusAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorApprovalAnalyticsAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_VENDOR_APPROVAL_ANALYTICS,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getVendorApprovalAnalyticsService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
