import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  changeVendorStatusAsyncThunk,
  getSingleVendorAsyncThunk,
  requestForChangePasswordAsyncThunk,
} from "redux/asyncThunk";
import { VendorDetailsTemplate } from "UI/templates";
import { VendorDetailsContext } from "utils/contexts";
import { handleForgerockRegistration } from "utils/other/forgeRockService";
import toast from "react-hot-toast";
import { logError } from "utils/other/helper";
import { VENDOR_STATUS } from "utils/data";


export const VendorDetails = () => {
  // ----------------- Initial Constant ----------------- //
  const location = useLocation();
  const singleVendorID = location?.state?.data;
  const { token, user } = useSelector((state) => state.auth);
  const encryptedToken = token
  const role = user?.role
  // CryptoJS.AES.encrypt(
  //   JSON.stringify(token),
  //   ENCRYPTED_KEY
  // ).toString();

  // -----------------Hooks----------------- //
  const dispatch = useDispatch();
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const { singleVendorData, singleVendorStatus } = useSelector(
    (state) => state.vendor
  );
  // -----------------Functions----------------- //
  const changePasswordRequest = () => {
    dispatch(requestForChangePasswordAsyncThunk({ id: singleVendorData.id })).unwrap().then(() => {
      dispatch(getSingleVendorAsyncThunk({ id: singleVendorID }));
    }).catch(() => { })
  }
  const handleVendorStatusChange = async ({ vendorData, status }) => {
    setApproveBtnLoading(true);
    if (!vendorData?.vender?.isProfileCompleted && status === VENDOR_STATUS.APPROVED) {
      toast.error("You can't approve vendor whose profile is not completed.");
      setApproveBtnLoading(false);
      return;
    }
    if (vendorData.status === VENDOR_STATUS.REJECTED && status === VENDOR_STATUS.APPROVED && vendorData.vender.approvedBy !== null) {
      dispatch(
        changeVendorStatusAsyncThunk({
          id: vendorData.id,
          status: VENDOR_STATUS.APPROVED,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(getSingleVendorAsyncThunk({ id: singleVendorID }));
        })
        .catch((err) => logError(err));
    } else if (status === VENDOR_STATUS.APPROVED) {
      const handleNextOperation = async () => await dispatch(
        changeVendorStatusAsyncThunk({
          id: vendorData.id,
          status: VENDOR_STATUS.APPROVED,
        })
      )
        .unwrap()
        .then(() => {
          setApproveBtnLoading(false);
          dispatch(getSingleVendorAsyncThunk({ id: singleVendorID }));
        })
        .catch((err) => logError(err));
      handleForgerockRegistration(vendorData, handleNextOperation, setApproveBtnLoading);
    } else {
      setApproveBtnLoading(false);
      dispatch(
        changeVendorStatusAsyncThunk({ id: vendorData.id, status: VENDOR_STATUS.REJECTED })
      )
        .unwrap()
        .then(() => {
          dispatch(getSingleVendorAsyncThunk({ id: singleVendorID }));
        })
        .catch((err) => logError(err));
    }
  };

  // -----------------Use Effects----------------- //
  useEffect(() => {
    dispatch(getSingleVendorAsyncThunk({ id: singleVendorID }));
    // eslint-disable-next-line 
  }, [singleVendorID]);

  // -----------------Context----------------- //
  const vendorDetailsContextValue = {
    singleVendorData,
    singleVendorStatus,
    handleVendorStatusChange,
    approveBtnLoading,
    encryptedToken,
    role,
    changePasswordRequest
  };
  return (
    <VendorDetailsContext.Provider value={vendorDetailsContextValue}>
      <VendorDetailsTemplate />
    </VendorDetailsContext.Provider>
  );
};
