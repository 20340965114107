// third-party
import { merge } from "lodash";

// project import
import Button from "./Button";
import IconButton from "./IconButton";
import InputLabel from "./InputLabel";
import InputBase from "./InputBase";
import TextField from "./TextField";
import MuiAutocomplete from "./Autocomplete";

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme, overRides) {
  return merge(
    Button(theme),
    IconButton(theme),
    InputLabel(theme),
    InputBase(theme),
    TextField(theme),
    MuiAutocomplete(theme),
    overRides()
  );
}
