export const style = {
  background: {
    background: "#060e0e",
  },
  grantButton: {
    height: "54px",
    borderRadius: 0,
    px: 2,
    justifyContent: "flex-start",
  },
  dateFont: {
    fontWeight: 400,
    mt: 1.5,
  },
  whiteText: {
    color: (theme) => theme.palette.common.white,
    fontWeight: 400,
  },
  cardContainer: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 2,
    mt: 2,
  },
  cardImgBackground: (color) => {
    return {
      justifyContent: "center",
      alignItems: "center",
      width: "46px",
      height: "38px",
      background: color,
    };
  },
  graphContainer: {
    background: (theme) => theme.palette.common.white,
    borderRadius: "12px",
    p: 4,
  },
};
