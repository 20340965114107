// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function InputLabel(theme) {
  const isDark = theme.palette.mode === "dark";
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          fontWeight: 500,
          marginLeft: "12px",
          marginTop: "5px !important",
          color: "rgba(0,0,0,0.6) !important",
          ...(isDark && {
            color: "#D4D0CA",
          }),
        },
        filled: {
          "&.MuiInputLabel-shrink": {
            fontSize: "15px",
            ...(isDark && {
              opacity: 0.3,
              color: "#cbcbcb",
            }),
          },
        },
        outlined: {
          lineHeight: "0.8em",
          "&.MuiInputLabel-sizeSmall": {
            lineHeight: "1em",
          },
          "&.MuiInputLabel-shrink": {
            background: theme.palette.background.paper,
            padding: "0 8px",
            marginLeft: -6,
            lineHeight: "1.4375em",
          },
        },
      },
    },
  };
}
