import React, { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import ThumbUpOffAltRoundedIcon from "@mui/icons-material/ThumbUpOffAltRounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import _ from "lodash";
import { GRANT_APPROVAL_STATUS } from "utils/data";
import { GrantDetailsContext } from "utils/contexts/admin/grantsContext";
import { THUNK_STATUS } from "utils/constants";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PublishRoundedIcon from "@mui/icons-material/PublishRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import { SubmitReportDialog } from "UI/pages/submitReportDialog";
import { LoadingButton } from "@mui/lab";
import { grantDetailsDesc } from "utils/constants/page.constants";
import {
  Container,
  Grid,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { style } from "./style";
import {
  BudgetInfoCard,
  DeclarationInfoCard,
  DocumentInfoCard,
  EventInfoCard,
  FeedbackInfoCard,
  FocusAreaInfoCard,
  GrantPageButtons,
  GrantRequestDialog,
  OtherTargetInfoCard,
  ProjectInfoCard,
  ReportInfoCard,
} from "UI/molecules";
import { GoBack } from "UI/molecules/GoBack";

export const GrantDetailsTemplate = () => {
  const {
    isAdmin,
    singleGrantData,
    grantRequestStatus,
    navigateToUpdateGrant,
    reports,
    createGrantAPIStatus,
    handleGrantStatusChange,
    loadGrant,
    submitReport,
    isAllFeedBackMarked,
    changeGrantStatusApiStatus,
    encryptedToken,
    role
  } = useContext(GrantDetailsContext);

  return (
    <>
      <Grid sx={style.grantDetailBackground}></Grid>
      <GrantRequestDialog />
      <Container maxWidth="lg">
        <Grid container pt={8} mb={10}>
          <Grid item container justifyContent={"space-between"} rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <GoBack />
                <Typography variant="h2" sx={style.whiteText}>
                  Grant Details
                </Typography>
              </Stack>
              {!isAdmin && grantRequestStatus === THUNK_STATUS.SUCCESS && (
                <Typography variant="body2" color="textlight.main">
                  {grantDetailsDesc[singleGrantData?.approval]}
                </Typography>
              )}
              {
                isAdmin && singleGrantData?.approval === GRANT_APPROVAL_STATUS.DRAFT &&
                <Typography variant="body2" color="textlight.main">
                  You can't see the details of a Draft Grant.
                </Typography>
              }
            </Grid>
            {grantRequestStatus === THUNK_STATUS.SUCCESS && (
              <>
                {isAdmin ? (
                  (singleGrantData?.approval ===
                    GRANT_APPROVAL_STATUS.IN_REVIEW || singleGrantData?.requestForEdit) ? (
                    <GrantPageButtons disableApprove={isAllFeedBackMarked} />
                  ) : (
                    <Grid item>
                      <Button
                        startIcon={<LockOutlinedIcon />}
                        sx={{
                          ...style.grantButton,
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        color="pending"
                        disabled
                        fullWidth
                      >
                        Grant is{" "}
                        {singleGrantData?.approval === GRANT_APPROVAL_STATUS.UPDATE_REQUIRED
                          ? "Requested For Revision"
                          : singleGrantData?.approval}
                      </Button>
                    </Grid>
                  )
                ) : (
                  <Grid item>
                    {singleGrantData?.isEditable ? (
                      <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
                        <Button
                          startIcon={<EditIcon />}
                          sx={style.grantButton}
                          variant="contained"
                          color="violet"
                          onClick={() => navigateToUpdateGrant(false)}
                        >
                          Update Grant
                        </Button>
                        <LoadingButton
                          loading={changeGrantStatusApiStatus === THUNK_STATUS.PENDING}
                          loadingPosition={"start"}
                          startIcon={<PublishRoundedIcon />}
                          sx={style.grantButton}
                          variant="contained"
                          color="info"
                          onClick={() =>
                            handleGrantStatusChange(
                              GRANT_APPROVAL_STATUS.IN_REVIEW
                            )
                          }
                        >
                          Submit Grant
                        </LoadingButton>
                      </Stack>
                    ) : (
                      <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
                        {singleGrantData?.requestForEdit ? (
                          <Button
                            startIcon={<LockOutlinedIcon />}
                            sx={style.grantButton}
                            variant="contained"
                            color="pending"
                            disabled
                          >
                            Requested For Update
                          </Button>
                        ) : (
                          <LoadingButton
                            loading={
                              createGrantAPIStatus === THUNK_STATUS.PENDING
                            }
                            loadingPosition="start"
                            startIcon={<EditIcon />}
                            sx={style.grantButton}
                            variant="contained"
                            color="violet"
                            onClick={() => navigateToUpdateGrant(true)}
                          >
                            Request For Update
                          </LoadingButton>
                        )}
                        {singleGrantData?.approval ===
                          GRANT_APPROVAL_STATUS.APPROVED &&
                          submitReport && (
                            <SubmitReportDialog {...{ singleGrantData }} />
                          )}
                      </Stack>
                    )}
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid item pt={5} xs={12}>
            {grantRequestStatus === THUNK_STATUS.PENDING && loadGrant ? (
              <Stack p={4} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
              </Stack>
            ) : (
              !_.isEmpty(singleGrantData) && !(isAdmin && singleGrantData?.approval === GRANT_APPROVAL_STATUS.DRAFT) && (
                <Stack gap={4}>
                  {!_.isEmpty(singleGrantData?.feedbacks) && (
                    <FeedbackInfoCard
                      title={"FeedBacks"}
                      data={singleGrantData}
                      Icon={ThumbUpOffAltRoundedIcon}
                    />
                  )}
                  <ProjectInfoCard
                    title={"Project Information"}
                    data={singleGrantData}
                    Icon={AccountTreeIcon}
                    status="success"
                  />
                  <FocusAreaInfoCard
                    title={"Focus Area and Impact Information"}
                    data={singleGrantData}
                    Icon={FilterTiltShiftIcon}
                  />
                  <OtherTargetInfoCard
                    title={"Other Impact Information"}
                    data={singleGrantData}
                    Icon={MoreHorizRoundedIcon}
                  />
                  <EventInfoCard
                    title={"Events Information"}
                    data={singleGrantData}
                    Icon={EventRoundedIcon}
                  />
                  <BudgetInfoCard
                    title={"Budget Information"}
                    data={singleGrantData}
                    Icon={LocalAtmRoundedIcon}
                  />
                  {singleGrantData.reportFile &&
                    <DocumentInfoCard
                      data={{ documents: singleGrantData.reportFile }}
                      title="Grant Document"
                      encryptedToken={encryptedToken}
                      role={role}
                    />
                  }
                  <DeclarationInfoCard
                    title={"Declaration"}
                    data={singleGrantData}
                    Icon={ChecklistRoundedIcon}
                  />

                  {!_.isEmpty(reports) && (
                    <ReportInfoCard
                      title={"Milestone Tracking/Reporting"}
                      data={reports}
                      Icon={AssessmentRoundedIcon}
                    />
                  )}
                </Stack>
              )
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
