import { createSlice } from "@reduxjs/toolkit";
import {
  createVendorAsyncThunk,
  getAllVendorAsyncThunk,
  getSingleVendorAsyncThunk,
  getVendorStatesAsyncThunk,
  getVendorListAsyncThunk,
} from "redux/asyncThunk/vendors.asyncThunk";
import { THUNK_STATUS } from "utils/constants";

const initialState = {
  data: [],
  vendorStates: null,
  singleVendorData: [],
  singleVendorStatus: null,
  singleVendorID: null,
  allVendorApiStatus: null,
  createVendorApiStatus: null,
  vendorList: [],
  vendorListApiStatus: null,
};

export const VendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setSingleVendorID: (state, action) => {
      state.singleVendorID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVendorAsyncThunk.pending, (state) => {
        state.allVendorApiStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getAllVendorAsyncThunk.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.allVendorApiStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(getAllVendorAsyncThunk.rejected, (state) => {
        state.allVendorApiStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(getVendorStatesAsyncThunk.fulfilled, (state, action) => {
        state.vendorStates = action.payload.data;
      })
      .addCase(getSingleVendorAsyncThunk.pending, (state) => {
        state.singleVendorStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getSingleVendorAsyncThunk.fulfilled, (state, action) => {
        state.singleVendorData = action.payload.data;
        state.singleVendorStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(getSingleVendorAsyncThunk.rejected, (state) => {
        state.singleVendorData = null;
        state.singleVendorStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(createVendorAsyncThunk.pending, (state) => {
        state.createVendorApiStatus = THUNK_STATUS.PENDING;
      })
      .addCase(createVendorAsyncThunk.fulfilled, (state) => {
        state.createVendorApiStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(createVendorAsyncThunk.rejected, (state) => {
        state.createVendorApiStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(getVendorListAsyncThunk.pending, (state, action) => {
        state.vendorListApiStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getVendorListAsyncThunk.rejected, (state, action) => {
        state.vendorListApiStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(getVendorListAsyncThunk.fulfilled, (state, action) => {
        state.vendorList = action.payload.data;
        state.vendorListApiStatus = THUNK_STATUS.SUCCESS;
      });
  },
});

export default VendorSlice.reducer;
export const { setSingleVendorID } = VendorSlice.actions;
