import { Box, Grid, Stack, Typography } from "@mui/material";
import { InputField, OtpInputAtom } from "UI/atoms";
import React, { useContext } from "react";
import { LoginContext } from "utils/contexts/loginContext";
import { style } from "./style";
import EmailIcon from "@mui/icons-material/Email";
import LoadingButton from "@mui/lab/LoadingButton";

export const LoginForm = () => {
  const {
    handleLogin,
    errors,
    control,
    navigateToSignup,
    navigateToForgotPassword,
    loginStatus,
    formStep,
    minutes, seconds, isTimerRunning, handleResendOTP
  } = useContext(LoginContext);
  return (
    <Grid
      item
      container
      sx={style.container}
      rowSpacing={3}
      component="form"
      noValidate
      onSubmit={handleLogin}
    >
      {formStep.step === 0 && (
        <>
          <Grid item xs={10} sm={8} lg={6.5}>
            <InputField
              fullWidth={true}
              variant="filled"
              label="Email"
              name={"email"}
              errors={errors}
              control={control}
              EndIcon={EmailIcon}
            />
          </Grid>
          <Grid item xs={10} sm={8} lg={6.5}>
            <Stack gap={1}>
              <InputField
                fullWidth={true}
                variant="filled"
                name={"password"}
                label={"Password"}
                type={"password"}
                autoComplete={"on"}
                errors={errors}
                control={control}
              />
              <Typography variant="subtitle1" sx={style.subtitle}>
                Trouble Signing in?{" "}
                <Box
                  component={"span"}
                  sx={style.link}
                  onClick={navigateToForgotPassword}
                >
                  Forgot Password
                </Box>
              </Typography>
            </Stack>
          </Grid>
        </>
      )}
      {formStep.step === 1 && (
        <Grid item xs={10} sm={8} lg={6.5}>
          <OtpInputAtom name={"otp"} control={control} />
        </Grid>
      )}
      <Grid item xs={10} sm={8} lg={6.5} sx={style.button}>
        <LoadingButton
          loading={loginStatus}
          loadingPosition="end"
          endIcon={<></>}
          type="submit"
          variant="contained"
          fullWidth
        >
          {formStep.step === 0 ? 'Log in' : 'Verify'}
        </LoadingButton>
      </Grid>
      {formStep.step === 0 && (
        <Grid item xs={10} sm={8} lg={6.5} mt={-2}>
          <Typography
            variant="body2"
            sx={{ textAlign: "center", color: "#D4D0CA" }}
          >
            Don’t have an account? &nbsp;
            <Box component={"span"} sx={style.link} onClick={navigateToSignup}>
              Register
            </Box>
          </Typography>
        </Grid>
      )}
      {formStep.step === 1 && (
        <Grid item xs={10} sm={8} lg={6.5} mt={-2}>
          <Typography
            variant="body2"
            sx={{ textAlign: "right", color: "#D4D0CA" }}
          >
            Didn't recieve yet? &nbsp;
            {isTimerRunning ? (
              <Box component={"span"}>Resend OTP in: <Box sx={{ minWidth: '40px', display: 'inline-block' }}>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Box></Box>
            ) : (
              <Box component={"span"} sx={style.link} onClick={handleResendOTP} >
                Resend OTP
              </Box>
            )}

          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
