import React from "react";
import { Stack, Typography } from "@mui/material";
import { style } from "./style";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { TitleValue } from ".";

export const VendorContactsInfoCard = ({
  title,
  data = [],
  Icon = AccountTreeIcon,
}) => {
  return (
    <>
      <Stack sx={style.infoCardBackground}>
        {/* Header */}
        <Stack
          sx={{ ...style.infoCardHead, p: 0, justifyContent: "space-between" }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
            <Stack sx={style.infoCardHeadIcon}>
              <Icon />
            </Stack>
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </Stack>
        </Stack>
        {/* Content */}
        <Stack sx={style.infoCardContent}>
          {data.map((contact, ind) => {
            return (
              <Stack
                key={ind}
                py={2}
                px={3}
                sx={{
                  outline: "1px solid rgba(0, 0, 0, 0.05)",
                  ...style.secondaryContainer,
                }}
              >
                <Stack sx={style.infoCardBackground}>
                  {/* Header */}
                  <Stack
                    sx={{
                      ...style.infoCardHead,
                      p: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={1}
                      p={2}
                    >
                      <Stack sx={style.infoCardHeadIcon}>
                        <Icon />
                      </Stack>
                      <Typography
                        variant="h3"
                        sx={{ textTransform: "capitalize", fontWeight: 500 }}
                      >
                        {contact.type} Contact
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* Content */}
                  <Stack
                    sx={{
                      ...style.infoCardContent,
                    }}
                  >
                    <TitleValue title={"Name"} value={contact.name} />
                    <TitleValue title={"Email"} value={contact.email} />
                    <TitleValue title={"Mobile"} value={contact.mobile} />
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};
