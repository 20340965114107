import React from "react";
import { Box } from "@mui/material";
import Video from "../../assets/videos/AldarPropertiesCorporateVideo.mp4";
import { style } from "./style";

export const VideoComponent = () => {
  return (
    <Box sx={style.videoBox}>
      <video style={style.video} autoPlay loop muted>
        <source src={Video} type="video/mp4" />
      </video>
    </Box>
  );
};
