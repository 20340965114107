import React, { useEffect, useState } from "react";
import { GrantsTemplate } from "../../../templates";
import { GrantsContext } from "utils/contexts/admin/grantsContext";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUpdateStatusAsyncThunk,
  getAllGrantAsyncThunk,
  getGrantStatesAsyncThunk,
  getRequestForUpdateGrantAsyncThunk,
} from "redux/asyncThunk";
import { ROUTES } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { logError } from "utils/other/helper";
import { GRANT_APPROVAL_STATUS } from "utils/data";

export const Grants = () => {
  // -----------------Hooks----------------- //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { grants: grantRequestData } = useSelector((state) => state.grant);
  const [grantPayload, setGrantPayload] = useState({
    params: { pageNo: 1, pageSize: 5, requestForEdit: false },
  });
  const [updateGrantPayload, setUpdateGrantPayload] = useState({
    params: { pageNo: 1, pageSize: 5, requestForEdit: true },
  });

  // -----------------Functions----------------- //
  const handleGrantDetailsClick = (id) => {
    navigate(ROUTES.GRANT_DETAILS, { state: { data: id } });
  };
  const handleUpdateRequest = (id, value) => {
    dispatch(
      changeUpdateStatusAsyncThunk({
        id: id,
        action: value ? GRANT_APPROVAL_STATUS.APPROVED : GRANT_APPROVAL_STATUS.REJECTED,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getAllGrantAsyncThunk(grantPayload));
        dispatch(getRequestForUpdateGrantAsyncThunk(updateGrantPayload));
        dispatch(getGrantStatesAsyncThunk());
      })
      .catch((err) => {
        logError(err);
      });
  };

  // -----------------Use Effects----------------- //
  useEffect(() => {
    dispatch(getAllGrantAsyncThunk(grantPayload));
    // eslint-disable-next-line 
  }, [grantPayload]);
  useEffect(() => {
    dispatch(getRequestForUpdateGrantAsyncThunk(updateGrantPayload));
    // eslint-disable-next-line 
  }, [updateGrantPayload]);
  useEffect(() => {
    dispatch(getGrantStatesAsyncThunk());
    // eslint-disable-next-line 
  }, []);

  // -----------------Context----------------- //
  const grantsContextValue = {
    grantRequestData,
    setGrantPayload,
    grantPayload,
    handleGrantDetailsClick,
    setUpdateGrantPayload,
    updateGrantPayload,
    handleUpdateRequest,
  };
  return (
    <GrantsContext.Provider value={grantsContextValue}>
      <GrantsTemplate />
    </GrantsContext.Provider>
  );
};
