import { Slider, Typography } from "@mui/material";
import React from "react";
import { style } from "./style";
import { Controller } from "react-hook-form";

export const SliderAtom = ({ label, name, control, ...props }) => {
  return (
    <div
      style={{
        ...style.inputBackground,
        ...(props.fullHeight && {
          height: "100%",
        }),
      }}
    >
      <Typography sx={style.inputLabel}>{label}</Typography>
      <Controller
        name={name} // Replace with your field name
        control={control}
        defaultValue={0} // Set the initial value
        render={({ field }) => <Slider {...field} {...props} />}
      />
    </div>
  );
};
