import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createGrantAsyncThunk,
  getQuestionsAsyncThunk,
  uploadDocumentsAsyncThunk,
} from "redux/asyncThunk";
import { GrantCreateTemplate } from "UI/templates";
import { ROUTES } from "utils/constants";
import { GrantCreateContext } from "utils/contexts";
import {
  GRANT_APPROVAL_STATUS,
  GrantStepFormComponents,
  INITIAL_BUDGET,
  INITIAL_EVENT_ROW,
  INITIAL_TARGET_ROW,
} from "utils/data";
import { GrantFormDefaultValues } from "utils/form/initialValues";
import {
  BudgetInfoValidationSchema,
  DeclarationValidationSchema,
  EventInfoValidationSchema,
  FocusAreaValidationSchema,
  otherImpactValidationSchema,
  ProjectInfoValidationSchema,
} from "utils/form/validationSchema";
import { logError, setFieldError } from "utils/other/helper";

export const GrantCreate = () => {
  // -----------------Initial Constants----------------- //
  const themes = useTheme();
  const location = useLocation();
  const urlData = location?.state?.data;

  // -----------------Hooks----------------- //
  const navigate = useNavigate();
  const [grantActiveStep, setGrantActiveStep] = useState(0);
  const [currentValidationSchema, setCurrentValidationSchema] = useState(
    ProjectInfoValidationSchema
  );
  const isMatchDownSm = useMediaQuery(themes.breakpoints.down("sm"));
  const isMatchDownMd = useMediaQuery(themes.breakpoints.down("md"));
  const isMatchDownLg = useMediaQuery(themes.breakpoints.down("lg"));
  const [grantApproval, setGrantApproval] = useState(GRANT_APPROVAL_STATUS.DRAFT);
  const dispatch = useDispatch();
  const {
    questions,
    questionStatus,
    grants: { createGrantAPIStatus },
  } = useSelector((state) => state.grant);
  const {
    handleSubmit,
    control,
    formState: { isSubmitted, errors },
    watch,
    setValue,
    getValues,
    trigger,
    register,
    clearErrors,
    setError,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(currentValidationSchema),
    defaultValues: urlData
      ? { ...urlData, commitMent: true, isAccurateInfo: true }
      : GrantFormDefaultValues,
  });
  const otherTargetField = useFieldArray({
    control,
    name: "otherTargets",
  });
  const eventsField = useFieldArray({
    control,
    name: "events",
  });
  const budgetsField = useFieldArray({
    control,
    name: "budgets",
  });

  // -----------------Constants----------------- //
  const values = getValues();
  const maxGrantSteps = GrantStepFormComponents.length;
  const similarProjectFileUpload = watch("similarProjectDeliveredBefore");
  const focusAreaApplicable = watch("focusAreaApplicable");
  const IMPACT_TARGET_OPTIONS =
    questions?.other_impact.map((que) => {
      return {
        label: que.question,
        value: que.id,
      };
    }) || [];

  // -----------------Functions----------------- //

  const onSubmit = async (data, e) => {
    if (grantActiveStep !== maxGrantSteps - 1) {
      window.scrollTo(0, 0);
      setGrantActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      delete data.isAccurateInfo;
      delete data.commitMent;
      data.approval = grantApproval;

      // upload document api
      const formData = new FormData();
      formData.append('file', data.documents[0]);
      await dispatch(uploadDocumentsAsyncThunk(formData)).unwrap().then((res) => {
        data.reportFile = res.data.documents;
        // create grant api
        delete data.documents;
        const updatedProblemSolving = data.problemSolvings.map((item) => {
          if (!item.value) {
            return { ...item, value: false };
          }
          return item;
        });
        data.problemSolvings = updatedProblemSolving;
        dispatch(createGrantAsyncThunk(data))
          .unwrap()
          .then(() => {
            navigate(ROUTES.DASHBOARD);
          })
          .catch((err) => {
            setFieldError(err, setError)
            logError(err)
          });
      }).catch((err) => { logError(err) })
    }
  };

  const handleGrantNext = () => {
    if (!Object.keys(errors).length) {
      setGrantActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleGrantBack = () => {
    if (grantActiveStep === 0) {
      navigate(-1);
    } else {
      window.scrollTo(0, 0);
      setGrantActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const addTargetRow = () => {
    otherTargetField.append(INITIAL_TARGET_ROW);
  };
  const deleteTargetRow = (index) => {
    otherTargetField.remove(index);
  };

  const addEventRow = () => {
    eventsField.append(INITIAL_EVENT_ROW);
  };
  const deleteEventRow = (index) => {
    eventsField.remove(index);
  };

  const addBudgetRow = () => {
    budgetsField.append(INITIAL_BUDGET);
  };
  const deleteBudgetRow = (index) => {
    budgetsField.remove(index);
  };

  const handleRequestBudgetChange = (e) => {
    setValue('requestedBudget', e.target.value);
    trigger('requestedBudget');
    if (watch('totalProjectCost')) {
      trigger('totalProjectCost');
    }
  }

  const handleExpectedImpactPerMonthChange = (e, i) => {
    setValue(`expectedImpacts[${i}].perMonth`, e.target.value);
    trigger(`expectedImpacts[${i}].perMonth`);
    if (watch(`expectedImpacts[${i}].total`)) {
      trigger(`expectedImpacts[${i}].total`);
    }
  }

  // -----------------UseEffects----------------- //
  useEffect(() => {
    switch (grantActiveStep) {
      case 0:
        setCurrentValidationSchema(ProjectInfoValidationSchema);
        break;
      case 1:
        setCurrentValidationSchema(FocusAreaValidationSchema());
        break;
      case 2:
        setCurrentValidationSchema(otherImpactValidationSchema());
        break;
      case 3:
        setCurrentValidationSchema(EventInfoValidationSchema());
        break;
      case 4:
        setCurrentValidationSchema(BudgetInfoValidationSchema());
        break;
      case 5:
        setCurrentValidationSchema(DeclarationValidationSchema);
        break;
      default:
        setCurrentValidationSchema(ProjectInfoValidationSchema);
        break;
    }
    // eslint-disable-next-line 
  }, [grantActiveStep]);
  useEffect(() => {
    dispatch(getQuestionsAsyncThunk({ focusAreaApplicable }));
    if (urlData?.focusAreaApplicable !== focusAreaApplicable) {
      setValue(
        "problemSolvings",
        questions?.problem_solving?.map((q) => {
          return {
            grantQuestionId: q.id,
            value: false,
          };
        }) || []
      );
      setValue(
        "expectedImpacts",
        questions?.expected_impact?.map((q) => {
          return {
            grantQuestionId: q.id,
            perMonth: "",
            total: "",
          };
        }) || []
      );
      clearErrors(["problemSolvings", "expectedImpacts"]);
    }
    // eslint-disable-next-line
  }, [focusAreaApplicable]);

  // -----------------Context----------------- //
  const grantCreateContextValue = {
    handleSubmit,
    control,
    errors,
    values,
    setValue,
    addTargetRow,
    deleteTargetRow,
    addEventRow,
    deleteEventRow,
    onSubmit,
    setCurrentValidationSchema,
    maxGrantSteps,
    grantActiveStep,
    handleGrantNext,
    handleGrantBack,
    focusAreaApplicable,
    addBudgetRow,
    deleteBudgetRow,
    isMatchDownSm,
    isMatchDownMd,
    isMatchDownLg,
    similarProjectFileUpload,
    isSubmitted,
    trigger,
    questions,
    questionStatus,
    register,
    otherTargetField,
    eventsField,
    budgetsField,
    IMPACT_TARGET_OPTIONS,
    watch,
    urlData,
    createGrantAPIStatus,
    handleRequestBudgetChange,
    handleExpectedImpactPerMonthChange,
    grantApproval,
    setGrantApproval
  };
  return (
    <GrantCreateContext.Provider value={grantCreateContextValue}>
      <GrantCreateTemplate />
    </GrantCreateContext.Provider>
  );
};
