import { Paper, Stack, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import React from "react";

const otherSeriesProps = {
  highlightScope: {
    faded: "global",
    highlighted: "item",
  },
  faded: {
    innerRadius: 50,
    additionalRadius: -10,
    color: "gray",
  },
  arcLabelMinAngle: 45,
  innerRadius: 50,
  outerRadius: 120,
  paddingAngle: 5,
  cornerRadius: 5,
  startAngle: -90,
  endAngle: 270,
};

const legendsOptions = {
  direction: "row",
  position: { horizontal: "middle", vertical: "bottom" },
  itemMarkWidth: 20,
  itemMarkHeight: 5,
  markGap: 10,
  itemGap: 10,
  labelStyle: {
    fontSize: 12,
    fill: "black",
  },
};

export const PieChartAtom = (props) => {
  const { title, data, ...rest } = props;
  return (
    <Paper
      sx={{
        height: "100%",
        width: "100%",
        p: 2,
      }}
    >
      <Typography variant="h5" textAlign={"center"}>
        {title}
      </Typography>
      {/* <Divider
        variant="fullWidth"
        absolute
        sx={{ width: "100%", bottom: 40 }}
      /> */}
      <Stack justifyContent={"center"} alignItems={"center"}>
        <PieChart
          margin={{
            left: 100,
            top: -30,
          }}
          series={[
            {
              data,
              ...otherSeriesProps,
            },
          ]}
          slotProps={{
            legend: { ...legendsOptions },
          }}
          height={400}
          {...rest}
        ></PieChart>
      </Stack>
    </Paper>
  );
};
