import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { HeadLogo, LoginHeader } from "UI/molecules";
import EmailIcon from "@mui/icons-material/Email";
import { style } from "./style";
import { ForgotPasswordContext } from "utils/contexts";
import { InputField, OtpInputAtom } from "UI/atoms";
import { LoadingButton } from "@mui/lab";

export const ForgotPasswordTemplate = () => {
  const { handleForgotPassword, errors, control, formStep, loadingBtn, handleResendOTP, minutes, seconds, isTimerRunning, navigateToLogin, moveBack } =
    useContext(ForgotPasswordContext);
  return (
    <Grid item container xs={12} sx={style.logoContainer} direction={"column"}>
      {/* Logo */}
      <Grid
        item
        container
        xs={2.3}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <HeadLogo />
      </Grid>
      <Grid item container xs={7.4} sx={style.loginFormContainer}>
        {formStep.step === 0 && (
          <LoginHeader
            title={"Forgot Password?"}
            desc={"Please enter your email to send the OTP."}
          />
        )}
        {formStep.step === 1 && (
          <LoginHeader
            title={"Verify OTP"}
            desc={
              <>
                OTP has been sent to your registered email.
                <br />
                Please enter OTP to proceed.
              </>
            }
          />
        )}
        {formStep.step === 2 && (
          <LoginHeader
            title={"Change Password"}
            desc={"Kindly proceed with resetting your password."}
          />
        )}
        <Grid
          item
          container
          rowSpacing={3}
          justifyContent={"center"}
          component="form"
          noValidate
          onSubmit={handleForgotPassword}
        >
          {formStep.step === 0 && (
            <Grid item xs={10} sm={8} lg={6.5}>
              <InputField
                fullWidth={true}
                variant="filled"
                label="Email"
                name={"email"}
                errors={errors}
                control={control}
                EndIcon={EmailIcon}
              />
            </Grid>
          )}
          {formStep.step === 1 && (
            <Grid item xs={10} sm={8} lg={6.5}>
              <OtpInputAtom name={"otp"} control={control} />
            </Grid>
          )}
          {formStep.step === 2 && (
            <>
              <Grid item xs={10} sm={8} lg={6.5}>
                <InputField
                  fullWidth={true}
                  variant="filled"
                  name={"password"}
                  label={"Password"}
                  type={"password"}
                  autoComplete={"on"}
                  errors={errors}
                  control={control}
                />
              </Grid>
              <Grid item xs={10} sm={8} lg={6.5}>
                <InputField
                  fullWidth={true}
                  variant="filled"
                  name={"confirmPassword"}
                  label={"Confirm Password"}
                  type={"password"}
                  autoComplete={"on"}
                  errors={errors}
                  control={control}
                />
              </Grid>
            </>
          )}
          <Grid item xs={10} sm={8} lg={6.5} sx={style.button}>
            {formStep.step === 0 && (
              <LoadingButton
                loading={loadingBtn}
                loadingPosition="end"
                endIcon={<></>}
                type="submit"
                variant="contained"
                fullWidth
              >
                Send OTP
              </LoadingButton>
            )}
            {formStep.step === 1 && (
              <LoadingButton
                loading={loadingBtn}
                loadingPosition="end"
                endIcon={<></>}
                type="submit"
                variant="contained"
                fullWidth
              >
                Verify
              </LoadingButton>
            )}
            {formStep.step === 2 && (
              <LoadingButton
                loading={loadingBtn}
                loadingPosition="end"
                endIcon={<></>}
                type="submit"
                variant="contained"
                fullWidth
              >
                Submit
              </LoadingButton>
            )}
          </Grid>
          {(formStep.step === 0 || formStep.step === 2) &&
            <Grid item xs={10} sm={8} lg={6.5} mt={-2}>
              <Typography
                variant="body2"
                sx={{ textAlign: "center", color: "#D4D0CA" }}
              >
                Don't want to move? Go back to &nbsp;
                <Box component={"span"} sx={style.link} onClick={navigateToLogin}>
                  Login
                </Box>
              </Typography>
            </Grid>
          }
          {formStep.step === 1 && (
            <Grid item xs={10} sm={8} lg={6.5} mt={-2}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", color: "#D4D0CA" }}
                >
                  <Box component={"span"} onClick={moveBack} sx={{ textDecoration: 'underline' }} >
                    Go Back
                  </Box>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", color: "#D4D0CA" }}
                >
                  {isTimerRunning ? (
                    <Box component={"span"}>Resend OTP in : <Box sx={{ minWidth: '40px', display: 'inline-block' }}>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Box></Box>
                  ) : (
                    <>
                      <Box component={"span"} sx={{ display: { xs: 'none', sm: 'unset' } }}>
                        Didn't recieve yet? &nbsp;
                      </Box>
                      <Box component={"span"} sx={style.link} onClick={handleResendOTP} >
                        Resend OTP
                      </Box>
                    </>
                  )}

                </Typography>

              </Stack>

            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
