import { Grid, Typography } from "@mui/material";
import React from "react";
import { style } from "./style";

export const PageHeader = ({ title, desc, black = false }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Typography
        variant="h2"
        sx={{ ...style.whiteText, textTransform: "capitalize", ...(black && { color: "black", textAlign: 'center' }) }}
      >
        {title}
      </Typography>
      <Typography variant="body2" color="textlight.main">
        {desc}
      </Typography>
    </Grid >
  );
};
