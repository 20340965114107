import React from "react";
import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { style } from "./style";
import { getTodayDate } from "utils/other/helper";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { LoadingButton } from "@mui/lab";
import { grey } from "@mui/material/colors";

export const ApproveRejectButtons = (props) => {
  const {
    handleApproveButton,
    handleRejectButton,
    showApprove = true,
    showReject = true,
    approveLoading,
  } = props;
  return (
    <Grid item xs={12} sm={6}>
      {/* Button with Text */}
      <Hidden smDown>
        <Stack alignItems={"flex-end"}>
          <Stack gap={1}>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              {showApprove && (
                <LoadingButton
                  loading={approveLoading}
                  loadingPosition="start"
                  startIcon={<CheckRoundedIcon />}
                  variant="contained"
                  color="success"
                  onClick={handleApproveButton}
                  sx={{
                    justifyContent: "flex-start",
                    "&.Mui-disabled": {
                      background: grey[600],
                    },
                  }}
                  fullWidth
                >
                  Approve
                </LoadingButton>
              )}
              {showReject && (
                <Button
                  startIcon={<CloseIcon />}
                  color="error"
                  variant="contained"
                  onClick={handleRejectButton}
                  sx={{ justifyContent: "flex-start" }}
                  fullWidth
                >
                  Reject
                </Button>
              )}
            </Stack>
            <Typography variant="h5" color="textlight.main" sx={style.dateFont}>
              {getTodayDate()}
            </Typography>
          </Stack>
        </Stack>
      </Hidden>
      {/* Icon Buttons  */}
      <Hidden smUp>
        <Stack gap={1}>
          <Stack direction={"row"} gap={1.5}>
            {showApprove && (
              <IconButton
                sx={style.grantButton("success")}
                variant="contained"
                onClick={handleApproveButton}
              >
                <CheckRoundedIcon />
              </IconButton>
            )}
            {showReject && (
              <IconButton
                sx={style.grantButton("error")}
                variant="contained"
                onClick={handleRejectButton}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
          <Typography variant="h5" color="textlight.main" sx={style.dateFont}>
            {getTodayDate()}
          </Typography>
        </Stack>
      </Hidden>
    </Grid>
  );
};
