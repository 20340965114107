import React, { useEffect, useState } from "react";
import { LoginTemplate } from "UI/templates";
import { LoginContext } from "utils/contexts/loginContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getprofileAsyncThunk, loginAsyncThunk } from "redux/asyncThunk";
import { loginValidationSchema, otpValidationSchema } from "utils/form/validationSchema";
import { setForgerockConfig } from "utils/other/forgeRockService";
import { USER_LOGIN_JOURNEY } from "config";
import {
  FRAuth,
  TokenManager,
  FRUser,
} from "@forgerock/javascript-sdk";
import { AppInstance } from "utils/network";
import { logout, setToken } from "redux/slices/auth.slice";
import toast from "react-hot-toast";
import { ROUTES } from "utils/constants";
import { VENDOR_STATUS } from "utils/data";
import { logError } from "utils/other/helper";

let frLoginRes1;
export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginStatus, setLoginStatus] = useState();
  const [timer, setTimer] = useState(120);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [formStep, setFormStep] = useState({
    step: 0,
    prevFRRes: {}
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues
  } = useForm({
    resolver: yupResolver(
      (() => {
        switch (formStep.step) {
          case 0:
            return loginValidationSchema;
          case 1:
            return otpValidationSchema;
          default:
            return;
        }
      })()
    ),
  });
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const handleLogin = async (data) => {
    try {
      if (formStep.step === 0) {
        setLoginStatus(true);
        dispatch(loginAsyncThunk(data)).unwrap().then(async (res) => {
          if (res.data.status === VENDOR_STATUS.APPROVED) {
            setForgerockConfig(USER_LOGIN_JOURNEY);
            try {
              await FRUser.logout();
            } catch (error) {
              toast.error("Something went wrong!");
            }
            let frLoginRes0 = await FRAuth.start();
            let frLoginReq1 = { ...frLoginRes0 };
            frLoginReq1.payload.callbacks[0].input[0].value = data.email;
            frLoginReq1.payload.callbacks[1].input[0].value = data.password;
            frLoginRes1 = await FRAuth.next(frLoginReq1);
            // if frLoginRes1 got error
            let frLoginRes1Mes = frLoginRes1?.payload?.message;
            if (frLoginRes1Mes) {
              switch (frLoginRes1Mes) {
                case "Login failure":
                  toast.error("Invalid Credentials!");
                  break;
                case "User Locked Out.":
                  toast.error("Your account has been locked due to too many failed login attempts. Please try again after 5 minutes.");
                  break;
                default:
                  toast.error(frLoginRes1Mes);
              }
            } else if (frLoginRes1?.callbacks?.[0]?.payload?.output?.[0]?.name === "message") {
              toast.error(frLoginRes1.callbacks[0].payload.output[0].value);
            } else {
              setFormStep((prev) => { return { ...prev, step: prev.step + 1 } });
            }

          }
        }).catch((err) => {
          logError(err);
        }).finally(() => {
          setLoginStatus(false);
        })
      } else if (formStep.step === 1) {
        setLoginStatus(true);
        let frLoginReq2 = { ...frLoginRes1 };
        if (frLoginReq2?.payload?.callbacks?.[1]?.type == "ConfirmationCallback") {
          frLoginReq2.payload.callbacks[1].input[0].value = 1;
          let frInvalidOtpRes = await FRAuth.next(frLoginReq2);
          frInvalidOtpRes.payload.callbacks[0].input[0].value = data.otp;
          frLoginReq2 = frInvalidOtpRes;
        } else {
          frLoginReq2.payload.callbacks[0].input[0].value = data.otp;
        }
        let frLoginRes2 = await FRAuth.next(frLoginReq2);
        let frLoginRes2Mes = frLoginRes2?.payload?.message;
        if (frLoginRes2Mes) {
          toast.error(frLoginRes2Mes);
        }
        if (frLoginRes2?.type === "LoginSuccess") {
          const { accessToken } = await TokenManager.getTokens();
          AppInstance.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken;
          AppInstance.defaults.headers.common["base"] = "forgerock"

          dispatch(getprofileAsyncThunk())
            .unwrap()
            .then((res) => {
              const vendorStatus = res.data.status;
              if (vendorStatus === VENDOR_STATUS.APPROVED) {
                dispatch(setToken(accessToken));
                setLoginStatus(false);
                toast.success("Logged in successfully!");
              } else {
                toast.error(`Your status is ${vendorStatus}`);
                dispatch(logout());
              }
            })
            .catch((err) => {
              setLoginStatus(false);
            });
        } else {
          if (frLoginRes2?.callbacks?.[0]?.payload?.output?.[0]?.name === "message") {
            toast.error(frLoginRes2.callbacks[0].payload.output[0].value);
          }
          setLoginStatus(false);
          frLoginRes1 = { ...frLoginRes2 }
        }
      }
    } catch (e) {
      toast.error(e.message);
      setLoginStatus(false);
    }
  };

  const navigateToSignup = () => {
    navigate(ROUTES.REGISTER);
  };

  const navigateToForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleResendOTP = async () => {
    setIsTimerRunning(true);
    setForgerockConfig(USER_LOGIN_JOURNEY);
    try {
      await FRUser.logout();
    } catch (error) {
      toast.error("Something went wrong!");
    }
    let frLoginRes0 = await FRAuth.start();
    let frLoginReq1 = { ...frLoginRes0 };
    frLoginReq1.payload.callbacks[0].input[0].value = getValues('email');
    frLoginReq1.payload.callbacks[1].input[0].value = getValues('password');
    frLoginRes1 = await FRAuth.next(frLoginReq1);
  }

  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning]);

  useEffect(() => {
    if (timer === 0) {
      setTimer(120);
      setIsTimerRunning(false);
    }
  }, [timer]);
  const LoginContextValue = {
    handleSubmit,
    control,
    errors,
    handleLogin: handleSubmit(handleLogin),
    navigateToSignup,
    navigateToForgotPassword,
    loginStatus,
    formStep,
    minutes,
    seconds,
    isTimerRunning,
    handleResendOTP
  };
  return (
    <LoginContext.Provider value={LoginContextValue}>
      <LoginTemplate />
    </LoginContext.Provider>
  );
};
