import {
  Container,
  Grid,
  Stack,
  Typography,
  Hidden,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React, { useContext } from "react";
import { style } from "./style";
import { AutoCompleteAtom, StatCard } from "UI/atoms";
import {
  DashboardCards,
  FOCUS_AREA_APPLICABLE_OPTIONS,
  GRANT_STATUS_OPTIONS,
  PROJECT_TIMEFRAME_OPTIONS,
  VENDOR_STATUS_OPTIONS,
} from "utils/data";
import { AddVendorDialog, ChangePasswordDialog, PageHeader } from "UI/molecules";
import { AdminDashboardContext } from "utils/contexts/admin/adminDashboardContext";
import { PieChartAtom } from "UI/atoms/PieChart";
import { LineChartAtom } from "UI/atoms/LineChart";
import { THUNK_STATUS } from "utils/constants";
const grantsCategoryColor = {
  live: "rgba(255, 99, 132, 0.8)",
  belong: "rgba(54, 162, 235, 0.8)",
  sustain: "rgba(255, 206, 86, 0.8)",
};

const grantsStatusColor = (theme) => {
  return {
    approved: theme.palette.success.light,
    rejected: theme.palette.error.light,
    pending: theme.palette.pending.main,
    in_review: theme.palette.secondary.light,
    update_required: theme.palette.info.main,
    draft: theme.palette.pending.main,
  };
};

const projectTimeFrameColor = {
  "6-12_weeks": "#3498db",
  "6-12_months": "#82e0aa",
  "1-3_years": "#ffb366",
  "+4_years": "#88ff66",
};

export const AdminDashboardTemplate = () => {
  const {
    grantRequestData,
    vendorStates,
    grantsCategoryAnalytics,
    grantsStatusAnalytics,
    projectTimeFrameAnalytics,
    grantApprovalAnalytics,
    grantSubmissionAnalytics,
    budgetAnalytics,
    grantEventsAnalytics,
    vendorStatusAnalytics,
    vendorApprovalAnalytics,
    handleAnalyticsFilterChange,
    handleVendorSearchChange,
    vendorNameOptions,
    vendorListApiStatus,
  } = useContext(AdminDashboardContext);
  const theme = useTheme();

  const grantsCategoryPieData = grantsCategoryAnalytics?.map((item, ind) => {
    return {
      label: item.focusAreaApplicable,
      color: grantsCategoryColor[item.focusAreaApplicable],
      value: Number(item.value),
    };
  });
  // const grantsStatusPieData = grantsStatusAnalytics.map((item, ind) => {
  //   return {
  //     label: item.approval,
  //     color: grantsStatusColor(theme)[item.approval],
  //     ...item,
  //   };
  // });
  const grantsStatusPieData = GRANT_STATUS_OPTIONS.map((item) => {
    return {
      label: item.label,
      color: grantsStatusColor(theme)[item.value],
      value:
        Number(
          grantsStatusAnalytics?.find((item2) => item2.approval === item.value)
            ?.value
        ) || 0,
    };
  });
  const projectTimeFramePieData = PROJECT_TIMEFRAME_OPTIONS.map((item, ind) => {
    return {
      label: item.label,
      color: projectTimeFrameColor[item.value],
      value:
        projectTimeFrameAnalytics.find(
          (item2) => item2.projectTimeFrame === item.value
        )?.count || 0,
    };
  });

  const grantSubmissionXAxis = grantSubmissionAnalytics.map((item) => {
    const date = new Date(item.date);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  });
  const grantSubmissionYAxis = grantSubmissionAnalytics.map((item) =>
    Number(item.count)
  );

  const grantApprovalXAxis = grantApprovalAnalytics?.map((item) => {
    const date = new Date(item.date);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  });
  const grantApprovalYAxis = grantApprovalAnalytics?.map((item) =>
    Number(item.count)
  );

  const budgetXAxis = budgetAnalytics.map((item) => {
    const date = new Date(item.date);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  });
  const budgetYAxis1 = budgetAnalytics?.map((item) =>
    Number(item.totalRequestedBudget)
  );
  const budgetYAxis2 = budgetAnalytics?.map((item) => item.totalProjectCost);

  const eventsXAxis = grantEventsAnalytics.map((item) => {
    const date = new Date(item.month);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  });
  const eventsYAxis = grantEventsAnalytics?.map((item) =>
    Number(item.eventCount)
  );

  const vendorStatusPieData = VENDOR_STATUS_OPTIONS.map((item) => {
    return {
      label: item.label,
      color: grantsStatusColor(theme)[item.value],
      value:
        Number(
          vendorStatusAnalytics.find(
            (item2) => item2.vendorStatus === item.value
          )?.count
        ) || 0,
    };
  });

  const vendorApprovalXAxis = vendorApprovalAnalytics?.map((item) => {
    const date = new Date(item.date);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  });
  const vendorApprovalYAxis1 = vendorApprovalAnalytics?.map((item) =>
    item?.createdCount ? Number(item.createdCount) : item.createdCount
  );
  const vendorApprovalYAxis2 = vendorApprovalAnalytics?.map((item) =>
    item?.approvedCount ? Number(item.approvedCount) : item.approvedCount
  );
  const darkTheme = (theme) => createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: "#61B1E8",
        contrastText: "black",
      },
    },

  })

  return (
    <>
      {/* Dashboard Description */}
      <Stack sx={style.dashboardBackground} pt={8} mb={5}>
        <Grid container>
          <Container maxWidth="lg">
            <Grid item container justifyContent={"space-between"} rowSpacing={2}>
              <PageHeader
                title={"Admin Dashboard"}
                desc={
                  "Real-time analytics and reports on Vendor and Grant management, enabling faster decision-making."
                }
              />
              <AddVendorDialog />
            </Grid>
          </Container>
          <Grid item pt={5} xs={12}>
            <Container maxWidth="lg" py={3}>
              {/* <Paper sx={{ p: 2 }}> */}
              <ThemeProvider theme={darkTheme}>
                <Stack>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      color: 'white',
                      pb: 1,
                    }}
                  >
                    Add Filters
                  </Typography>
                  <Grid item container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <AutoCompleteAtom
                        multiple
                        name={"grant_category"}
                        label="Grant Category"
                        options={FOCUS_AREA_APPLICABLE_OPTIONS}
                        onChange={(event, option) => handleAnalyticsFilterChange("grantCategory", option)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <AutoCompleteAtom
                        multiple
                        name={"grant_status"}
                        label={"Grant Status"}
                        options={GRANT_STATUS_OPTIONS}
                        onChange={(event, option) =>
                          handleAnalyticsFilterChange(
                            "grantStatus",
                            option
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <AutoCompleteAtom
                        multiple
                        name={"userId"}
                        label={"Vendor Name"}
                        options={vendorNameOptions}
                        loading={vendorListApiStatus === THUNK_STATUS.PENDING}
                        onChange={(event, option) =>
                          handleAnalyticsFilterChange("userId", option)
                        }
                        onInputChange={(e, v) => handleVendorSearchChange(v)}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </ThemeProvider>
              {/* </Paper> */}
            </Container>
          </Grid>
          <Grid
            item
            pt={5}
            xs={12}
            sx={{ background: "linear-gradient(#060e0e 75%, #f3f1f0 30%)" }}
          >
            <Container maxWidth="lg">
              <Hidden smDown>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500,
                    ...style.whiteText,
                  }}
                >
                  Overview
                </Typography>
              </Hidden>
              <Stack sx={style.cardContainer}>
                <Grid container gap={2} direction={"row"} display={"flex"}>
                  {DashboardCards(grantRequestData, vendorStates).map(
                    (card, ind) => {
                      if (card.title === "Draft Grants") return null;
                      return <StatCard key={ind} card={card} />;
                    }
                  )}
                </Grid>
              </Stack>
            </Container>
          </Grid>
        </Grid>
      </Stack>
      {/* Overview */}
      <Container maxWidth="lg">
        <Grid item container xs={12} spacing={3} mb={4}>
          <Grid item container justifyContent="space-around" spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <PieChartAtom
                title={"Grant By Categories"}
                data={grantsCategoryPieData}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <PieChartAtom
                title={"Grant By Statuses"}
                data={grantsStatusPieData}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <PieChartAtom
                title={"Project Time Frames"}
                data={projectTimeFramePieData}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-around" spacing={3}>
            <Grid item xs={12} md={6}>
              <LineChartAtom
                title={"Grants Submitted"}
                xAxis={[{ scaleType: "point", data: grantSubmissionXAxis }]}
                series={[
                  {
                    data: grantSubmissionYAxis,
                    color: "#ff6666",
                    label: "Grants",
                    id: "grantId",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineChartAtom
                title={"Grants Approved"}
                xAxis={[{ scaleType: "point", data: grantApprovalXAxis }]}
                series={[
                  {
                    data: grantApprovalYAxis,
                    label: "Grants",
                    id: "grantId",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-around" spacing={3}>
            <Grid item xs={12} md={6}>
              <LineChartAtom
                title={"Requested Budget Vs Total Project Cost"}
                xAxis={[{ scaleType: "point", data: budgetXAxis }]}
                series={[
                  {
                    data: budgetYAxis1,
                    color: "#ff6666",
                    label: "Requested budget",
                    id: "requestedBudgetId",
                  },
                  {
                    data: budgetYAxis2,
                    label: "Total Project Cost",
                    id: "totalCostId",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LineChartAtom
                title={"Grant Events per month"}
                xAxis={[{ scaleType: "point", data: eventsXAxis }]}
                series={[
                  {
                    data: eventsYAxis,
                    label: "Events",
                    id: "eventsId",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="space-around" spacing={3}>
            <Grid item xs={12} md={4}>
              <PieChartAtom
                title={"Vendor Status"}
                data={vendorStatusPieData}
              />
            </Grid>
            <Grid item xs>
              <LineChartAtom
                title={"Vendor's Approval"}
                xAxis={[{ scaleType: "point", data: vendorApprovalXAxis }]}
                series={[
                  {
                    data: vendorApprovalYAxis1,
                    label: "Created",
                    id: "createdId",
                  },
                  {
                    data: vendorApprovalYAxis2,
                    label: "Approved",
                    id: "approvedId",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
